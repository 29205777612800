ion-modal.promotion-modal-full-screen {
  --background: rgba(0, 0, 0, 0.4); /* Fondo oscuro con 0.2 de opacidad */
  --backdrop-opacity: 0.4; /* Opacidad del backdrop */

  ion-content, ion-toolbar, ion-item {
    --background: rgba(0, 0, 0, 0); /* Fondo oscuro con 0.2 de opacidad */
    --backdrop-opacity: 0.4; /* Opacidad del backdrop */
  }

  ion-item {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    height: 85%;


    ion-img {
      border-radius: 4px;
      overflow: hidden;
      height: 85vh;
      padding-left: 3vw;
    }

    .item-inner {
      --border-color: none;
    }
  }
}