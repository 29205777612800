.modal-pay-guru-pro {
  position: fixed;
  top: -3px;
  margin: 0px !important;
  width: 100%;
  .content {
    background: #fff;
    border-radius: 8px;
    height: 49em;
    margin-top: 3px;
    > .header {
      font-size: 14px;
      font-weight: 700;
      color: var(--text-title-gray-6);
      text-align: center;
      margin-bottom: 31px;
      position: relative;
      top: 84px;
    }
    > .body-guru-pro {
      display: flex;
      flex-direction: row;
      padding: 0 18px;
      margin-top: 126px;
      > div {
        &:nth-child(1) {
          height: 8em;
          width: 9em;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          background-size: auto 72%;
          background-position: center;
          background-repeat: no-repeat;
          height: 6em;
          width: 100%;
        }
      }
    }
    > .container-info-productModal {
      text-align: center;
      padding: 4px 12px 24px 12px;
      font-size: 13px;
      .title-pay-guru-pro {
        font-size: 16px;
        font-weight: 700;
        color: var(--emphasis-background-color);
        text-transform: uppercase;
        margin-bottom: 26px;
      }
      .info-modal {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        text-align: left;
        margin: 11px;
        margin-bottom: -36px;
        text-align: center;
      }
    }
    > .footer-btn {
      margin-top: 54px;
      text-align: center;
      ion-button.profile {
        --border-radius: 50px;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        --background: var(--primary-color-celeste);
        width: 50%;
        margin-bottom: 32px;
      }
      ion-button.contact {
        --border-radius: 50px;
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-color-celeste);
        --background: none;
        width: 90%;
        --border-color: var(--primary-color-celeste);
        --border-color: var(--primary-color-celeste);
        --border-width: 0.5px;
        --border-style: solid;
        --box-shadow: none;
        --background-focused:none;
        --background-activated:none;
      }
    }
    > .body {
      padding: 0 18px;
      > .title {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: var(--text-title-gray-3);
      }
      > .info-terms {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        margin-top: 30px;
      }
    }
  }
}

ion-modal.contact-agente {
  .modal-wrapper {
    background: transparent;
    div.ion-page {
      left: 8%;
      right: 5%;
      top: 17%;
      overflow: visible;
      width: 83%;
      .content {
        background: #fff;
        border-radius: 8px;
        height: 29em;
        > .header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }

        > .container-info-productModal {
          text-align: center;
          padding: 10px;
          font-size: 13px;
          padding: 6px 0px 6px 0px;
          .container-title-modal {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-title-gray-3);
          }

          .container-subtitle-modal {
            font-size: 13px;
          }

          .container-contact-us {
            text-align: left;
            color: var(--text-title-gray-6);
            font-size: 11px;
            margin-top: 15px;
          }
        }
        > .container-input {
          margin-bottom: -25px;
          ion-item {
            margin: 16px;
            margin-bottom: -16px;
            > ion-icon {
              display: none;
              color: var(--primary-color);
              position: absolute;
              bottom: 8px;
              right: 0px;
            }

            &.item-has-focus {
              --highlight-color-focused: var(--primary-background-color);
            }
            &.item-valid {
              ion-icon {
                display: inline;
              }
            }
            ion-avatar {
              width: 29px;
              margin: 7px 7px 7px -4px;
              .img-avatar {
                height: 32px;
              }
            }
            ion-label {
              color: var(--text-title-gray-6);
              font-size: 14px;
              font-weight: 400;
            }
            ion-input {
              ion-icon {
                margin-right: 16px;
              }
              --placeholder-color: var(--text-title-gray-6);
              --placeholder-opacity: 1;
              --placeholder-font-weight: 400;
              font-size: 14px;
            }
          }
          .container-textarea {
            margin: 16px;
            margin-top: 31px;
            .message {
              color: var(--text-title-gray-6);
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 10px;
            }
            ion-textarea {
              font-size: 14px;
              font-weight: 400;
              width: 100%;
              height: 6em;
              border: 1px solid #e3e3e3;
              padding: 10px;
            }
          }
        }
        > .footer {
          ion-button {
            --border-radius: 50px;
            margin: 40px 15px 43px 15px;
            --background: var(--primary-color-celeste);
            ion-spinner {
              position: absolute;
              left: 172px;
            }
          }
        }
      }
    }
  }
}

ion-modal.contact-agente-confirmation {
  .modal-wrapper {
    background-color: #00000040;
    div.ion-page {
      left: 8%;
      right: 5%;
      top: 17%;
      overflow: visible;
      width: 83%;
      .content {
        background: #fff;
        border: 1px solid #9c9c9c;
        border-radius: 8px;
        height: 29em;
        > .header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }

        > .container-info-productModal {
          text-align: center;
          padding: 10px;
          font-size: 13px;
          padding: 6px 0px 6px 0px;
          .container-title-modal {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-title-gray-3);
          }

          .container-subtitle-modal {
            font-size: 13px;
          }

          .container-contact-us {
            text-align: left;
            color: var(--text-title-gray-6);
            font-size: 11px;
            margin-top: 15px;
          }
        }
        > .container-input {
          margin-bottom: -25px;
          ion-item {
            margin: 16px;
            margin-bottom: -16px;
            > ion-icon {
              display: none;
              color: var(--primary-color);
              position: absolute;
              bottom: 8px;
              right: 0px;
            }

            &.item-has-focus {
              --highlight-color-focused: var(--primary-background-color);
            }
            &.item-valid {
              ion-icon {
                display: inline;
              }
            }
            ion-avatar {
              width: 29px;
              margin: 7px 7px 7px -4px;
              .img-avatar {
                height: 32px;
              }
            }
            ion-label {
              color: var(--text-title-gray-6);
              font-size: 14px;
              font-weight: 400;
            }
            ion-input {
              ion-icon {
                margin-right: 16px;
              }
              --placeholder-color: var(--text-title-gray-6);
              --placeholder-opacity: 1;
              --placeholder-font-weight: 400;
              font-size: 14px;
            }
          }
          .container-textarea {
            margin: 16px;
            margin-top: 31px;
            .message {
              color: var(--text-title-gray-6);
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 10px;
            }
            ion-textarea {
              font-size: 14px;
              font-weight: 400;
              width: 100%;
              height: 6em;
              border: 1px solid #e3e3e3;
              padding: 10px;
            }
          }
        }
        > .footer {
          ion-button {
            --border-radius: 50px;
            margin: 40px 15px 43px 15px;
            --background: var(--primary-color-celeste);
            ion-spinner {
              position: absolute;
              left: 172px;
            }
          }
        }
      }
    }
  }
}
