.recovery-password{
  margin: 0 auto;  
  .content-recovery-password{
    --background: var(--background-color-blue);
  }
  .container-help{
    text-align: center;
    padding: 26px;     
    .help {
      font-size: 12px;
      font-weight: 700;
      color:var(--normal-text);
      text-decoration:none;
      margin-top: 5px;
      text-align: center;
    }
  }
  .container-subtitle{
    margin: 0px 10%;
    margin-top: 24px;
    .subtitle-validate-user{
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-3);
      margin-bottom: 28px;
    }
  }
  .container-subtitle-form{
    margin: 0px 10%;
    margin-top: 50px;
    .subtitle-validate-user{
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-3);
      margin-bottom: 28px;
    }
  }
  .container-get-code-btn{
    margin: 60px 60px 30px 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .btn-first{
      margin-bottom: 30px;
    }
  }
  .container-btn-form{
    display: flex;
    justify-content: center;
  }
  .form{
    --border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-color-celeste);
    --background: none;
    --border-color: var(--primary-color-celeste);
    --border-color: var(--primary-color-celeste);
    --border-width: 0.5px;
    --border-style: solid;
    --box-shadow: none;
    --background-focused:none;
    --background-activated:none;
    margin: 0 5%;
  }
}