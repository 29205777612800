.feedback-no-scope-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 38px;
        color: var(--color-blue);
        text-align: center;
}

.feedback-no-scope-text{
    margin-top: 20px;
}

.feedback-no-scope-call-to-action {
    margin-top: 12px;
    font-weight: bold;
}
