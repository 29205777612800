ion-footer {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
}
.footer-menu-home {
  box-shadow: none;
  display: flex;
  align-items: center !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 8px; 
  padding-bottom: 16px; //iPhone fix
  button {
    color: var(--text-title-gray-6);
    flex: 1;
    height: 3rem;
    font-size: 13px;
    &:disabled {
      opacity: 0.6;
    }
    &.active {
      color:var(--button-footer);
      font-size: 13px;
      height: 3rem;
    }
    &:active, &:focus {
      outline: none;
    }
    .orders{
      display: flex;
      justify-content: center;
      .check-orders{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color:var(--emphasis-background-color);
      }
    }
    .footer-title {
      margin: 0px;
      padding-top: 5px;
    }
    .footer-option-icon {
      width: 24px;
      height: 24px;
    }
  }
}

