.container-header-help{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin: 20px 20px 20px 12px;
  .arrow-back{
    width: 30px;
    height: 30px;
  }
  .label-header-help{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 17px;
    margin-top: 8px;
    margin-left: 14px;
    color: var(--color-blue);
  }
  .header-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.platform-ios{
  margin: 46px 20px 20px 20px !important;
}