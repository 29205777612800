ion-modal#modal-detail-product {
    --max-width: 500px;
    --border-radius: 6px;
    --height: 54%;
    flex: 1;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --background: var(--background-color-blue);
}

ion-modal#modal-detail-product .wrapper {
    height: 100%;
    overflow-y: auto;

    & > .content-close {
      display: flex;
      justify-content: flex-end;
      background-color: #fff;
      padding-top: 0.5rem;
      padding-right: 0.5rem;

      & > ion-icon {
        font-size: 2.5rem;
        color: var(--color-blue);
        font-weight: 700;
        cursor: pointer;
      }
    }

    & > .content-image {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 1rem;

        & > img {
            width: 8rem;
            height: 8rem;
        }

        & > h3 {
            color: var(--color-blue);
            font-size: 1.2rem;
            font-weight: 700;
            margin-top: 0.5rem;
        }

        & > span {
            font-size: 0.9rem;
            color: #333333;
            margin-top: -0.3rem;
        }
    }

    & > .content-info {
        & > .content-info__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            & > .info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 1rem;
    
                & > span {
                    color: #333333;
                    font-size: 1rem;
                }

                &:nth-child(2) {
                    margin-top: -1rem;
                }

                & > .buttons {
                    position: relative;
                    height: fit-content;
                    width: fit-content !important;
                    padding-bottom: 1rem;
                
                    ion-col {
                      text-align: center;
                    }
                
                    .button {
                      margin: 0px;
                      padding: 0px;
                      width: fit-content;
                      height: fit-content;
                      border-color: #d9d8db;
                      background-color: var(--color-blue);
                      font-size: 12px;
                      opacity: 1;
                
                      &__active {
                        background-color: var(--color-blue);
                      }
                
                      &__disabled {
                        background-color: #999;
                      }
                
                      .icon {
                        margin: 0;
                        width: 25px;
                        height: 25px;
                        color: #ffff;
                      }
                    }
                
                    .btn-left {
                      border-radius: 100%;
                    }
                
                    .btn-right {
                      border-radius: 100%;
                    }
                
                    .value-cell {
                      color: var(--color-blue);
                      display: inline-block;
                      width: 1em;
                      vertical-align: middle;
                      font-size: 16px;
                      min-width: 3em;
                      font-weight: bold;
                      text-align: center;
                      --padding-top: 3px;
                      --padding-end: 0;
                      --padding-bottom: 0px;
                      --padding-start: 0;
                    }
                    .inputs-background {
                      position: absolute;
                      width: 95%;
                      height: 60%;
                      border-radius: 20px;
                      margin: 0px auto;
                      top: 0rem;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      background-color: var(--white);
                      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                    }
                  }
            }
        }

        & > .separator {
            height: 0.01rem;
            border-top: 0.01rem solid #333333;
        }
    }

    & > .content-total {
        padding: 0.7rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > span{
            font-size: 1.2rem;
            color: var(--color-blue);
            font-weight: 700;
        }
    }

    & > .content-sugeridos {
        padding: 0.7rem;
        width: 100%;

        & > h5 {
            font-size: 1.2rem;
            color: var(--color-blue);
            font-weight: 700;
        }

        & > .products {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(2rem, auto);
          gap: 1rem;
        }
    }
}