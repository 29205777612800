$default-background-color: #f5f5f5;
$primary-background-color: #0C2461;
$primary-color: #4a90e2;
$inactive-color: #d9d9d9;
$error-color: #F7432B;
$text-label-color: #a6a6a6;
$text-primary-color: #505255;
$text-disabled-color: #b2b2b2;
$emphasis-color: #ffffff;
$emphasis-background-color: #ec4733;
$separator-color: #a3a3a3;
$heder-productSlider: #eee;
$text-title-gray-6: #666;
$primary-color-celeste: #00B0FF;
$button-footer: #0C2461;
$text-title-gray-9: #999;
$text-title-gray-3: #333;
$background-green: #66F0BC;
$background-green-primary: #78FF0D;
$background-app: #fbfbfb;
$color-blue : #1E3799;
$white: #FFF;
$form-gray: #BBBBBB;
$blue-active-filter: #1C58ED;
$background-color-blue: #EFF5F9;
$normal-text: #333333;
$new-primary-color-celeste: #06B0FF;
$new-dark-blue: #1F3799;
$orange-promotion: #FA983A;
$color-blue-rgb: rgb(30, 55, 153);
//Media Sizes
$media_lg:            1100px;
$media_md:            992px;
$media_sm:            650px;
$media_xs:            600px;


