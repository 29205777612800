.welcome-guru-modal {
  .modal-wrapper {
    background: var(--background-color-blue);
  }

  .content {
    background: var(--background-color-blue);
    overflow: scroll;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80vh;

      .container-title-modal {
        font-size: 1rem;
        font-weight: 600;
        color: var(--color-blue);
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
    }

    >.header {
      display: flex;
      justify-content: flex-end;
      font-size: 1.8em;
      color: #142242;
      opacity: 0.5;
      margin: 13px;
      margin-bottom: -4px;
    }

    >.add-margin{
      margin-top: 60px;
    }

    >.body-welcome-guru {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 0 18px;
      margin-bottom: 30px;

      >div {
        &:nth-child(1) {
          height: 8em;
          width: 9em;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          background-size: auto 72%;
          background-position: center;
          background-repeat: no-repeat;
          height: 10em;
          width: 100%;
        }
      }
    }

    >.container-info-productModal {
      text-align: center;
      padding: 4px 12px 24px 12px;
      font-size: 13px;

      .convert-to-upper{
        text-transform: uppercase;
      }

      

      .info-modal {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        text-align: left;
        margin: 11px;
        margin-bottom: -36px;
      }
    }

    >.body {
      padding: 0 18px;

      >.title {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: var(--text-title-gray-3);
      }

      >.info-terms {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        margin-top: 30px;
      }
    }
  }

    // div.ion-page {
    //   left: 8%;
    //   right: 5%;
    //   top: 18%;
    //   overflow: visible;
    //   width: 83%;

      
    // }
  
}
