.payment-method-content {
  background-color: var(--primary-color-celeste);
  border-top: 1px solid var(--form-gray);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0.2rem 0.7rem 0rem 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span {
    font-weight: 600;
    font-size: 1rem;
    color: #fff;
  }

  & > ion-select::part(text) {
    display: none;
  }

  & > ion-select::part(icon) {
    color: #fff;
    opacity: 1;
  }
}
