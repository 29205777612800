.confirm-page {
  .wrapper {
    position: absolute;
    .header {
      position: relative;
      ion-icon {
        color: white;
        top: 1rem;
        left: 1rem;
        position: absolute;
      }
    }
  }
  .welcome-title {
    text-align: center;
    padding: 24px 0px;
    margin-top: 4em;

    div {
      &:first-child {
        color: var(--primary-background-color);
        font-size: 1.2em;
        font-weight: bold;
      }
      &:last-child {
        color: var(--text-label-color);
        font-size: 0.85em;
        font-weight: lighter;
        padding-top: 4px;
      }
    }
  }

  .code-valid {
    --ion-item-border-color: #f4511e !important;
    font-weight: 700;
    font-size: 1.5em;
  }

  .form {
    .resend-sms {
      --color: var(--primary-color);
      text-align: center !important;
      width: 100%;
      display: inherit;
      padding-top: 10px;
      color: #00b0ff;
      background: white;
      ion-button {
        --background: var(--primary-color-celeste);
        margin-top: 0.5em !important;
        font-size: 14px;
        font-weight: 400;
        --background-activated: none;
        --background-focused: none;
        --background-hover: none;
      }
    }
    .validate-phone{
      margin-top: 23px;
      ion-label{
        margin-bottom: 10px;
        font-weight: 300 !important;
        color:var(--text-title-gray-6)!important; 
      }
      ion-input{
        background: white;
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.25);
        margin-top: 13px;
        padding: 0 1rem ;
        height:26px;
        transform: none !important;
        .phone-prefix {
          background-color: #ccc;
          font-size: 14px;
          padding: 0 8px ;
        }
       
      }
      .btn-save{
        display: flex;
        justify-content: flex-end;
        ion-button{
          height: 32px;
          --border-radius: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary-color-celeste);
          --background: none;
          --border: none;
          box-sizing: none;
          --box-shadow: none;
          text-transform: capitalize;
          width: 34%;
        }
      }
    }
    .report-problem{
      text-align: center;
      display: inherit;
      width: 100%;
      padding-top: 35px;
      font-size: 0.7em;
      color: #9c9c9c;
      text-decoration:underline;
    }
    
    ion-button {
      --background: var(--primary-color);
      font-size: 1.1em;
      margin-top: 0.5em !important;
    }

    div.hint {
      padding: 12px 0px 0px 16px;
      font-size: 0.8em;
      color: var(--primary-color);
    }
  }

  .grid-confirm-sms{
    font-weight: 700;
    margin-top: 1em;
    text-align: center;
    color: #444444;
    font-size: 1.5em;

    .numeric-keyboard {
      --background: white !important;
      color: var(--text-title-gray-3);
      font-size: 20px;
      font-weight: 400;
      margin-top: 0.5em !important;
      --box-shadow: none;
      border: none;
    }
    .numeric-keyboard-delete{
      width: 75%;
      font-size: 15px;
      margin-left: 22px;
      --background: white !important;
      color: var(--text-title-gray-3);
      font-size: 16px;
      font-weight: 400;
      margin-top: 0.5em !important;
      --box-shadow: none;
      border: none;
    }
  }

  .sub-title{
    margin-top: 1em;
    text-align: center;
    color: #666666 !important;
  }

  .title{
    color: #444444 !important;
  }

  .title-2{
    font-weight: 300 !important;
    color: #666666 !important;
    font-family: Italic !important;
  }
}
