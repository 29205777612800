.provider-card-content {
  .provider-card-item {
    list-style: none;
    margin: 0.9rem 0 1rem -2.5rem;
    background: #fff;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

    &--error {
      border-top: 1.5px solid #f7432b;
      border-right: 1.5px solid #f7432b;
      border-left: 1.5px solid #f7432b;
    }

    & > label {
      padding: 0.7rem 0.7rem 0.3rem 0.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      color: var(--color-blue);

      & > span {
        display: flex;
        align-items: center;

        & > .rayo-icon {
          margin-right: 0.2rem;
        }

        & > .loading-icon {
          margin-left: 0.5rem;
        }
      }
    }

    &__accordion {
      overflow-y: auto;
      transition: max-height 0.5s;

      &--up {
        max-height: 400px;
      }

      &--down {
        max-height: 0;
      }

      &__despacho-gratis {
        padding: 0.8rem;
        border-top: 1px solid var(--form-gray);
        display: flex;
        align-items: center;

        & > p {
          margin: auto 0.4rem;
          & > span {
            font-size: 0.9rem;
            font-weight: bold;
            color: #000;
          }
        }
      }
    }
  }

  &__minimo-entrega {
    margin: -0.9rem 0 0.3rem -2.5rem;
    background: #fff;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0.9rem 0.7rem 0.1rem 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &--error {
      border-right: 1.5px solid #f7432b;
      border-left: 1.5px solid #f7432b;
    }

    & > p:nth-child(1) {
      color: var(--normal-text);
      font-size: 0.9rem;
      font-weight: lighter;
    }

    & > p:nth-child(2) {
      font-weight: 700;
      color: var(--primary-color-celeste);
    }
  }

  &__costo-despacho {
    margin: -0.9rem 0 0.3rem -2.5rem;
    background: #fff;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0.7rem 0.7rem 0.1rem 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.9rem;

    &--error {
      border-right: 1.5px solid #f7432b;
      border-left: 1.5px solid #f7432b;
    }
  }

  &__total {
    margin: -0.9rem 0 0.9rem -2.5rem;
    background: #fff;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0.5rem 0.7rem 0.3rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--error {
      border-bottom: 1.5px solid #f7432b;
      border-right: 1.5px solid #f7432b;
      border-left: 1.5px solid #f7432b;
    }

    & > span {
      color: var(--color-blue);
      font-weight: 700;
    }

    & > span:nth-child(1) {
      font-size: 1.2rem;
    }

    & > span:nth-child(2) {
      font-size: 1rem;
    }
  }
}
