.container-step-line{
  display: flex;
}
.container-index{
  width: 25px;
  height: 25px;
  color: #333333;
  text-align: center;
  padding-top: 4px;
  font-weight: bold;
  border-radius: 100%;
}
.active-step{
  background: white;
  border: 2px solid #3659ED;
  padding: 0;
}
.not-completed-step{
  background: #C8EEFF;
  padding: 0.8px;
}
.completed-step{
  background: #3659ED;
  color: white;
  padding: 0;
}
.trace-line{
  border-top: 2px solid #C8EEFF;
  width: 14px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 11px;
}
.active-line{
  border-top: 2px solid #3659ED;
}
