  .page-omboarding{
    height: 100vh !important;
    background-color: #fff;
  .git-slide{
    height: 35rem;
  }
  .container-slides{
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .container-skip-page{
    display: flex;
    justify-content: flex-end;
    background:#ffff;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10;
  }

  .this-is-ios{
    top: 20px !important;
    right: 20px !important;
  }

  .btn-skip-page{
    margin-right: -22px;
    margin-top: 1rem;
  }
  
  .slides-git{
    background: #ffff;
    height: 95vh;

    .slide-info-wrapper{
      height: 100%;
    } 
    
    div.swiper-pagination.swiper-pagination-bullets {
      bottom: 0px;
      --bullet-background-active: var(--primary-color-celeste) ;
    }
    div.swiper-pagination.swiper-pagination-bullets {
     
      background: none !important
    }
    
  }
  .img-slide-onboarding{
    height: 42rem;
    margin-bottom: -124px;
  }
  .text-info{
    padding: 41px;
    font-size: 1em;
    font-weight: 400;
    color: var(--text-title-gray-3);
  }
  .btn-next{
    --background: none;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color-celeste);
    text-transform: uppercase;
    --box-shadow: none ;
    --background-focused:none;
    --background-activated:none ;
    --background-hover: none !important;
  }
  .btn-skip{
    @extend .btn-next;
    --border-color: var(--primary-color-celeste);
    --border-width: 0.5px;
    --border-style: solid;
    --border-radius: 50px;
    width: 50%;
  }
  }



@media screen and (max-height: 736px){
  .page-omboarding{
    .img-slide-onboarding{
      height: 29rem !important;
    }
    div.swiper-pagination.swiper-pagination-bullets {
      bottom: 0px !important;
    }
  }
}


@media screen and (max-height: 568px){
  .page-omboarding{
    div.swiper-pagination.swiper-pagination-bullets {
      bottom: 0px !important;
    }
  }
}
