@mixin separator{
  border-bottom: 1px solid var(--color-border);
}

@mixin textInfo1{
  font-size: 14px;
  font-weight: 600;
  color:  var(--text-title-gray-6);
}

@mixin box {
  border: 0.5px solid var(--color-border);
  box-shadow: 1px 1px 8px 0px #dddddd;
}

@mixin priceTotal {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color-celeste);
}

@mixin title2-2 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-blue);
}
@mixin title3 {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
}

@mixin btn3 {
  font-size: 14px;
  font-weight: 400;
  color: var( --primary-color-celeste);
}

@mixin container() {
  max-width: $media_md;
  margin: 0 auto;
}

@mixin separator() {
  border-bottom: 1px solid #ccc;
  margin: 4px 0 4px 0;
  color: var(--separator-color);
}

@mixin general-shadow($color:black) {
  box-shadow: 0 0 3px 0 $color;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin btn-properties ($background, $background-active, $gradient-list, $color: $background, $opacity-disabled: 0.2){
  border: none;
  color: $color;
  background-size: 0% 0%;
  background-repeat:no-repeat;
  text-transform: none;
  @include transition(background-size, 0.2s linear);
  font-size: 16px !important;
  vertical-align: middle;
  margin: 0;
  cursor: pointer;
  background-color: $background;
  min-width: 40%;
  min-height: 42px;
  border-radius: 100px;
  font-weight: bold;
  padding: 0 2em;
  
  &:hover, &.hover{  
    &:not(.active):not(.disabled):not(.loading){
      background-color: $background-active;
      background-size: 300% 300%;
      @include transition(background-size, 0.2s linear);
    }
  }
  
  &:disabled, &.disabled{
    opacity: $opacity-disabled;
    color: $color;
    background-color: $background;
  }

  &:not(:disabled):not(.disabled){
    &:active, &.active{
      background-color: $background-active;
    }
  }

  &.loading{
    @include gradient(right, $gradient-list); 
    animation: animatedgradient 3s linear alternate infinite;
    background-size: 300% 300%;    
  }

  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@mixin gradient($direction, $list) { 
  background: linear-gradient(to $direction,  $list);

}

@mixin form-properties ($border-color: $form-gray, $padding: 20px, $margin: 10px 0px){
  width: 100%;
  height: 40px;
  margin: $margin;
  background: $emphasis-color;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 12px 20px 12px 20px;
  &:focus-visible{
    outline: none;
  }
  &:focus{
    border: 1px solid $color-blue;
  }
  &:invalid{
    border: 1px solid red;
  }
}

