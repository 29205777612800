// .page-category-provider {
//   .word-slide {
//     --ripple-color: #0000;
//     display: flex;
//     font-size: 14px;
//     margin: 0;
//     max-height: 26px;
//     text-transform: none;


//   }
// }

.swiper {
  .swiper-slide-word {

    // width: auto !important;
    ion-button {
      max-height: 50px;
      display: flex;
      font-size: 14px;
      margin: 0;
      text-transform: none;
      --ripple-color: transparent;
      text-align: left;

    }
  }
}

.swiper-slide-word {
  // border: 1px solid #d7d7d7;
  border-radius: 4px;

  .slide-word {
    font-size: 14px;
    text-align: left;
  }

  .slide-word::part(native) {
    padding: 0.4rem;
  }
}

.container-slider {
  margin-bottom: 15px;
  overflow-x: auto;
}

.selected {
  font-weight: 600;
  color: var(--white);
  background-color: var(--color-blue);
  border-radius: 5px;
}

.selected .tab-offer {
  background-color: #F7432B;
}

.not-selected {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--text-title-gray-6);
}

.isFirst {
  margin-left: 20px;
}

.last-word {
  margin-right: 20px;
}