@import '../../../assets/scss/variables';

ion-modal.modal-invited-user {
  --background: white !important;
  --height: 420px;
  --max-height: 600px;
  --width: 320px;
  --max-width: 400px;
  --border-radius: 10px;
  ion-content.modal-body-content {
    --background: white !important;
    .modal-content {
      margin: 0 !important;
      position: relative;
      min-height: 100%;
      .modal-form-container {
        padding: 15px;
        .form-helper-container {
          padding: 0 28px;
          text-align: center;
          .form-helper-text {
            color: var(--color-blue);
            font-weight: 600;
            font-size: 13px;
          }
        }
      }
      .error-container {
        padding: 0;
        .error-text {
          color: $error-color;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  .btn-actions-container {
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    z-index: 1000;
    bottom: 0;
    -webkit-box-shadow: 0px -8px 12px -11px rgba(0, 0, 0, 0.58);
    box-shadow: 0px -8px 12px -11px rgba(0, 0, 0, 0.58);
  }
}
ion-modal.modal-success-container {
  --border-radius: 10px;
  --height: 220px;
  --max-width: 380px;
  --background: white !important;
  --width: 320px;
  .modal-success-content {
    padding: 28px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    .success-title {
      color: var(--color-blue);
      font-weight: bold;
      font-size: 16px;
    }
    .success-text {
      font-size: 16px;
      padding: 14px 0;
    }
    .btn-ok-container {
      display: flex;
      justify-content: flex-end;
      .send {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        color: var(--new-primary-color-celeste);
        --background: none;
        --box-shadow: none;
        --border-color: none;
        --box-shadow: none;
        --background-focused: none;
        --background-activated: none;
        --background-hover: none !important;
      }
    }
  }
}
