.orders-page-div {
  .categories-list {
    margin: 0 10px;
  }
  ion-content {
    --background: var(--background-color-blue);
    width: 101%;

    .padding-left-1 {
      padding-left: 1rem;
    }

    .padding-left-2 {
      padding-left: 2rem;
    }

    .padding-left-3 {
      padding-left: 2.75rem;
    }

    .padding-left-4 {
      padding-left: 3.5rem;
    }

    .category {
      .text-item {
        flex: 1;
      }

      .top-level {
        font-weight: bold;
      }

      &.open {}

      &.close {
        .children {
          height: 0 !important;
          overflow: hidden;
        }
      }
    }

    .wrapper-orders {
      margin: 1rem 0;

      .list-orders {
        .title {
          background: #f1f1f1;
          color: #4a4a4a;
          padding: 0.25rem 1rem;
          font-weight: bold;
        }

        .list {
          margin: 0.5rem;

          .order {
            margin: 0.5rem 1rem;
            background: white;
            min-height: 3rem;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            display: flex;

            .provider {
              display: inline-block;
              position: relative;
              line-height: 2rem;
              flex: 1;

              .date {
                color: gray;
                font-size: 0.75rem;
                line-height: 0.75rem;
              }
            }

            .button-wrapper {
              vertical-align: middle;
              position: relative;
              top: 0.5rem;

              button {
                color: #4a90e2;
                background: white;
                border: 1px solid #4a90e2;
                border-radius: 1rem;
                padding: 0.5rem 1rem;
                font-size: 0.75rem;

                &:active {
                  color: white;
                  background: #4a90e2;
                }
              }
            }
          }
        }
      }
    }

    .without-products {
      text-align: center;

      ion-img {
        max-width: 70%;
        margin: 1rem auto;
      }

      .message {
        font-weight: bold;
        color: #142242;
        margin: 1rem 0;
        font-size: 1.25rem;
      }

      ion-button {
        width: 70%;
      }
    }
  }
}

.categories-list {
  max-width: 1000px;
  margin: 0 auto;
  background-color: var(--background-color-blue);
  .categories-card {
    background-color: var(--background-color-blue);
    animation: fadeInLeft; 
    animation-duration: 1.6s;
    justify-content: center;
    width: 33%;
    height: 11em;
    float: left;
    margin-left: 0%;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ion-text {
      font-weight: bold;
      vertical-align: middle;
      text-align: center;
    }

    >.logo {
      background-size: auto 80%;
      background-position: center;
      background-repeat: no-repeat;
      height: 6em;
      width: 100%;
    }

    >.name-categories {
      text-align: center;
      margin: 0 5px;
      color: var(--color-blue);
    }
  }
}


