.confirm-page {
  .welcome-title {
    text-align: center;
    padding: 24px 0px;

    div {
        color: var(--primary-background-color);
        font-size: 1.25em;
        font-weight: bold;
    }
  }

  .form {
    margin: 0px 10%;
    ion-item {
      ion-input {
        --color: var(--text-primary-color);
      }

      ion-label {
        --color: var(--text-label-color);
        font-size: 0.85em;
      }

      ion-icon {
        display: none;
        color: var(--primary-color);
        position: absolute;
        bottom: 8px;
        right: 0px;
      }

      &.item-has-focus {
        --ion-item-border-color: var(--primary-color);
      }

      &.item-valid {
        --ion-item-border-color: var(--primary-color);
        --inner-border-width: 0px 0px 2px 0px;

        ion-icon {
          display: inline;
        }
      }
    }

    ion-anchor {
      --color: var(--primary-color);
      text-align: center;
      width: 100%;
      display: inherit;
      padding-top: 10px;
    }

    ion-button {
      --background: var(--primary-color);
      font-size: 1.1em;
    }

    div.hint {
      padding: 12px 0px 0px 16px;
      font-size: 0.8em;
      color: var(--primary-color);
    }
  }
}
