@import '../../assets/scss/styles.scss';

.cart-page-class {
  --background:  var(--background-color-blue);
  ion-content {
    --background:  var(--background-color-blue);
    .container-total-cart{
      display: flex;
      justify-content: space-between;
      padding: 17px;
      border-bottom: 1px solid var(--color-blue);
      .length-cart{
        font-size: 14px;
        font-weight: 600;
        color: var(--text-title-gray-3);
        max-width: 1000px;
      }
      .total-cart{
        font-size: 14px;
        font-weight: 700;
        color: var(--primary-color-celeste);
      }
    }
    .separator {
      border-bottom: 1px solid #ccc;
    }
    .without-products-card {
      text-align: center;
      display: flex;
      justify-content: center;
      .container-guru-white-screen{
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
      ion-img {
        max-width: 40%;
        margin: 1rem auto;
      }
 
      .message {
        color: #ccc;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 700;
      }
      .text{
        color: #ccc;
        font-size: 14px;
        font-weight: 700;
      }
      ion-button {
        font-size: 12px;
        font-weight: 600;
        color: var(--primary-color-celeste);
        border: 0.5px solid var(--primary-color-celeste);
        border-radius: 100px;
        --background: none;
        margin-top: 50px;
        --box-shadow: none;
      }
    }
    .hide-container {
      visibility: hidden !important;
      position: absolute;
      left: -1000px;
      max-height: 1px !important;
    }
  }
}


@media only screen and (min-width: 768px) {
  .container-total-cart {
    margin: 0 auto; 
    max-width: 1000px;
  }
}


