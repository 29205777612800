.order-help-page {
  .header-wrapper {
    margin: 10px 0 -20px 0;
  }
  .order-info {
    width: 100%;
    border-bottom: 1px solid rgba(31, 60, 124, 0.3);
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 15px;
    &-title {
      margin: 24px 0px 5px 0px;
    }
  }
  .order-help-content {
    width: 100%;
    padding: 10px 20px;
  }
  .order-help-title {
    margin: 0px 0px 10px 0px;
  }
  .info-type {
    pointer-events: none;

    &-section {
      padding: 10px 15px;
      border-bottom: 1px solid rgba(31, 60, 124, 0.3);
    }
    &-red-section {
      padding: 10px 15px;
      border: 1px solid red
    }
    &-header {
      display: flex;
      align-items: center;
      margin: 15px 0px;

      .info-type-arrow {
        width: 29px;
        margin-right: 15px;
      }
    }
    &-rejected-status {
      display: flex;
      width: 100%;

      .info-type-rejected-circle {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: 3px;
        margin-right: 15px;
      }
    }
    &-question-section {
      display: flex;
      height: 150px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .checklist-type {
    width: 100%;
    
    &-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0px;
    }
    &-checkbox-container input[type='checkbox'] {
      visibility: hidden;
      width: 20px;
      height: 20px;
      background: white;
      border-radius: 5px;
      border: 2px solid #555;
    }
    &-checkbox-container input[type='checkbox']:after {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      position: relative;
      content: '';
      display: inline-block;
      visibility: visible;
      background-color: white;
      border: 2px solid var(--color-blue);
    }
    &-checkbox-container input[type='checkbox']:checked:after {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      position: relative;
      background-color: var(--color-blue);
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid var(--color-blue);
    }
    &-checkbox {
      margin-right: 10px;
    }
  }
  .radiolist-type {
    width: 100%;
    
    &-option-container input[type='radio'] {
      visibility: hidden !important;
      margin-top: 8px;
    }
    &-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 15px 14px 0px;
    }
  }
  .rec-coupon-type {
    width: 100%;

    &-card {
      display: flex;
      align-items: center;

      width: 100%;
      height: fit-content;
      
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 8px 16px;
    }
    &-image {
      width: 64px;
      height: 64px;
    }
    &-info-section {
      padding-left: 10px;
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .comment-type {
    width: 100%;
    &-textarea {
      width: 100%;
      border: 1px solid var(--color-blue);
      border-radius: 10px;
      margin-top: 10px;
      padding: 5px;
    }
  }
  .full-order-type {
    width: 100%;
    max-width: 390px;
    margin: 0px auto;
    &-card {
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
    &-card-header {
      padding: 13px 15px 8px 15px;
      border-bottom: 1px solid #DDD;
    }
    &-card-body {
      padding: 10px 15px;
    }
    &-card-footer-flex {
      padding: 10px 15px;
      border-top: 1px solid #DDD;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-card-footer {
      padding: 10px 15px;
      border-top: 1px solid #DDD;
    }
    &-product {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 0px;

      &-qty {
        width: 60px;
        text-align: center;
        margin-right: 20px;
      }
      &-qty h1 {
        color: var(--text-title-gray-3) !important;
        margin: 0px;
      }
      &-img {
        width: 42px;
        margin-right: 15px;
      }
      &-price {
        margin: 5px 0px 0px 0px;
      }
      &-info {
        width: 130px;
      }
    }
    &-info-container { 
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
    }
    &-discounts {
      margin-bottom: 20px;
      text-align: right;
    }
    &-dark-title {
      margin-top: 0px;
      color: var(--text-title-gray-3) !important;
    }
    &-margined-title {
      margin: 28px 0px 0px 0px;
    }
    &-spacer {
      height: 20px;
    }
  }
  .edit-order-type {
    width: 100%;
    max-width: 390px;
    margin: 0px auto;
    &-card {
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
    &-card-header {
      padding: 13px 15px 8px 15px;
      border-bottom: 1px solid #DDD;
    }
    &-card-body > :not(:last-child) { 
      border-bottom: 1px solid #DDD;
    }
    &-card-footer-flex {
      padding: 10px 15px;
      border-top: 1px solid #DDD;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-card-footer {
      padding: 10px 15px;
      border-top: 1px solid #DDD;
    }
    &-product {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;

      &-qty {
        width: 60px;
        text-align: center;
        margin-right: 20px;
      }
      &-qty h1 {
        color: var(--text-title-gray-3) !important;
        margin: 0px;
      }
      &-img {
        width: 42px;
        margin-right: 15px;
      }
      &-price {
        margin: 5px 0px 0px 0px;
      }
      &-info {
        width: 130px;
      }
    }
    &-info-container { 
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
    }
    &-discounts {
      margin-bottom: 20px;
      text-align: right;
    }
    &-buttons {
      min-height: 100%;
      padding-left: 10px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;

      &-updown {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--color-blue);
      }
      &-icon {
        margin: 0;
        width: 24px;
        height: 24px;
        color: #ffff;
      }
    }

    &-dark-title {
      margin-top: 0px;
      color: var(--text-title-gray-3) !important;
    }
    &-margined-title {
      margin: 28px 0px 0px 0px;
    }
    &-spacer {
      height: 20px;
    }
  }
  .review-order-type {
    &-back-btn { 
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .old-gray-price {
      text-decoration: line-through !important;
      color: var(--text-title-gray-9) !important;
    }
  }
  .evidence-type {
    width: 100%;
    max-width: 390px;
    margin: 0px auto;
    &-card {
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
    &-card-header {
      padding: 13px 15px 8px 15px;
    }
    &-card-section {
      padding: 13px 15px 8px 15px;
      border-top: 1px solid #DDD;

      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 0px;
    }
    &-product {
      &-qty h1 {
        color: var(--text-title-gray-3) !important;
        margin: 0px;
      }
      &-img {
        width: 42px;
        margin-right: 15px;
      }
      &-price {
        margin: 5px 0px 0px 0px;
      }
      &-info {
        width: 130px;
      }
      &-checkbox-container {
        width: 60px;
        text-align: center;
        margin: 0px 10px 0px 20px;
      }
      &-checkbox-container input[type='checkbox'] {
        visibility: hidden;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 5px;
        border: 2px solid #555;
      }
      &-checkbox-container input[type='checkbox']:after {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        position: relative;
        content: '';
        display: inline-block;
        visibility: visible;
        background-color: white;
        border: 2px solid var(--color-blue);
      }
      &-checkbox-container input[type='checkbox']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        position: relative;
        background-color: var(--color-blue);
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid var(--color-blue);
      }
      &-checkbox {
        margin-right: 10px;
      }
    }
    &-spacer {
      height: 20px;
    }
    &-photos-title {
      margin: 0px 0px 10px 0px;
    }
  }
  .contact-type {
    padding: 0px 10px;

    &-option-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 10px;
      background-color: white;
    }
    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 20px;
    }
    &-info-section {
      display: flex;
      align-items: center;
    }
    &-radio {
      margin-top: 6px;
    }
    &-radio-container input[type='radio'] {
      visibility: hidden !important;
    }
    &-inferior-section {
      width: 90%;
      margin: 20px auto 0px auto;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
  }
  .contact-other-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-field {
      width: 100%;
      max-width: 300px;
    }
  }
  .fixed-btn-section {
    z-index: 3;
    height: 100px;
  }
  .fixed-btn-container {
    z-index: 3;
    width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color-blue);
  }
  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    background-color: white;
    border: 2px solid var(--color-blue);
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--color-blue);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--color-blue);
  }
}