.brand-picture-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  color: #b2b2b2;
  background: white;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
}
