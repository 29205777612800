.page-notifications {
  ion-content {
    --background: var(--background-color-blue);
    .loading-section-notification {
      --color: var(--text-label-color);
      width: 100%;
      text-align: center;
      padding-top: 14.5em;

      .spinner-mid {
        padding-left: 22em;
      }

      .loading-notification {
        font-size: 14px;
        color: var(--text-label-color);
      }
    }
    .header-notifications {
      margin: 8%;
      .tittle {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-title-gray-3);
        max-width: 1000px;
        margin: 0 auto;
      }
    }
    .container-not-notifications {
      min-height: 55vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .message {
        color: #ccc;
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 700;
      }
      .text {
        color: #ccc;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .page-card {
    margin: 0 auto;
    .container-card-notification {
      display: flex;
      align-items: center;
      margin: 4%;
      background: white;
      border-bottom: 1px solid #eee;
      border-radius: 10px;
      padding: 16px;
      box-shadow: 0px 3px 10px rgba(30, 55, 153, 0.05);
      .img-wrapper {
        width: 50px;
      }
      .info-wrapper {
        margin-left: 15px;
        flex: 1;
      }
      .texto {
        font-size: 15px;
        margin: 8px 0;
        font-weight: 400;
        color: var(--text-title-gray-3);
        text-align: start;
      }
      .circle-is-open {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: var(--background-green-primary);
        left: 95%;
      }
      .code-subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--normal-text);
      }
      .code {
        font-size: 14px;
        margin: 8px 0;
        font-weight: 400;
        color: var(--normal-text);
        text-align: start;
      }
      .titulo-notif-card {
        color: var(--color-blue);
        font-weight: 700;
        font-size: 16px;
        text-align: start;
        margin-bottom: -5px;
      }
      .call-to-action-text {
        color: var(--primary-color-celeste);
        cursor: pointer;
      }
      .notif-card-date {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-3);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }
      .notif-card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .container-coupon {
      padding: 0.4rem;
      padding-bottom: 0;
      margin: 0.5rem 0.8rem;
      background: white;
      min-height: 3rem;
      vertical-align: middle;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }
  .selected-segment {
    color: var(--text-label-color);
  }
  ion-segment {
    --background: var(--background-color-blue);
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    ion-segment-button {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: none;
      color: var(--text-title-gray-3);
    }
    .isChecked {
      color: var(--color-blue);
      font-weight: bold;
    }
  }

  .header-md::after {
    background-image: none !important;
  }
  .header-ios ion-toolbar:last-of-type {
    --border-width: 0px !important;
  }
  .notifications-container {
    transition: all 1s ease-out;
    opacity: 1;
    overflow: hidden;
    position: relative;
    top: 0px;
    width: 100%;
    min-height: 100vh;
  }
  .disappear {
    opacity: 0;
    height: 0;
    top: 100px;
  }
  .empty-img {
    width: 140px;
    margin: 0px auto 20px auto;
  }
}

.page-open-notification {
  z-index: 3;
  ion-content {
    .loading-section-notification {
      --color: var(--text-label-color);
      width: 100%;
      text-align: center;
      padding-top: 14.5em;

      .spinner-mid {
        padding-left: 22em;
      }

      .loading-notification {
        font-size: 14px;
        color: var(--text-label-color);
      }
    }
    .container-open {
      max-width: 1000px;
      margin: 0 auto;
      .header-open-notification {
        margin: 4% 5% 0% 4%;
        display: flex;
        align-items: center;
        .left-title-container {
          flex-grow: 1;
          min-width: 200px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
        }
        .createdAt {
          margin-left: 5px;
          font-size: 14px;
          color: var(--text-title-gray-3);
        }
        .garbage {
          padding: 3% 3% 0 3%;
          margin-bottom: 9px;
          ion-icon {
            font-size: 19px;
          }
        }
      }

      .title-open-notif {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: var(--text-title-gray-3);
        display: inline-block;
        margin: 0px 5%;
      }

      .card-open-notification {
        margin: 24px 5%;
        border-radius: 6px;
        .container-createdAt-garbage {
          display: flex;
          justify-content: space-between;
          .createdAt {
            font-size: 12px;
            color: var(--text-title-gray-3);
            padding: 3% 0px 3% 0px;
          }
        }
        .container-body {
          margin: 24px 0;
          .body {
            font-size: 16px;
            font-weight: 400;
            color: var(--text-title-gray-3);
            margin-bottom: 24px;
          }
          .img-notif {
            display: block;
            margin: 0px auto;
          }
        }
        .container-btn-notification {
          display: flex;
          justify-content: center;
          .on-notification {
            width: 90% !important;
          }
        }
        .contariner-coupon-code {
          cursor: pointer;
          border: 1px solid var(--primary-color-celeste);
          border-radius: 20px;
          margin: 15px auto 0px auto;
          max-width: 400px;
          height: fit-content;
          .code {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: var(--primary-color-celeste);
            padding: 10px 0px 10px 0px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

ion-modal.modal-validate-coupon {
  --backdrop-opacity: 0.2 !important;
  .modal-wrapper {
    background: none;
    div {
      left: 8%;
      right: 5%;
      top: 35%;
      width: 83%;
      .content-validate-coupon {
        background: #fff;
        border-radius: 8px;
        height: 16.8em;
        padding: 29px;
        width: 100%;

        .container-title-validate-coupon {
          font-size: 20px;
          font-weight: 600;
          color: var(--text-title-gray-3);
          display: flex;
          justify-content: flex-start;
        }
        .subTitle-validate-coupon {
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-3);
          margin-top: 30px;
        }
        .footer-validate-coupon {
          font-size: 14px;
          font-weight: 700;
          color: var(--primary-color-celeste);
          text-transform: uppercase;
          margin-top: 35px;
          float: right;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .page-notifications {
    ion-content {
      .header-notifications {
        margin: 5% -1% 2% 2%;
      }
    }
    .page-card {
      .container-card-notification {
        margin: 3% 2% 3% 2%;
      }
    }
  }

  .page-open-notification {
    ion-content {
      .container-open {
        .header-open-notification {
          margin: 5% -1% 2% 7%;
          .tittle {
            margin: 0% 2% 2% 2%;
          }
        }
      }
    }
  }
}
