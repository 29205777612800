
ion-modal.spinner-container-full-page{
  --background: transparent;
  --box-shadow: none !important;
  ion-content {
    --background: transparent;
    --box-shadow: 0;
    min-height: 100%;
    display: flex;
    .spinner-container {
      height: 100%;
      .spinner {
        margin: auto;
        width: 40px;
        height: 40px;
        background-color: var(--color-blue);
        border-radius: 100%;  
        -webkit-animation: sk-scaleout-full-page 1.4s infinite ease-in-out;
        animation: sk-scaleout-full-page 1.4s infinite ease-in-out;
      }
    }
  }
  
  @-webkit-keyframes sk-scaleout-full-page {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout-full-page {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
}