.promotion-icon-box {
  background-color: #F7432B;
  padding: 2px 5px;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
}
.promotion-info-text {
  color: white;
  font-weight: 700;
  margin: 0px;
}