.safe-area-content {
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
  
  .safe-area-content.ios {
    --padding-top: var(--ion-safe-area-top, 44px);
    --padding-bottom: var(--ion-safe-area-bottom, 34px);
  }
  
  .safe-area-content.android {
    --padding-top: var(--ion-safe-area-top, 24px);
    --padding-bottom: var(--ion-safe-area-bottom, 0px);
  }
  
  .safe-area-container {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  
  @supports (padding-top: env(safe-area-inset-top)) {
    .safe-area-content.ios {
      --padding-top: env(safe-area-inset-top);
      --padding-bottom: env(safe-area-inset-bottom);
    }
  }
  
  /* Ajustes específicos para Android */
  .safe-area-content.android {
    padding-top: var(--padding-top);
  }