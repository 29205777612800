.loading-layer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(31, 60, 124, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__animated-circle {
    width: 40px;
    height: 40px;
    background-color: var(--color-blue);
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.4s infinite ease-in-out;
    animation: sk-scaleout 1.4s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }
}