.location-confirmation {
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color-blue);

  &__form {
    width: fit-content;
    margin: 0px auto 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__field-container {
    display: flex;
    justify-content: left;
    max-width: 300px;
  }
  &__checkbox-field {
    margin-top: 20px;
  }
  &__reference-info {
    width: 100%;
    border: 1px solid var(--color-blue);
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px;
  }
  &__submit-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

.location-confirmation__form {
  .btn-primary {
    margin-top: 1rem;
  }
}