.geolocalization-map {
  width: 100%;
  height: 60vh;
  position: relative;
  
  &__marker-container {
    pointer-events: none;
    z-index: 3;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__find-btn-container {
    z-index: 3;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 1rem;
    right: 1rem;

  }
}
.map-marker {
  width: 30px;
  height: 30px;
  position: relative;
  bottom: 20px;
  pointer-events: none;
}
.find-me-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-celeste);
  padding: 8px 16px;
  border-radius: 100px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  
  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
  &--disabled {
    opacity: 0.3;
  }
}