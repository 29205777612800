.terms-modal::part(content) {
  background: transparent;
}

.terms-modal {
  --box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2) !important;
  background: rgba(31, 60, 124, 0.3);
  // ion-backdrop{
  //   background: rgba(31, 60, 124, 0.3);
  // }

  .ion-page {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }

  
  .content {
    position: relative;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2) !important;
    background: #fff;
    border-radius: 8px;
    height: fit-content;
    > .header {
      display: flex;
      justify-content: flex-end;
      font-size: 1.8em;
      color: #142242;
      opacity: 0.5;
      margin: 13px;
      margin-bottom: -4px;
    }

    > .body {
      padding: 0 24px;
      margin-top: 35px;
      .container-information-modal{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      .container-information-modal{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-blue);
        margin-bottom: 8%;
      }
    }
    .footer {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      padding: 0px 24px;
      text-align: right;
      .btn-tertiary {
        padding-right: 0px !important;
        text-align: right;
        width: 100%;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: var(--primary-color-celeste);
      }
    }
  }
  .modal-wrapper {
    display: inline-block;
    background: none;
    box-shadow: none;
    div.ion-page {
      margin: auto auto;
      padding-left: 2px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50%;
      transform: translateY(-30%); 
      overflow: visible;
      width: 83%;
      max-width: 300px;
      
    }
  }
}
