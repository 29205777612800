.categories-home-fulfillment {
  margin-bottom: 1rem;
  max-width: 90%;
  margin: 0 auto;

  & > h3 {
    color: var(--color-blue);
    font-weight: 700;
    font-size: 1.4rem;
  }

  & > .slides {
    padding-top: 0 !important;
    .swiper {
      .swiper-slide {
        display: flex;
        flex-direction: column;

        & > span {
          font-size: 0.8rem;
          color: #333333;
        }
      }

      .swiper-slide.slide-home-brand {
        width: 5.5rem;
      }
    }
  }
}
