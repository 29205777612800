
.container-desktop{
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.ds-content-page{
  h1{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--color-blue);
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: var(--color-blue);
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-blue);
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-blue);
  }
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-blue)
  }
  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-blue)
  } 
  .red-offer{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--emphasis-background-color)
  }
  .paragraph-1{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--normal-text)
  } 
  .paragraph-2{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--normal-text)
  } 
  .paragraph-3{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
}

.no-margin {
  margin: 0px;
}

.cart-badge-btn {
  background: #ec4733;
  border: 1px solid white;
  border-radius: 5rem;
  font-size: 0.5rem;
  width: 1rem;
  height: 1rem;
  padding: 0;
  line-height: 1rem;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  bottom: 37px;
  right: 21px;

  transform: translate(0px, 0px);
  z-index: 0;
  pointer-events: none;
}
.badge-anim-in { 
  transform: translate(-200px, 100px);
  opacity: 0;
  z-index: 199;
}
.badge-anim-out { 
  transition: all 0.3s ease-out;
  transform: translate(0px, 0px);
  z-index: 199;
}

.categories-toolbar-text {
  color: white !important;
  position: absolute;
  left: 5px;
  bottom: -8px;
  z-index: 1000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}
.relative-header-wrapper{
  position: relative;
}
