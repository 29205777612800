.modal-orders-confirmed {
  position: fixed;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .content {
    background-color: var(--background-color-blue);
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    > .header {
      font-size: 14px;
      font-weight: 700;
      color: var(--text-title-gray-6);
      text-align: center;
      margin-bottom: 31px;
      position: relative;
      top: 84px;
    }
    > .body-orders-confirmed {
      display: flex;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      align-content: center;
      width: 100%;
      justify-content: center;
      .star {
        height: 200px !important;
      }
      > div {
        &:nth-child(1) {
          height: 8em;
          width: 9em;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          background-size: auto 72%;
          background-position: center;
          background-repeat: no-repeat;
          height: 6em;
          width: 100%;
        }
      }
      > .container-info-productModal {
        text-align: center;
        padding: 4px 12px 24px 12px;
        margin-bottom: 10px;
        font-size: 13px;
        color: #f1f2f4;
        .icon-check {
          margin-bottom: -3px;
          margin-left: 5px;
        }
        .title-pay-orders-confirmed {
          font-size: 16px;
          font-weight: 700;
          color: var(--emphasis-background-color);
          text-transform: uppercase;
          margin-bottom: 26px;
        }
        .info-modal {
          font-weight: bold;
          font-size: 18px;
          text-align: left;
          margin: 11px;
          margin-bottom: -36px;
          text-align: center;
          color: var(--color-blue);
          > ion-icon { 
            margin-bottom: -3px;
            margin-left: 5px;
          }
        }
      }
      .container-btn-return{
        .return-button{
        --border-radius: 50px;
        width: 50%;
        height: 42px;
        --background: var(--primary-color-celeste);
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        } 
      }
      .container-btn-next{
        margin-top: 25px;
        margin-bottom: 10px;
        .next-button{
          --border-radius: 50px;
          width: 50%;
          height: 42px;
          --background: var(--color-blue);
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          } 
      }
    
    }
    > .footer-btn {
      margin-top: 54px;
      text-align: center;
      ion-button.contact {
        --border-radius: 50px;
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-color-celeste);
        --background: none;
        width: 90%;
        --border-color: var(--primary-color-celeste);
        --border-color: var(--primary-color-celeste);
        --border-width: 0.5px;
        --border-style: solid;
        --box-shadow: none;
      }
    }
    > .body {
      padding: 0 18px;
      > .title {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: #f7f7f7;
      }
      > .info-terms {
        font-size: 20px;
        font-weight: 400;
        color: #f7f7f7;
        margin-top: 30px;
      }
    }
  }
}