.confirm-page {
  margin: 0 auto;  
  max-width: 1000px;
  .container-recovery-password-confirm{
    --background: var(--background-color-blue);
  }     
}
.container-btn-validate{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.container-help{
  background: var(--background-color-blue);
  text-align: center;
  padding: 26px;     
  .help {
    font-size: 12px;
    font-weight: 700;
    color: var(--primary-color-celeste);
    text-decoration:none;
    margin-top: 5px;
    text-align: center;
    color:var(--normal-text);
    a{
      color:var(--normal-text);
      text-decoration: underline;
    }
  }
}
