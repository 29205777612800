.go-back {
  margin-top: 7px;
  width: 100%;
  .header {
    position: relative;
    .back-btn{
      ion-icon{
        color: var(--color-blue);
        top: 1rem;
        left: 1rem;
        position: absolute;
      }
    }
    .close-btn{
      color: var(--color-blue);
      top: 1em;
      left: 88%;
      position: absolute;
    }
  }
  .container-img{
    display: flex;
    justify-content: center;
    .img-validate-user{
      align-items: flex-start;
      background-size: auto 72%;
      background-position: center;
      background-repeat: no-repeat;
      height: 7em;
      width: 100%;
      margin-top: 48px;
      margin-bottom: 30px;
      animation: bounce; 
      animation-duration: 2s;
      animation-iteration-count: 1;
    }
    .diferent-margin{
      margin-bottom: -20px;
    }
    .img-survey{
      width: 250px;
      height: 216.49px;
    }
   .title-validate-user{
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-blue);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
   }
 }
 .has-border{
    border-bottom: 1px solid #ccc;
 }
}

