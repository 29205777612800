ion-app {
  .container-close {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    font-size: 22px;
    margin-top: 29px;
  }

  ion-content {
    white-space: pre-line;

    pre {
      font-size: inherit;
      color: inherit;
      border: initial;
      padding: initial;
      font-family: inherit;
    }

    .delete-page {
      overflow: scroll;
    }

    .delete-wrapper {
      background-color: var(--background-color-blue);
      padding: 0px 35px 20px 35px;
    }

    .title {
      font-weight: 700;
      text-align: center;
      color: var(--color-blue);
      line-height: 30px;
    }

    .general-text {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      text-align: justify;
      margin-top: 19px;
      line-height: 17px;
      color: var(--text-title-gray-3);

      >span {
        font-size: 14px;
        font-weight: 700;
        color: #1a237e
      }

      div {
        margin-top: 14px;
      }
    }

    .points {
      font-size: 18px;
      font-weight: 500;
      color: #00b0ff;
      margin: 27px 27px 27px 0px;
      margin-top: 23px;
    }

  }
}