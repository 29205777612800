.checkbox-component {
  display: flex;
  align-items: center;
  
  &__checkbox {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #1E3799;
    margin: 10px;
  }
  &__checked {
    background: #1E3799;
  }
  &__label {
    font-size: 14px;
    color: #1E3799;
    font-weight: 400;
  }
}