.brands-fulfillment-content {
  margin: 1rem auto 1.5rem auto;
  max-width: 60rem;

  & > div {
    max-width: 90%;
    margin: 0 auto;

    & > h3 {
      color: var(--color-blue);
      font-weight: 700;
      font-size: 1.5rem;
    }

    & > div {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 1rem;

      @media only screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
      }

      & > div {
        cursor: pointer;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          width: 4.5rem;
          height: 4.5rem;

        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
