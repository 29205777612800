.contact-page {
  .scroll {
    background: var(--background-color-blue);
    overflow: auto;
    width: 100vw;
    height: 100vh;
    .input-validate {
      margin: 0px 10%;
      margin-top: 24px;
      max-width: 800px;
      .subtitle-validate-user{
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        margin-bottom: 42px;
      }
    }
    .container-header-help{
      display: flex;
      justify-content: flex-start;
      margin: 20px 20px 40px 20px;
      .arrow-back{
        width: 30px;
        height: 30px;
      }
      .label-header-help{
        font-style: normal;
        font-weight: bold;
        line-height: 17px;
        margin-left: 10px;
        color: var(--color-blue);
      }
    }
    .separator {
      border-bottom: 1px solid #ccc;
      margin: 20px 0px;
      margin-top: 50px;
    }
    .center-text {
      text-align: center;
      font-size: 0.87em;
      font-weight: 400;
      color: var(--text-title-gray-3);
    }
    .container-contact-forms{
      display: flex;
      justify-content: center;
      .item-contact {
        font-size: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2rem 1rem;
        line-height: 2rem;
        ion-icon {
          font-size: 1.5rem;
          display: inline-block;
          &.mail {
            color: #c14940;
          }
          &.whatsapp {
            color: var(--color-blue);
          }
          &.phone {
            color: var(--color-blue);
            position: relative;
          }
        }
        a {
          font-size: 1.0rem;
          display: inline-block;
          text-decoration: none;
          color: var(--color-blue);
          cursor: pointer;
        }
      }
      .item-contact-whatsapp{
        font-size: 22px;
        text-align: center;
        margin: 2rem 1rem;
        line-height: 2rem;
        margin-bottom: -18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        ion-icon {
          font-size: 1.5rem;
          display: inline-block;
          &.mail {
            color: #c14940;
          }
          &.whatsapp {
            color: var(--color-blue);
            position: relative;
          }
          &.phone {
            color: var(--color-blue);
          }
        }
        a {
          font-size: 1.0rem;
          display: inline-block;
          text-decoration: none;
          color: var(--color-blue);
          cursor: pointer;
        }
      }
    }
  }
}