.swiper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .swiper-slide {
    // width: fit-content !important;
  }
}

.container-reder-products {

  .swiper-wrapper {
    flex: 1;
    min-height: 0;
  
    /* Styles for screens smaller than 800px */
    @media screen and (max-width: 799px) {
      height: 10em !important;
    }
  
  }
  

}

.swiper-wrapper {
  flex: 1;
  min-height: 0;

  /* Styles for screens smaller than 800px */
  @media screen and (max-width: 799px) {
    // height: 10em !important;
  }

}

.swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: fit-content;
}

.guru-slide-container,
.visibility-tracker-promotion-card,
.banner-slide-home {
  height: 100%;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promotion-img-home {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.guru-slide-container {
    width: 100% !important;
}