.register-optional {
    .wrapper {
      position: absolute;
      .header {
        position: relative;
        ion-icon {
          color: white;
          top: 1rem;
          left: 1rem;
          position: absolute;
        }
      }
    }
    .guru-pro-register{
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #333;
      margin-top: 59px;
    }
    .welcome-title {
      text-align: center;
      margin-top: 10px;
      div {
        &:first-child {
					font-size: 14px;
					font-weight: 400;
					color:var(--text-title-gray-6);
          padding-top: 4px;
          margin-bottom: -4px
      
        }
        &:last-child {
					font-size: 14px;
					font-weight: 400;
					color:var(--text-title-gray-6);
          padding-top: 12px;
        }
      }
    }
		.continue {
			font-size: 14px;
			font-weight: 400;
			color:var(--text-title-gray-9);
      --background: none;
			margin: 14px;
       margin-top: 35px;
       --background-activated:none;
       --background-focused:none;
       --background-hover:none;
       text-decoration-line: underline;
       --box-shadow: none;
       border: none;
		}
  }
  

  .container-photo-register{
    margin: 28px;
    div{
      ion-select{
        border: 1px solid  #CCC;
        --placeholder-color: var(--text-title-gray-6);
        --placeholder-opacity: 1;
      }
      ion-item{
        ion-input{
          --placeholder-color: var(--text-title-gray-6);
          --placeholder-opacity: 1;
        }
      }
      .tittle-register{
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        padding: 10px 0px 10px 0px;
      }
      .photos{
        margin: -6px;
        ion-grid{
          padding: 0px;
          ion-row{
            ion-col{
              .container-img{
                .ahead-rear{
                  font-size: 12px;
                  font-weight: 600;
                  color: var(--text-title-gray-6);
                  text-transform: uppercase;
                  padding: 10px 0px 10px 0px;
                  height: 45px;
                }
                
                .btn-upload-photos{
                  font-size: 14px;
                  font-weight: 400;
                  color:  var(--primary-color-celeste);
                  text-align: right;
                  padding: 4px 0px 4px 0px;
                }
                div{
                  ion-img{
                    margin: 0px;
                  }
                  .not-image{
                    width: 100%;
                    height: 100%;
                    padding-bottom: 76%;
                    /* background-color: #6e6d6d; */
                    /* display: flex; */
                    /* align-items: flex-start; */
                    /* justify-content: center; */
                    background-size: auto 100%;
                    /* background-position: center; */
                    /* background-repeat: no-repeat; */
                    /* width: 100%; */
                    background-repeat: no-repeat;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  @media (min-width: 1020px) { 
  .container-photo-register{
    max-width: 799px;
    margin: 0 auto;
  }
}
