.search-bar {
  background-color: var(--background-color-blue);
  display: flex;
  align-items: top;
  justify-content: center;
  padding: 6px 0px 9px 0px;
  &.transparent-background{
    background-color: transparent;
  }
  &.no-padding-top {
    padding-top: 0rem;
  }
  > div {
    width: 90%;
    position: relative;
    .search-bar-icon {
      width: 16px;
      height: 16px;
    }
    input {
      min-height: 42px;
      width: 100%;
      border: none;
      border-radius: 10px;
      padding: 8px 38px 8px 20px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

      font-weight: 400;
      color: var(--text-primary-color);
      font-size: 12px;
      font-family: 'Nunito','Open Sans', sans-serif;

      &::placeholder {
        color: var(--text-label-color);
        opacity: 0.8;
      }

      &:focus {
        outline: none;
      }
    }

    > ion-icon {
      position: absolute;
      right: 12px;
      color: var(--text-label-color);
      top: 13px;
      font-size: 20px;
    }
  }
}
