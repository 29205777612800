.container-office-user{
  margin-top:50px;
  .info-adress{
    div{
      p{
        width: 86%;
      }
    }
  }
  .container-office{
    .container-header{
      display: flex;
      justify-content: space-between;
      padding: 0 13px 0 13px;
      .container-title-action {
        display: flex;
        flex-direction: row;
        align-items: center;
        .back-small-arrow{
          ion-button{
            --background:none;
            --box-shadow: none;
            --border-color: none;
            --box-shadow: none;
            --background-focused:none;
            --background-activated:none;
            --background-activated:none ;
            --background-hover: none !important;
            margin: 0px;
            margin-top: 3px;
          }
        }
        .container-title{
          h1{
            margin: 11px 60px 7px 20px;
          }
        }
      }
      .send{
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: var(--new-primary-color-celeste);
        --background: none;
        --box-shadow: none;
        --border-color: none;
        --box-shadow: none;
        --background-focused:none;
        --background-activated:none;
        --background-hover: none !important;
      }
    }

    .container-google-map{
      padding: 20px 0;
    }
    .container-office-input{
      padding: 0 30px 0 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container-input {
        max-width: 299px !important;
      }
      .container-text-input{
        width: 299px;
      }
      .containet-select-input{
        margin-bottom: 10px;
        ion-label{
          display: flex;
          margin-left: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          margin-top: 10px;
          color: var(--color-blue);
        }
        .container-input-search-component .container-input-search{
          margin: 0;
        }
      } 
    }
    .container-patent{
      margin: 25px;
      .container-patent-tittle{
        display: flex;
         justify-content: space-between;
         .icon{
          margin-top: 15px;
          font-size: 18px;
         }
      } 
      ion-grid{
        margin: -10px;
      }
    } 
    .container-btn-submit{
      padding: 22px;
      display: flex;
      justify-content: center;
      .btn-submit{
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: white;
        min-width: 200px;
        --background: none;
        background-color: var(--color-blue);
        border-radius: 100px;
        --box-shadow: none;
        --border-color: none;
        --box-shadow: none;
        --background-focused:none;
        --background-activated:none;
        --background-hover: none !important;
      }
    }
  }
}