.plp-page {  
  .cart-badge-btn-search {
    background: #ec4733;
    border: 1px solid white;
    border-radius: 5rem;
    font-size: 0.5rem;
    width: 1rem;
    height: 1rem;
    padding: 0;
    line-height: 1rem; 
    text-align: center;
    vertical-align: middle;

    position: absolute !important;
    bottom: 28px;
    right: 20px;

    z-index: 0;
    pointer-events: none;
    transform: translate(0px, 0px);
  }
  .badge-anim-in { 
    transform: translate(-200px, 100px);
    opacity: 0;
    z-index: 199;
  }
  .badge-anim-out { 
    transition: all 0.3s ease-out;
    transform: translate(0px, 0px);
    z-index: 0;
  }

  .without-products {
    text-align: center;
    ion-img {
      max-width: 70%;
      margin: 1rem auto;
    }
    .message {
      font-weight: bold;
      color: #142242;
      margin: 1rem 0;
      font-size: 1.25rem;
    }
    ion-button {
      width: 70%;
    }
  }

  .product-wrapper {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f1f1f1;
    > .header {
      padding: 6px 12px;
      > span {
        font-weight: bold;
        color: var(--text-primary-color);
      }
    }
  }

  div.product-list {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    > .product-card {
      display: inline;
      margin-left: 3%;
      max-width: 30%;
      margin-bottom: 11px;
      background: white;
    }
  }


}
