@import '../scss/base';

.btn{
  &-primary{
    $gradient-list: #1C58ED 0%, $primary-background-color 50%;
    @include btn-properties(
      $background: $color-blue, 
      $background-active: $primary-background-color, 
      $gradient-list: $gradient-list, 
      $color: white
    );
  }

  &-secondary{
    $gradient-list: white 0%, $primary-color-celeste 50%;
    border-color: white;
    @include btn-properties(
      $background: $primary-color-celeste, 
      $background-active: $blue-active-filter, 
      $gradient-list: $gradient-list, 
      $color: white, 
      $opacity-disabled: 0.3
    );
  }

  &-tertiary{
    $gradient-list: #1C58ED 0%, $primary-background-color 50%;
    @include btn-properties(
      $background: transparent,
      $background-active: transparent, 
      $gradient-list: $gradient-list, 
      $color: $primary-color-celeste 
    );
    &:hover, &.hover, &:active, &.active{  
      &:not(.disabled):not(.loading){
        color: $color-blue;
      }
    }
    
  }
}
