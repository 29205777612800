.spinner-container {
  display: flex;
  align-items: center;
  .spinner {
    width: 40px;
    height: 40px;
    margin: 50% auto;
    background-color: var(--color-blue);
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.4s infinite ease-in-out;
    animation: sk-scaleout 1.4s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}
