.coupons-section {
  border-top: 1px solid var(--form-gray);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0.7rem 0.7rem 0.2rem 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &--aplicated {
    background: #52d9cb;
  }

  &--no-aplicated {
    background-color: var(--orange-promotion);
  }

  & > h6,
  span {
    font-weight: 600;
    font-size: 0.9rem;
    color: #fff;
    display: flex;
  }

  & > span {
    text-decoration: underline;
    margin-bottom: 0.4rem;
    cursor: pointer;
  }
}
