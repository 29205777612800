.plp-page-search{
  div{
    .container-card-search-products{
      max-width: 1000px;
      margin: 0 auto;
      margin-top: 3%;
      .product-card{
        width: 45%;
        float: left;
        margin-left: 3%;
        margin-bottom: 4%;
        background: white;
      }
    }
  }
  .product-wrapper {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f1f1f1;
    > .header {
      padding: 6px 12px;
      > span {
        font-weight: bold;
        color: var(--text-primary-color);
      }
    }
  }

  div.product-list {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    > .product-card {
      display: inline;
      margin-left: 3%;
      max-width: 30%;
      margin-bottom: 11px;
      background: white;
    }
  }
  .not-product{
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    font-size: 14pt; 
    font-weight: 400; 
    color: #ccc;
    position: absolute;
    top: 40%; 
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    div{
      .container-text{
        display: flex;
        justify-content: center;
        .text{
          width: 55%;
        }
      }
    }
  }
  .loading-product{
    width: 100%;
    font-size: 14px; 
    font-weight: 400; 
    color: #ccc;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .search-products-wrapper {
    width: 100%;
    font-size: 14px; 
    font-weight: 400; 
    color: #ccc;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .icon {
      width: 250px;
    }
    .text {
      margin-top: 0px;
    }
  }
}


@media only screen and (min-width: 420px) {
  .plp-page-search{
    .not-product{
      ion-img{
        margin-bottom: -154px;
      }
    }
  }
}