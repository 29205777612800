.container-payment-view {
  --background: var(--background-color-blue);
  .container-middle-text{
    min-height: 90%;
    max-width: 1000px;
    margin: 0 auto;
    .card-guru-white-screen{
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
    }
    .container-icon-and-title{
      width: 100%;
      margin-bottom: 20px;
      .title-payments-methods{
        margin: 0;
        display: inline-block;
        width: 88%;
        padding-top: 30px;
        padding-left: 20px;
        color: var(--color-blue);
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
      .container-icon-tooltip{
        display: inline-block;
        font-size: 22px;
      }
    }
      .no-payment-methods{
        color: #ccc;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
      }
  }
  .container-no-methods{
    text-align: center;
    margin: 0 auto;  
    max-width: 1000px;
    margin-top: 131px;
    ion-img {
      max-width: 40%;
      margin: 1rem auto;
    }
  }
}

.container-btn-add-payment{
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
  margin-top: -10px;
}

.add-credit-card{
  min-height: 100%;
}

.container-labels-add-card{
margin: 10px 10px 10px 10px;
border-radius: 20px;
border: solid #b2b2b2 1px;
font-size: 14px;
font-weight: 700;
color: #666;
  hr{
    background-color:var(--text-label-color);       
  }
  .owner-card{
    color:var(--text-primary-color);
    margin: 0 0 0 15px;
  }
  .card-date{
    color:var(--text-primary-color);
    margin-left: 15px;
    margin: 15px 0 0 15px;
  }
}
.btn-save-card{
margin: 16% 10px 10% 10px;
}

hr{
  border-width:1px
}

.alert-for-delete{
  .alert-message {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-title-gray-6);
    margin-bottom: 11px;
  }
  .alert-header{
    font-weight: bold;
    color: black;
  }
  .buttom-alert-cancel{
    color: var(--text-title-gray-6);
    margin-right: 21%;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;

  }
  .buttom-alert-confirm{
    color: var(--primary-color-celeste);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: -11px;
  }
  .alert-wrapper.sc-ion-alert-md{
    min-width: 300px;
    max-width: 300px;
    border-radius: 6px;
    height: 15em;
  }
  .alert-wrapper .alert-title.sc-ion-alert-md {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-title-gray-3);
    margin-bottom: 11px;
    text-align: initial !important;
    text-transform: initial
  }
  .alert-button-group.sc-ion-alert-md {
    margin-left: -23px;
  }
}


.card-associate {
  margin: 0.5rem 1rem;
  background: white;
  min-height: 4.5rem;
  max-height: 4.5em;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  display: flex;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05); 
  animation: fadeInUp; 
  animation-duration: 1.6s;
  &.deleting{
    border: solid #EB2F06 1px;
  }
  .img-of-cards {
    height: 3.5rem;
  }
  .cards {
      position: relative;
      line-height: 2rem;
      flex: 1;
      width: 100%;
      .container-info-card {
        margin-top: 8px;
        display: inline-block;
        width: 90%;
        .rut-account{
          padding-left: 2rem;
          padding-bottom: 0.6rem;
          padding-top: 10px;
          color:var(--text-title-gray-3);
          font-size: 16px;
          font-weight: 600;
          line-height: 0.85rem;
        }
        .id-cuenta{
          padding-left: 2rem;
          padding-top: 10px;
          color:var(--primary-color-celeste);
          font-size: 16px;
          font-weight: 600;
          line-height: 0.85rem;
        }

        .name-associate-with-card-banco-estado{
          padding-left: 2rem;
          padding-bottom: 0.3rem;
          color:var(--text-title-gray-3);
          font-style: normal;
          font-weight: normal;
          font-size: 0.7em;
          line-height: 15px;
        }
        .last-four-digits{
          padding-left: 2rem;
          padding-bottom: 0.5rem;
          padding-top: 0.3rem;
          color:var(--color-blue);
          font-weight: 600;
          font-size: 12px;
          line-height: 0.85rem;
        }
        .name-associate-with-card{
          padding-left: 2rem;
          padding-bottom: 0.3rem;
          color:var(--text-title-gray-3);
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
        }
      }
      .container-icon-trash{
        font-size: 22px;
        display: inline-block;
        width: 10%;
        position: absolute;
        margin-top: 15px;
      }
    }
  }
  
  
.my-custom-class {
  --max-width:100%;
}

.info-card{
  .alert-wrapper{
     .alert-message.sc-ion-alert-md{
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
     }
     .alert-button.sc-ion-alert-md {
      color: var(--primary-color-celeste);
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
     }
  }
}

.my-custom-class .delete{
  --color: red;
  color: var(--color-blue);
  background: var(--background-color-blue);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 70px;
  span{
    justify-content: center;
  }
}

.my-custom-class .bank{
  --color: rgb(238, 138, 38);
  color: var(--color-blue);
  background: var(--background-color-blue);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 70px;
  span{
    justify-content: center;
  }
}

.my-custom-class .card{
  color: var(--text-title-gray-6);
  font-weight: 600;
  margin-left: 15%;
  background: var(--background-color-blue);
}

.my-custom-class .oneClick{
  color: var(--color-blue);
  background: var(--background-color-blue);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 70px;
  span{
    justify-content: center;
  }
}

ion-button{
  .button-add-card{
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
}

ion-spinner{
  margin-left: 10px;
}

.alert-card{
  .alert-wrapper.sc-ion-alert-md{
    min-width: 300px;
    max-width: 300px;
  }
  .alert-wrapper .alert-title.sc-ion-alert-md {
    margin: 0px;
    padding-top: 4px;
    font-size: 20px;
    font-weight: 600;
    color:var(--text-title-gray-3);
    text-align: initial;
  }
  .alert-button.sc-ion-alert-md{
    color: var(--primary-color-celeste);
    font-size: 14px;
    font-weight: 400;
  }
}



ion-modal.modal-one-click {
  .modal-wrapper {
    background: transparent;

    div.ion-page {
      left: 8%;
      right: 5%;
      top: 37%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 6px;
        height: 17em;

        .title{
          font-size: 20px;
          font-weight: 600;
          color: var(--text-title-gray-3);
          padding: 19px 19px 3px 19px;
          padding: 19px 19px 3px 19px;
          margin-top: 20px;
        }

        .content-info{
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-6);
          padding: 19px;
        }

        .footer {
          margin-top: 17px;
          padding: 0 18px;
          width: 100%;

          >.btn {
            font-size: 14px;
            font-weight: 400;
            color: var(--primary-color-celeste);
            float: right;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

