.schedules-fulfillment {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > p {
    font-size: 0.8rem;
    color: #666;

    & > span {
      color: var(--color-blue);
      font-weight: 600;
    }
  }

  & > p:nth-child(2) {
    margin-top: -0.5rem;
  }
}
