.container-dropdown-select{
  .container-label{
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 16px;
    color: var(--color-blue);
  }
  .disabled{
    color: var(--form-gray);
  }
  .select-container{
    position: relative;
    select{
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid var(--color-blue);
      width: 300px;
      height: 40px;
      margin: 10px 0px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      padding: 10px 20px 10px 20px;
      &:focus-visible{
        outline: none;
      }
      &:disabled{
        color: var(--form-gray);
        border: 1px solid var(--form-gray);
      }
    }
    .select-arrow {
      background-image: url("../../assets/icn_arrow-closed.svg");
      background-repeat: no-repeat;
      background-position-x: 94%;
      background-position-y: 17px;      
    }
  }
}
.css-1wy0on6 {
  display: none !important;
}
