ion-loading.close-session {
  --spinner-color: var(--primary-color);
  --background: #fff;
  color: var(--text-primary-color);
  --min-width: 70%;

  .loading-wrapper {
    box-shadow: -5px 5px 8px -5px #a2a2a2;
    border-radius: 100px;
  }
  ion-backdrop {
    background-color: var(--primary-background-color);
  }
}

.menu-page {
  .image-spacer {
    background-color: var(--primary-background-color);
  }

  .store-selection {
    margin: 0 7%;
    background-color: #fff;
    border-radius: 24px;
    position: relative;
    top: -11px;
    display: flex;
    height: 39px;
    align-items: center;
    padding: 0px 32px 0px 20px;
    font-size: 0.85em;
    line-height: 1em;
    color: var(--text-primary-color);
    box-shadow: 0px 0px 8px -3px #a2a2a2;
    > ion-item {
      position: absolute;
      width: 100%;
      z-index: 100;
      opacity: 0;
      left: 0;
    }
    > ion-icon {
      position: absolute;
      right: 16px;
    }
  }

  ion-item-divider {
    font-size: 0.8em;
    --background: #f9f9f9;
    min-height: 24px;
    padding-left: 2em;
    --color: var(--text-label-color);
  }
  ion-list {
    margin-top: 0px;
    a {
      cursor: pointer;
      text-decoration: none;
      ion-item {
        --padding-start: 0px;
        --border-color: #eaeaea;
        line-height: 1em;
        --padding-top: 3px;
        --min-height: 51px;

        &.no-border {
          --border-color: transparent;
        }

        ion-icon {
          padding-left: 0.85em;
        }
        ion-text {
          padding: 0 0 0 1.2em;
          font-size: 0.8em;
          color: var(--text-primary-color);
        }
      }
    }
  }
}
ion-img {
  margin-bottom: 0.9em;
}
