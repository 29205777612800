.coupon-section {
  width: 95%;
  background: #fff;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem auto 0 auto;
  padding: 1rem;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

  & > img {
    width: 3.3rem;
    height: 3.3rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > h4 {
      font-size: 1rem;
      color: var(--color-blue);
      font-weight: 700;
    }

    & > span {
      font-size: 0.8rem;
      color: var(--text-primary-color);
      font-weight: 500;
    }
  }
}
