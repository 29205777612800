ion-modal.recovery-password-modal {
  .modal-wrapper {
    background: transparent;

    div.ion-page {
      left: 8%;
      right: 5%;
      top: 24%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
        height: 21em;

        >.header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }

        >.body-recovery-password{
          display: flex;
          flex-direction: row;
          padding: 0 18px;

          >div {
            &:nth-child(1) {
              height: 8em;
              width: 9em;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              background-size: auto 72%;
              background-position: center;
              background-repeat: no-repeat;
              height: 11em;
              width: 100%;
            }
          }
        }

        >.container-info-productModal {
          text-align: center;
          padding: 4px 12px 24px 12px;
          font-size: 13px;

          .container-title-modal {
            font-size: 16px;
            font-weight: 700;
            color: var(--text-title-gray-3);
            text-transform: uppercase;
            margin-bottom: 33px;
          }

          .info-modal {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            text-align: left;
            margin: 11px;
            margin-bottom: -36px;
            text-align: center;
          }
        }
      }
    }
  }
}
