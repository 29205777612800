.product-slider-secundary {
  border-bottom: 1px solid #f1f1f1;
  > .container-subCategory {
    > .header {
      padding: 20px 12px 10px;
      display: flex;
      justify-content: space-between;
      > span {
        color: var(--color-blue);
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      } 
      .seeMore{
        margin-right: 40px;
        font-size: 14px;
        width: 14px;
        font-weight: bold;
        .button-more {
          display: flex;
          .see-all-products {
            margin-right: 6px;
            color: var(--color-blue);
          }
          .container-icon-right {
            padding-top: 2px;
          }
        }
      }
      .show-in-blue{
        color: var(--color-blue);
      }
      .name-into-header{
        color: #fff;
      }
      .see-button{
        color: #fff;
      }
      .counter-products{
        color: var(--text-title-gray-6);
        font-size: 16px;
        font-weight: normal;
      }
      .container-title{
        margin-right: 10px;
      }
    }
    .body {
      .provider-summary-order{
        display: flex;
        justify-content: center;
        padding-top: 0;
        .provider-header-order{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.8em 0.8em 0 0.8em;
          .container-provider-header{
            width: 100%;
            display: flex;
            justify-content: center;
            .provider-title {
              display: inline-block;
              font-size: 16px;
              font-weight: 600;
              color: var(--color-blue);
              margin-right: 8px;
            }
            .icon-provider{
              align-self: center;
              margin-right: 0px;
              width: 14px;
              padding-bottom: 4px;
            }
            .icon-anulate{
              transform: rotate(90deg) !important;
              margin-right: 10px;
            }
          }
        }
        .open-details-products{
          border-bottom: 1px solid rgba(31, 60, 124, 0.3);
        }
      }
      > div{
        display: flex;
        flex-wrap: wrap;
        .container-card{
          margin:2%;
          width: 45%;
        }
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-color);
    > div.button-more {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
}


.loading-products-secundary{
  text-align: center;
  padding-top: 100px;
  padding-bottom: 230px;
  color:var(--text-label-color);
  position: relative;
  width: 100%;
  .spinner-mid{
    margin: 0px auto 15px auto;
    --color:var(--text-label-color);
  }
}
