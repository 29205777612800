.modal-user-lock1{
	--backdrop-opacity:0.2!important;
	z-index: 0 !important;
	.modal-wrapper {
		background: none;
		div  {
			left: 8%;
			right: 5%;
			top: 8%;
			.content {
				background: #fff;
				border-radius: 8px;
				height: 35.8em;
				width: 100%;
				
				> .header {
					display: flex;
					justify-content: flex-end;
					font-size: 1.1em;
					opacity: 1.5;
					margin: 13px;
					margin-bottom: -46px;
				}

				> .body {
					display: flex;
					flex-direction: row;
					padding: 0 18px;
					margin-top: -26px;
					> div {
						&:nth-child(1) {
							height: 8em;
							width: 9em;
							display: flex;
							align-items: flex-start;
							justify-content: center;
							background-size: auto 42%;
							background-position: center;
							background-repeat: no-repeat;
					    height: 10em;
    					width: 99%;
						}
					}
				} >.container-info-productModal{
					text-align: center;
					padding: 4px 12px 0px 12px;
					font-size: 13px;
					margin-top: -37px;
					.container-title-modal{
						font-size: 20px;
						font-weight: 600;
						color: var(--text-title-gray-3);
						margin-bottom: 7px;
					}
					.container-subtitle-modal{
						font-size: 12px;
						font-weight: 400;
						color: #999;
						text-transform: uppercase;
						margin-bottom: 7px;
					}
					.container-text-modal{
						font-size: 12px;
						font-weight: 600;
						color:var(--text-title-gray-6);
						
					}
				}
				> .container-input{
					margin-bottom: -25px;
					width: 100%;
					ion-item{
						margin: 16px;
						margin-bottom: -16px;
					>	ion-icon {
							display: none;
							color: var(--primary-color);
							position: absolute;
							bottom: 8px;
							right: 0px;
						}
						
						&.item-has-focus {
							--highlight-color-focused: var(--primary-background-color);
						}
						&.item-valid {
							ion-icon {
								display: inline;
							}
						}
						ion-avatar{
							width: 29px;
							margin: 7px 7px 7px -4px;
							.img-avatar{
								height: 32px;
							}
						}
						ion-label{
							color: var(--text-title-gray-6);
							font-size: 14px;
							font-weight: 400;	
						}
						ion-input{
							ion-icon{
								margin-right: 16px;
							}
							--placeholder-color: var(--text-title-gray-6);
							--placeholder-opacity: 1;
							 --placeholder-font-weight: 400;
							 font-size: 14px;
						}
						
					}
					.container-textarea{
						margin: 16px;
						margin-top: 31px;
						.message{
							color: var(--text-title-gray-6);
							font-size: 14px;
							font-weight: 400;	
							margin-bottom: 10px;
						}
						ion-textarea{
							font-size: 14px;
							font-weight: 400;
							width: 100%;
							height: 6em;
							border: 1px solid #e3e3e3;
							padding: 10px;
						}
					}
				}> .footer{
					ion-button{
						--border-radius: 50px;
						margin: 40px 15px 43px 15px;
						--background:var(--primary-color-celeste);
						ion-spinner{
							position: absolute;
							left: 172px;
						}
					}
				}
      }
    }
	}
}



ion-modal.modal-restricted-access1{
	--backdrop-opacity:0.2!important;

	.modal-wrapper {
		background: none;
		div {
			left: 8%;
			right: 5%;
			top: 35%;
			width: 83%;
			.content-restricted-access {
				background: #fff;
				border-radius: 8px;
				height: 13.8em;
				padding: 29px;
				width: 100%;
				.header{
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
				.container-title-restricted-access{
					font-size: 20px;
					font-weight: 600;
					color: var(--text-title-gray-3);
					display: flex;
					justify-content: flex-start;
				}
				.subTitle-restricted-access{
					font-size: 14px;
					font-weight: 400;
					color: var(--text-title-gray-6);
					margin-top: 30px;
				}
				.footer-restricted-access{
					font-size: 14px;
					font-weight: 700;
					color: #999;
					text-transform: uppercase;
					margin-top: 35px;
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
			}
		}
	}
}
