.containet-tags-category {
  div.product-list-1 {
    margin-top: 4%;
    > .product-card {
      width: 29%;
      float: left;
      margin-left: 3%;
      margin-bottom: 4%;
      background: white;
    }
  }
  ion-content{
    .loading-tags{
      text-align: center;
      padding-top: 182px;
      padding-bottom: 10px;
      color:var(--text-label-color);
      position: relative;
      width: 100%;
      .spinner-mid{
        margin: 0px auto 15px auto;
        --color:var(--text-label-color);
    }
  }
  .card-tags{
    max-width: 1000px;
    margin: 0 auto;
    overflow: auto;
    div.product-list {
      margin-top: 4%;
      > .product-card {
        background: white;
        width: 29%;
        float: left;
        margin-left: 3%;
        margin-bottom: 4%;
      }
    }
  }
  ion-button{
    --border-radius: none;
    --background: none;
    --box-shadow: none;
    width: 100%;
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-color-celeste);
    --background-activated: none;
    --background-focused: none;
    --background-hover: none;
    ion-spinner{
      --color:var(--primary-color-celeste)
    }
  }
  }
}

