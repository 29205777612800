
.register-page-password{
    margin: 0 auto;  
    .username-field-wrapper {
      width: fit-content;
      min-width: 300px;
      margin: 0 auto;
    }
    .container-validate-user{
      --background: var(--background-color-blue);
    }
    .input-validate{
      margin: 0px 10%;
      margin-top: 24px;

      ion-item {
        ion-input {
          --color: var(--text-primary-color);
          input {
            margin-right: 5px;
          }
        }
  
        ion-label {
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-6)
        }
  
        ion-icon {
          display: none;
          color: var(--primary-color);
          position: absolute;
          bottom: 8px;
          right: 0px;
        }
  
        &.item-has-focus {
          --ion-item-border-color: var(--primary-color);
          .item-invalid-input {
            color: var(--ion-color-danger, #eb445a);
          }
        }
        
        .item-invalid-input {
          color: var(--ion-color-danger, #eb445a);
        }
  
        &.item-valid {
          --ion-item-border-color: var(--primary-color);
  
          ion-icon {
            display: inline;
          }
        }
  
        &.item-invalid {
          --ion-item-border-color: var(--ion-color-danger, #eb445a);
          ion-icon {
            display: inline;
          }
        }
      }

      .subtitle-validate-user{
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        margin-bottom: 42px;
      }
  }
  .container-validate-btn{
    display: flex;
    justify-content: center;
    margin-top: 20px
  }
  .container-help{
    text-align: center;
    padding: 26px;     
    .help {
      color:var(--normal-text);
      font-size: 12px;
      font-weight: 700;
      text-decoration:none;
      margin-top: 5px;
      text-align: center;
    }
  }
}


ion-modal.modal-user-invalid{
	--backdrop-opacity:0.2!important;
	.modal-wrapper {
		background: none;
		div {
			left: 8%;
			right: 5%;
			top: 35%;
			width: 83%;
			.content-user-invalid{
				background: #fff;
				border-radius: 8px;
				height: 17.8em;
				padding: 29px;
				width: 100%;
				.header{
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
				.container-title-user-invalid{
					font-size: 20px;
					font-weight: 600;
					color: var(--text-title-gray-3);
					display: flex;
					justify-content: flex-start;
				}
				.subTitle-user-invalid{
					font-size: 14px;
					font-weight: 400;
					color: var(--text-title-gray-6);
					margin-top: 30px;
				}
				.footer-user-invalid{
					font-size: 14px;
					font-weight: 700;
					color: var(--primary-color-celeste);
					text-transform: uppercase;
					margin-top: 35px;
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
			}
		}
	}
}