.office-geolocalization {

  header {
    height: 9rem;

    .office-geolocalization__location-search {
      width: 100%;
      height: 2rem;
      padding: 0px 1rem;

      input {
        width: 100%;
        padding: 1rem;
      }
    }
  }
  &__location-search {
    width: 100%;
    height: 30px;
    padding: 0px 18px;
  }
  &__submit-btn-container {
    z-index: 4;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color-blue);
  }
}