.provider-selection-page {
  div {
    > .divProvider,
    .divBrand {
      display: inline-block;
      width: 50%;
      > ion-button {
        width: 101%;
        margin: 0px;
        --border-radius: none;
        --background: var(--primary-background-color);
        --background-activated: var(--primary-background-color);
      }
    }
  }
  ion-content {
    > div {
      margin-top: -1em;
      .provider-brand-card {
        justify-content: center;
        align-items: center;
        width: 29%;
        height: 9em;
        display: inline-table;
        margin-left: 3%;
        margin-bottom: 4%;
        margin-top: 10px;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
        box-shadow: 1px 1px 9px -7px #a2a2a2;
        > .logo {
          background-size: auto 80%;
          background-position: center;
          background-repeat: no-repeat;
          height: 6em;
          width: 100%;
        }
      }
    }
    .provider-list {
      margin-top: 1em;
      .provider-card {
        justify-content: center;
        align-items: center;
        width: 29%;
        height: 10em;
        float: left;
        margin-left: 3%;
        margin-bottom: 4%;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        /*box-shadow: -5px 5px 8px -5px #a2a2a2;*/
        border: 1px solid #f1f1f1;
        border-radius: 4px;
        box-shadow: 1px 1px 9px -7px #a2a2a2;
        ion-text {
          font-weight: bold;
          vertical-align: middle;
          text-align: center;
        }
        > .logo {
          background-size: auto 80%;
          background-position: center;
          background-repeat: no-repeat;
          height: 6em;
          width: 100%;
        }
        > .name-provider {
          text-align: center;
        }
      }
    }
  }
}
