
.loading-container-guest-user {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color-blue);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container-text-loading-user-guest{
    width: 70%;
    text-align: center;
    .h4-label{
      color: var(--color-blue);
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin-top: 44px;
      margin-bottom: 27px;
    }
    .p-loading-cover-guest-user{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-title-gray-3);
    }
    .p-variable-text-cover-guest-user{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-blue);
      margin-top: 50px;
    }
  }
}

.loading-gif {
  display: block;
}