.brands-home-fulfillment {
  max-width: 87%;
  margin: 0 auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > .slides {
    padding-top: 0 !important;
    max-width: 90%;

    @media only screen and (max-width: 912px) {
      max-width: 85%;
    }

    @media only screen and (max-width: 820px) {
      max-width: 82%;
    }

    @media only screen and (max-width: 768px) {
      max-width: 87%;
    }

    @media only screen and (max-width: 540px) {
      max-width: 72%;
    }

    @media only screen and (max-width: 414px) {
      max-width: 67%;
    }

    @media only screen and (max-width: 395px) {
      max-width: 75%;
    }

    @media only screen and (max-width: 360px) {
      max-width: 50%;
    }

    @media only screen and (max-width: 280px) {
      max-width: 30%;
    }

    .swiper {
      .swiper-slide.slide-home-brand {
        width: 5.5rem;
      }
    }
  }

  & > .all-brands {
    display: inline;
    height: 4.2rem;
    width: 4.2rem;
    background-color: var(--color-blue);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.7rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-left: 0.4rem;

    @media only screen and (max-width: 992px) {
      margin-right: 1rem;
    }
  }
}
