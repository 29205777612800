.register-page {
  margin-top: 10px;
  .loading-container-guest-user {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color-blue);
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    .container-text-loading-user-guest{
      width: 70%;
      text-align: center;
      .h4-label{
        color: var(--color-blue);
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-top: 44px;
        margin-bottom: 27px;
      }
      .p-loading-cover-guest-user{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-title-gray-3);
      }
      .p-variable-text-cover-guest-user{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-blue);
        margin-top: 50px;
      }
    }
  }
    .container-stepper{
      display: flex;
      margin-left: 180px;
      margin-top: 12px;
    }
    .form-register {
      width: 300px;
      margin: 0px 10%;
      .container-input-search-component .container-input-search .css-yk16xz-control {
        border-radius: 20px;
        border-color: var(--color-blue);
    }
        .container-form{
          .container-dropdown-select .container-label{
          font-style: normal; 
          font-weight: 600 ;
          font-size: 14px ;
          margin-top: 10px ;
          }
          .container-dropdown-select .select-container select{
            margin: 10px 0px ;
           
          }
          .container-dropdown-select .select-container  select option {
            margin: 40px;
            background-color: rgba(0, 0, 0, 0.3) !important;
            color: #fff;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
          }
          .containet-bussiness{
            .title-type-bussiness{
              display: flex;
              margin-left: 20px;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              margin-top: 10px;
              color: var(--color-blue);
            } 
          }
        }
      
      .register-invited-btn{
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        .btn-tertiary {
          font-size: 20px!important;
       }
      }
      .container-checkbox{
        margin-top: 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        align-items: center;
        margin-left: 4px;
        display: flex;
        width: 310px;
        .checkbox{
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 2px solid #1E3799;
          margin: 10px;
        }
        .checked{
          background: #1E3799;
        }
        .label-terms{
          .terms-text{
            margin-left: 4px;
            text-decoration-line: underline;
            display: inline;
          }
        }
        label{
          display: contents;
          a{
            color:var(--normal-text);
            text-decoration: underline !important;
          }
        }
      }
      .container-btn-stepper{
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
      }
      .help {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color:var(--normal-text);
        text-decoration:none;
        margin-top: 5px;
        .label-help{
          color:var(--normal-text);
          a{
            color:var(--normal-text);
          }
        }
      }
      
      ion-item {
        ion-input {
          --color: var(--text-primary-color);
        }

        ion-label {
          font-size: 14px;
          font-weight: 400;
          color: #666;
        }

        ion-icon {
          display: none;
          color: var(--primary-color);
          position: absolute;
          bottom: 8px;
          right: 0px;
        }

        .phone-box {
          align-items: center;
          vertical-align: middle;
          
          .phone-prefix {
              font-family: inherit;
          }
        
        }

        &.item-has-focus {
          --ion-item-border-color: var(--primary-color);
        }

        &.item-valid {
          --ion-item-border-color: var(--primary-color);
          --inner-border-width: 0px 0px 2px 0px;

          ion-icon {
            display: inline;
          }
        }
      }

      .img-avatar {
        width: 70%;
        height: 70%;
        margin-left: 60%;
      }

      .avatar {
        margin-left: -2em;
      }

      .continue{ 
        --background: var(--primary-color-celeste);
        font-size: 14px;
        font-weight: 700;
        color: #FFF;
        border-radius: 50%;
        margin-top: 30px;
      }

      .login {
        color: #9c9c9c;
        font-size: 90%;
        text-decoration:none;
      }

      ion-anchor {
        --color: var(--primary-color);
        text-align: center;
        width: 100%;
        display: inherit;
        padding-top: 10px;
      }

      div.hint {
        padding: 12px 0px 0px 16px;
        font-size: 0.8em;
        color: var(--primary-color);
      }
    }
}

ion-modal.modal-failed-to-register{

  .modal-wrapper {
    background: transparent;

    div.ion-page {
      left: 10%;
      right: 10%;
      top: 34%;
      overflow: visible;
      height: 88%;
      .content {
        min-height: 200px;
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
        .help {
          text-align: center;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          color:var(--normal-text);
          text-decoration:none;
          margin-top: 5px;
          .a-help{
            margin-left: 40%;
            color:var(--primary-color-celeste);
            text-decoration: none;
          }
        }
         > .header {
          display: flex;
          justify-content: flex-end;
          padding: 4px 4px 4px 0px;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 15px;
          margin-bottom: -24px;
        }
        > .body {
          display: flex;
          flex-direction: row;
          padding: 0 18px;
          > div {
            &:nth-child(1) {
              height: 8em;
              width: 9em;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              background-size: auto 72%;
              background-position: center;
              background-repeat: no-repeat;
              height: 6em;
              width: 100%;
            }
          }
        }
        >.container-info-productModal{
          text-align: left;
          padding: 4px 12px 0px 25px;
          font-size: 13px;
          .container-title-modal{
            color: var(--color-blue);
            margin-bottom: 15px;
            margin-top: 36px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
          }
          .container-subTitle-modal{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--text-title-gray-9);
            text-transform: uppercase;
            margin-bottom: 5px;
          }
          .container-information-modal{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--text-title-gray-6);
            margin-bottom: 20px;
          }
        }
        .container-input{
          margin-bottom: 13px;
          ion-item{
            margin: 16px;
            margin-bottom: -14px;
            ion-avatar{
              margin-left: -14px;
            }

            .numerical-code{
              --color: var(--text-label-color)
            }
            &.item-valid {
              --ion-item-border-color: var(--primary-color);
              --inner-border-width: 0px 0px 2px 0px;
      
              ion-icon {
                display: inline;
              }
            }
              ion-icon{
                margin-right: 16px;
                display: none;
                color: var(--primary-color);
                position: absolute;
                bottom: 8px;
                right: 0px;
              }
            
          }
        }
        .container-button{
          ion-button{
            --border-radius: 50px;
            margin: 50px 15px 43px 15px;
            font-size: 14px;
            font-weight: 700;
            color: #FFF;
            --background:var(--primary-color-celeste);
            border-radius: 50%;
            ion-spinner{
              position: absolute;
              left: 232px;
            }
          }
        }
      }
    }
  }
}

ion-modal.modal-failed-user-exists{
  .modal-wrapper {
    background: transparent;
    div.ion-page {
      left: 5%;
      right: 5%;
      top: 35%;
      overflow: visible;
      height: 88%;
      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
         > .header {
          display: flex;
          justify-content: flex-end;
          padding: 4px 4px 4px 0px;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 15px;
          margin-bottom: -24px;
        }
        .container-info-productModal{
          text-align: center;
          padding: 4px 12px 0px 12px;
          font-size: 13px;
          .container-title-modal{
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin-bottom: 15px;
            margin-top: 36px;
          }
        }
        .container-text{
          div{
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            padding: 16px;
          }
        }
        .container-button{
          margin-top: 10px;
          margin-bottom: 10px;
          float: right;
          padding: 14px;
          div{
            font-size: 14px;
            font-weight: 400;
            color: var(--primary-color-celeste);
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) { 
  .register-page  {
    .form-register {
    margin: 0 auto;
    max-width: 800px;
    }
  }
}




