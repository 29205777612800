.help {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color:var(--normal-text);
  margin-top: 5px;
  b{
    a{
      text-decoration: underline;
      color:var(--normal-text);
    }
  }
}