.deliveries-page {
  --background: var(--default-background-color);

  ion-content {
    --background: var(--default-background-color);
    .wrapper-orders {
      margin: 1rem 0;
      .list-orders {
        .title {
          background: #f1f1f1;
          color: #4a4a4a;
          padding: 0.25rem 1rem;
          font-weight: bold;
        }
        .list {
          margin: 0.5rem;
          .order {
            margin: .5rem 1rem;
            background: white;
            min-height: 3rem;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            display: flex;
            .provider {
              display: inline-block;
              position: relative;
              line-height: 2rem;
              .date {
                color: gray;
                font-size: 0.75rem;
                line-height: .75rem;
              }
            }
            .dispatch-hour {
              color: #ec4733;
              font-size: 0.75rem;
              line-height: 2rem;
              padding-left: 1rem;
            }
            .button-wrapper {
              vertical-align: middle;
              position: relative;
              top: .5rem;
              flex: 1;

              button {
                color: #4a90e2;
                background: white;
                border: 1px solid #4a90e2;
                border-radius: 1rem;
                padding: 0.5rem 1rem;
                font-size: .75rem;
                &:active {
                  color: white;
                  background: #4a90e2;
                }
              }
            }
          }
        }
      }
    }
    .without-products {
      text-align: center;
      ion-img {
        max-width: 70%;
        margin: 1rem auto;
      }
      .message {
        font-weight: bold;
        color: #142242;
        margin: 1rem 0;
        font-size: 1.25rem;
      }
      ion-button {
        width: 70%;
      }
    }
  }
}
