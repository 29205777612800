
.slides-container{
  position: relative;
}

.slider-arrow-action {
  position: absolute;
  top: 100px;
  z-index: 3;
  font-size: 25px;
}

.arrow-icon-right {
  right: 20px;
}

.arrow-icon-left {
  left: 20px;
}

