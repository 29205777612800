ion-modal{
  .terms-conditions-coupons{
    background: var(--background-color-blue);
    .header{
      margin: 40px 15px 20px 20px;
      display: flex;
      justify-content: flex-end;
      ion-icon{
        font-size: 30px;
        color: var(--color-blue)
      }
    }
    .container-close{
      display: flex;
      justify-content: flex-end;
      margin: 20px;
      font-size: 22px;
      margin-top: 29px;
    }
    pre {
      font-size: inherit;
      color: inherit;
      border: initial;
      padding: initial;
      font-family: inherit;
    }
    .terms-header-wrapper {
      background-color: var(--background-color-blue);
    }
    .terms-page {
      overflow: scroll;
    }
    .terms-wrapper {
      background-color: var(--background-color-blue);
      padding: 0px 35px 20px 35px;
    }
    .title {
      font-weight: 700;
      text-align: center; 
      color: var(--color-blue);
      padding-top: 10px;
    }
    .title-finish {
      font-size: 18px; 
      font-weight: 700;
      text-align: center; 
      color: var(--color-blue);
      padding-top: 20px;
      margin: 0 auto;
      max-width: 250px;
    }
    .title2{
      font-size: 19px; 
      font-weight: 500;
      text-align: center; 
      color: #444444;
      margin-top: 43px;
    }
    .paragraph{
      line-height: 20px;
      font-size: 14px; 
      font-weight: 400;
      text-align: center; 
      color: var(--text-title-gray-3);
      margin-top: 26px;
    }
    .general-text{
      font-size: 14px; 
      font-weight: 400;
      color: #666;
      text-align: justify;
      margin-top: 19px;
      line-height: 17px;
      color: var(--text-title-gray-3);
      > span{
        font-size: 14px; 
        font-weight: 700;
        color: #1a237e
      }
      div{
        margin-top: 14px;
      }
    }
    .points{
      font-size: 18px; 
      font-weight: 500;
      color: #00b0ff;
      margin: 27px 27px 27px 0px;
      margin-top: 23px;
    }
  }
}