.container-card-controller{
  max-width: 1000px;
  margin: 0 auto;
  padding: 17px;
  padding-top: 7px;
  span{
    font-size: 16px;
    font-weight: 700;
    color: var(--color-blue);
    text-transform: uppercase;
  }
  .btn-cart-controller{
    .btn-primary{
      width: 100%;
    }
  }
}