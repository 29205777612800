.plp-page {
  div.product-list-1 {
    margin-top: 4%;
    > .product-card {
      width: 29%;
      float: left;
      margin-left: 3%;
      margin-bottom: 4%;
      background: white;
    }
  }

}

.container-filter{
  --background:var(--primary-background-color);
  --color:var(--emphasis-color);
  ion-select{
    --placeholder-opacity:var(--emphasis-color);
    &:part(.select-icon){
      opacity:1!important;
    }
  }
}

.no-product{
  margin: 10px;
  margin-top: 23px;
}