.orders-page {
  ion-content.container-all-orders {
    --background: var(--background-color-blue);
    .container-orders-title{
      margin-bottom: 20px;
      .title-payments-methods{
        margin: 0;
        display: inline-block;
        width: 88%;
        padding-top: 30px;
        padding-left: 20px;
        color: var(--color-blue);
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .container-white-screen{
      height: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .wrapper-orders {
      margin: 1rem 0;
      .list-orders {
        animation: fadeInUp; 
        animation-duration: 1.6s;
        margin-bottom: 30px;
        .title {
          padding: 0.05rem 1.2rem;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 17px;
          text-align: initial;
          color:var(--color-blue);
        }
        .list {
          width: 100%;
          margin-top: 10px;
          margin-bottom: 0px;
          .provider-delivery {
            text-align: center;
            font-size: 0.8em;
            color: var(--secondary-color);
            padding: 0.5em;
          }
          .order {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05) !important;
            margin: 0.8rem 1rem;
            background: white;
            min-height: 4.375em;
            padding: 0.5rem 1rem 0.4rem 1rem;
            vertical-align: middle;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: none !important;
            &.Rechazado {
              background: #efefef;
            }
            .img-order-wrapper {
              width: 72px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              margin-right: 10px;
              margin-top: 0px;
            }
            .img-order {
              width: 42px;
            }
            .provider {
              margin-left: 6px;
              width: 100%;
              .date {
                color:  #999;
                font-size: 12px;
                font-weight: 400;
                color: #999;
                text-transform: uppercase;
                margin-bottom: 6px;
                .deliveryDays {
                  font-size: 12px;
                  float: right;
                }
              }
              .name-provider{
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                line-height: 15px;
                color: var(--text-title-gray-3);
                margin-bottom: 2px;
                .rate-order{
                  display: flex;
                  justify-content: flex-end;
                  color: var(--color-blue);
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 14px;
                }
              }
              .container-state{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .price-provider{
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 17px;
                  color: var(--color-blue);
                  width: 40%;
                  display: inline-block;
                }
                .state{
                  display: inline-block;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 15px;
                  color: var(--text-title-gray-3);
                  .cicle{
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    margin-right: 6px;
                    position: relative;
                    top: 1px;
                  }
                }
              }
            }
            .button-wrapper {
              vertical-align: middle;
              position: relative;
              top: 0.5rem;
              button {
                color: #4a90e2;
                background: white;
                border: 1px solid #4a90e2;
                border-radius: 1rem;
                padding: 0.5rem 1rem;
                font-size: 0.75rem;
                &:active {
                  color: white;
                  background: #4a90e2;
                }
              }
            }
          }
          .is-deliverable{
            border:1px solid #1E3799 !important;
          }
          .is-home-deliverable-done{
            border: 1px solid #ec4733 !important
          }
      
        }
        .see-more-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .see-more-img {
          width: 12px;
          margin-left: 10px;
        }
      }
      .orders-empty-wrapper {
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .without-products {
      text-align: center;
      ion-img {
        max-width: 70%;
        margin: 1rem auto;
      }
      .message-no-product {
        font-weight: bold;
        color: #142242;
        margin: 1rem 0;
        font-size: 1.25rem;
      }
      ion-button {
        width: 70%;
      }
    }
  }
}

