$border-radius: 16px;
$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);

// Generar clases para diferentes alturas
@for $i from 1 through 9 {
  $height: $i * 10;
  .custom-bottom-sheet-#{$height} {
    --height: #{$height}%;
  }
}

.custom-bottom-sheet {
  --border-radius: #{$border-radius} #{$border-radius} 0 0;
  --box-shadow: #{$box-shadow};

  &::part(content) {
    position: absolute;
    bottom: 0;
  }

  .bottom-sheet-content {
    padding: 16px;
  }

  ion-header {
    ion-toolbar {
      --background: #f4f5f8;
      --color: #000;

      ion-title {
        font-size: 18px;
        font-weight: 600;
      }

      ion-button {
        --color: #3880ff;
      }
    }
  }
}

.container-provider {
  .provider {
    margin-bottom: 16px;

    .title-provider {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .container-day {
      display: flex;
      justify-content: space-between;

      .day-provider {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &.inactive {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.selected {
          background-color: #1E3799;
          color: white;
          border-color: #1E3799;
        }

        &:hover:not(.inactive) {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.time-Shipping {
  margin-bottom: 16px;

  .time-title {
    font-weight: 600;
  }

  .time {
    color: #666;
  }
}

.container-button {
  margin-top: 24px;
  
  .btn-primary {
    width: 100%;
  }
}

.info-footer {
  margin-top: 16px;
  font-size: 14px;
  color: #666;

  strong {
    color: #000;
  }
}