.payment-method-card {
  padding: 0 1rem;
  margin: 0.5rem auto 0 auto;
  width: 100%;
  max-width: 45rem;

  & > h4 {
    color: var(--color-blue);
    font-size: 1.1rem;
    font-weight: 700;
  }

  &__providers {
    width: 100%;
  }

  &__button {
    width: 100%;
    max-width: 20rem;
    border-radius: 1.8rem;
    text-align: center;
    margin: 1rem auto;
    padding: 0.6rem 0.8rem;

    &--active {
      background: var(--color-blue);
    }

    &--disabled {
      background: #394c94;
    }

    &__link {
      color: #fff;
      font-weight: 700;
      font-size: 1.1rem;

      &--active {
        cursor: pointer;
      }

      &--disabled {
        cursor: not-allowed;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
