.home-brands-container {
  padding-top: 0 !important;
  .swiper {
    height: 5em;

    .swiper-wrapper {
      height: 5em;
    }

    .swiper-slide {
      width: fit-content !important;
      &.slide-home-brand {
        width: 80px;
      }
    }
    
    
  }
}