ion-app{
  .container-close{
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    font-size: 22px;
		margin-top: 29px;
	}
	ion-content{
		white-space: pre-line;
    	pre {
			font-size: inherit;
			color: inherit;
			border: initial;
			padding: initial;
			font-family: inherit;
		}
		.terms-header-wrapper {
			background-color: var(--background-color-blue);
		}
		.terms-page {
			overflow: scroll;
		}
		.terms-wrapper {
			background-color: var(--background-color-blue);
			padding: 0px 35px 20px 35px;
		}
		.title {
			font-weight: 700;
			text-align: center; 
			color: var(--color-blue);
			line-height: 30px;
		}
		.title-finish {
			font-size: 18px; 
			font-weight: 700;
			text-align: center; 
			color: var(--color-blue);
			padding-top: 20px;
			margin: 0 auto;
			max-width: 250px;
		}
		.title2{
			font-size: 19px; 
			font-weight: 500;
			text-align: center; 
			color: #444444;
			margin-top: 43px;
		}
		.paragraph{
			line-height: 20px;
			font-size: 14px; 
			font-weight: 400;
			text-align: center; 
			color: var(--text-title-gray-3);
			margin-top: 26px;
		}
		.general-text{
			font-size: 14px; 
			font-weight: 400;
			color: #666;
			text-align: justify;
			margin-top: 19px;
			line-height: 17px;
			color: var(--text-title-gray-3);
			> span{
				font-size: 14px; 
				font-weight: 700;
				color: #1a237e
			}
			div{
				margin-top: 14px;
			}
		}
		.points{
			font-size: 18px; 
			font-weight: 500;
			color: #00b0ff;
			margin: 27px 27px 27px 0px;
			margin-top: 23px;
		}
		ion-button.accep{
			--border-radius: 50px;
			font-size: 16px;
			font-weight: 700;
			color: #FFF;
			margin: 10px 27px 15px 27px;
			--background: var(--primary-color-celeste)
		}
		.checkbox-wrapper {
			margin: 10px 0px 20px 0px;
			display: flex;
			align-content: center;
			justify-content: center;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			align-items: center;
			color: var(--text-title-gray-3);
		}
		.terms-button-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
		}
		.finish-button-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			margin-top: 20px;
			width: 100vw;
		}
		.terms-checkbox {
			margin-right: 8px;
		}
		.checkbox{
			width: 20px;
			height: 20px;
			border-radius: 4px;
			border: 2px solid #1E3799;
			margin: 10px;
		}
		.checked {
			background: #1E3799;
		}
		.accept-terms-container {
			position: fixed;
			bottom: 0px;
			left: 0;
			right: 0;
			background-color: var(--background-color-blue);
			margin-top: 10px;
			text-align: justify;
		}
		.finish-container {
			background-color: var(--background-color-blue);
			position: fixed;
			height: 100vh;
			width: 100vw;
			display: flex;
			justify-content: center;
			align-items: center;
			.finish-inner-wrapper {
				height: fit-content;
				width: fit-content;
			}
			.finish-content {
				width: 230px;
				margin: auto;
			}
		}
	}
}