.container-guru-helper{
  margin-top: 50px;
  margin-bottom: 20px;
  .label-helper-guru{
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
    color: var(--color-blue);
    margin-left: 10%;
  }
  .helper-guru{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .container-img-helper-guru{
      width: 74px;
      height: 72px;
      margin-right: 6%;
    }
    .container-text-helper-guru{
      position: relative;
      background: #407BFF;
      color: white;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      padding: 6px 12px 6px 14px;
      margin-top: 15px;
      width: 60%;
      min-height: 54px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px !important;
      line-height: 15px;
      display: flex;
      align-items: center;
    }    
    .container-text-helper-guru:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 4px;
      top: 0;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: #407BFF;
      border-bottom: 0;
      border-left: 0;
      margin-left: -20px;
      transform: rotate(90deg)
    }
  }
}