.bottom-sheet__container {
  bottom: 0px !important;
  height: 87%;
}
.bottom-sheet{
  padding: 15px;
  width: 100%;
  background-color: #EFF5F9;
  height: 100%;
  overflow: scroll;
  .btn-close{
    height: 10px;
    background: rgba(31, 60, 124, 0.3);
    border-radius: 100px;
    width: 25%;
    height: 10px;
    margin: 0 auto;
  }
  .title-text{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 17px;
    color: var(--color-blue);
    margin-top: 25px;
  }
  .sheet-text{
    margin-top: 20px;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 32px;
    .underlined{
      text-decoration: underline
    }
  }
  .time-Shipping{
    display: flex;
    justify-content: space-between;
    height: 5%;
    // margin-top: 50px;
    .time-title{
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: var(--color-blue);
    }
    .time{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }
  }
  .container-provider{
    margin-top: 15px;
    .provider{
      .title-provider{
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: var(--color-blue);
      }
      .container-day{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
        .day-provider{
          background-color: #ffff;
          margin: 2%;
          width: 60px;
          height: 42px;
          box-shadow: 0px 3px 10px rgba(30, 55, 153, 0.05);
          border-radius: 5px;
          text-align: center;
        }
      }
    }
  }
  .container-button{
    text-align: center;
    margin: 10%;
  }
  .info-footer{
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    padding: 0px 33px
  }
}