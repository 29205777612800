.faq1-page {
  ion-content {
    --background: var(--background-color-blue);
    .tittle-faq {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: var(--text-title-gray-3);
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .search-bar-faq {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      align-items: top;
      justify-content: center;
      margin-bottom: 20px;
      display: none;
      > div {
        width: 92%;
        position: relative;

        input {
          font-weight: 300;
          width: 100%;
          border-radius: 50px;
          border: 1px solid var(--primary-background-color);
          padding: 8px 38px 8px 20px;

          &::placeholder {
            opacity: 0.8;
          }

          &:focus {
            outline: none;
          }
        }

        > ion-icon {
          position: absolute;
          right: 12px;
          color: var(--text-label-color);
          top: 8px;
          font-size: 20px;
        }
      }
    }
    .filter-btn {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      button {
        padding: 0px 10px !important;
      }
    }
    .questions {
      padding: 12px 24px;
      .container-questions {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-title-gray-6);
        --background: var(--background-color-blue);
      }
      .container-answers {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-title-gray-3);
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 23px;
      }
      .item-contact {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-title-gray-6);
        ion-icon {
          &.mail {
            color: var(--primary-color-celeste);
          }
          &.whatsapp {
            color: var(--primary-color-celeste);
          }
          &.phone {
            color: var(--primary-color-celeste);
          }
        }
        a {
          text-decoration: none;
          color: var(--text-title-gray-6);;
          cursor: pointer;
        }
      }
      ion-item {
        ion-label {
          color: #24272c;
          white-space: normal;
        }
      }
      .container-contant {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-title-gray-6);
        --background: var(--background-color-blue);
      }
    }

    .question-card {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      width: 100%;
      padding: 12px 15px;
      margin-bottom: 10px;

      .question-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .question-card-body {
        margin-top: 17px;
      }
    }
  }
}
