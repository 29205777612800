ion-modal#payment-method-modal {
  --min-width: 320px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--background-color-blue);
}

ion-modal#payment-method-modal .wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    max-height: 31rem;
    overflow: auto;
    padding: 1rem;

    @media only screen and (max-width: 992px) {
      max-height: 45rem;
    }

    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > ion-icon {
        font-size: 1.8rem;
        color: #1e3799;
      }

      & > span {
        color: var(--color-blue);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    & > .payment-method-modal-content {
      display: flex;
      gap: 0.8rem;
      flex-direction: column;
      overflow: auto;
      margin-top: 1rem;

      & > .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span {
          color: var(--color-blue);
          font-weight: 600;
          font-size: 1.1rem;
        }
      }

      & > .metodo-pago {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span {
          font-size: 1rem;
          font-weight: 600;
          color: var(--color-blue);
        }

        & > input {
          content: '';
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }

  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      width: 100%;
      max-width: 20rem;
      border-radius: 1.8rem;
      text-align: center;
      margin: 1rem auto;
      padding: 0.6rem 0.8rem;
      background: var(--color-blue);
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
