ion-title {
  color: var(--color-blue);
  font-size: 1.3rem;
}

.coupons-modal__content {
  background-color: var(--background-color-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5rem;
  text-align: center;

  & > p {
    margin-top: 1rem;
    margin-bottom: 0rem;
    max-width: 60%;

    & > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
