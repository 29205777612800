@import '../scss/base';

.form-input{
  &-default{
    @include form-properties();
    padding-right: 42px;
  }
  &-valid{
    @include form-properties($color-blue);
    padding-right: 42px;
  }
  &-invalid{
    padding-right: 42px;
    @include form-properties($error-color, 12px 48px 12px 12px, 10px 0px);
  }
}

.form-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}