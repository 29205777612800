.hashtags-page {
  .banner-outer-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    .banner-virtual-background {
      background-color: var(--background-color-blue);
      position: absolute;
      top: 0;
      bottom: 0;
      min-width: 100%;
      height: 70%;
      min-height: 70%;
    }
  }
  .hashtags-banner-row {
    padding: 0px 14px;
  }
}