
.container-form-user{
  display: flex;
  justify-content: center;
  max-width: 1000px;
  .input-validate{
    margin: 0px 10%;
    margin-top: 24px;
    max-width: 800px;
    .subtitle-validate-user{
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
      margin-bottom: 42px;
      .message-automatic{
        display: inline-block;
        margin-top: 20px;
        font-style: italic;
      }
      .message{
        display: inline-block;
        margin-left: 9px;
        font-style: italic;
      }
    }
  }

  .container-help{
    text-align: center;
    padding: 26px;     
    .help {
      font-size: 12px;
      font-weight: 700;
      color: var(--primary-color-celeste);
      text-decoration:none;
      margin-top: 5px;
      text-align: center;
    }
  }
}

.container-textarea-contact{
  margin: 16px;
  width: 300px;
  .container-textarea{
    .message{
      color: var(--form-gray);
      font-size: 12px;
      font-weight: 600;	
      margin-bottom: 10px;
      margin-left: 20px;
    }
    .in-focus{
      color: var(--color-blue)
    }
    ion-textarea.textarea-contact{
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-3);
      border: 1px solid var(--text-gray-color);
      height: 6em;
      border-radius: 20px;
      padding: 10px;
      width: 300px;
      background: #ffffff;
    }
    ion-textarea.in-focus{
      border: 1px solid var(--color-blue);
    }
  }
}
.input-validate-message-automatic{
  display: flex;
  max-width: 800px;
  .subtitle-validate-user{
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-title-gray-3);
    margin-bottom: 20px;
    margin-left: 6px;
    .message-automatic{
      display: inline-block;
      font-style: italic;
    }
    .message{
      display: inline-block;
      margin-left: 9px;
      font-style: italic;
    }
  }
}

.footer{
  margin-top: 20px;
}

ion-modal.modal-successful-shipment{
	--backdrop-opacity:0.2!important;
	.modal-wrapper {
		background: none;
		div {
			left: 8%;
			right: 5%;
			top: 37%;
			width: 83%;
			.content-successful-shipment{
				background: #fff;
				border-radius: 8px;
				height: 11.8em;
				padding: 29px;
				width: 100%;
				.header{
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
				.container-title-successful-shipment{
					color: var(--color-blue);
					display: flex;
					justify-content: flex-start;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
				}
				.subTitle-successful-shipment{
					color: var(--text-title-gray-6);
					margin-top: 10px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
				}
				.footer-successful-shipment{
					font-size: 14px;
					font-weight: 700;
					color: var(--primary-color-celeste);
					text-transform: uppercase;
					margin-top: 10px;
					float: right;
					display: flex;
    				justify-content: flex-end;
				}
			}
		}
	}
}