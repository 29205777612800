.recover-access{
  .content-recover{
    --background: var(--background-color-blue);
  }
  .input-validate{
    margin: 0px 10%;
    margin-top: 24px;
    max-width: 800px;
    .subtitle-validate-user{
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
      margin-bottom: 42px;
    }
  }
}