
.container-add-office{
  height: 100%;
  width: 100%;
  --background: var(--background-color-blue);
}
.container-address-items{
  display: flex;
  justify-content: center;
}
.container-btn-add-direction{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}