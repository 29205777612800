ion-toolbar.primaryHeader {
  --background: var(--color-blue);
  --color: #fff;
  --border-width: 0px;
  &.staging {
    --background: rgba(255, 0, 0, 0.1);
    --color: #fff;
    --border-width: 0px;
  }
  ion-badge {
    background: #ec4733;
    border: 1px solid white;
    border-radius: 5rem;
    font-size: 0.5rem;
    width: 1rem;
    height: 1rem;
    padding: 0;
    line-height: 1rem;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  ion-icon {
    color: #fff !important;
  }
  ion-menu-button {
    font-size: 1.5rem;
  }
  ion-title{
    padding-top: 15px;
  }

  .no-offices-title {
    color: #fff !important;
    margin: 0px;
    padding: 15px 0 0 30px;

    display: inline-block;
    width: calc(85%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .no-margin {
    margin: 0px;
  }
  .notif-button {
    margin-right: 12px;
  }
  .category-container {
    text-align: center !important;
  }
  .category-button-icon {
    display: block;
  }
  .category-button-text {
    display: block;
    color: white;
    margin: 0px;
  }
}

.office-selection {
  width: fit-content;
  max-width: 160px;
  height: 39px;
  margin: 0 30px 0 30px;
  background-color: var(--color-blue);
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.85em;
  line-height: 1em;

  > ion-item {
    position: absolute;
    width: 100%;
    z-index: 100;
    opacity: 0;
    left: 0;
  }
  &__text-wrapper {
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

  }
  &__info-text {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__arrow-icon {
    position: absolute;
    right: -20px;
    bottom: 30%;
  }
}

.select-action-sheet.custom-action-sheet .action-sheet-title {
  --color: var(--text-title-gray-3);
  font-weight: 700 !important;
  font-size: 16px !important;
}

ion-toolbar.secondaryHeader {
  --background: var(--background-color-blue);
  --color: var(--text-title-gray-9);
  --border-width: 0px;
  &.staging {
    --background: rgba(255, 0, 0, 0.1);
    --color: #fff;
    --border-width: 0px;
  }
  ion-badge {
    background: #ec4733;
    border: 1px solid white;
    border-radius: 5rem;
    font-size: 0.5rem;
    width: 1rem;
    height: 1rem;
    padding: 0;
    line-height: 1rem;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  ion-icon {
    color:var(--text-title-gray-9) !important;;
  }
  ion-menu-button {
    font-size: 1.5rem;
  }
  .title-aligned-left {
    text-align: inherit;
    padding: 10px 0px 4px 50px;
    margin-top: 0px;
  }
  .title-one-vendor {
    text-align: inherit;
    padding: 3px 0px 4px 6px;
  }
  .title-center-aligned{
    text-align: center;
    padding: 0px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color:var(--color-blue)
  }
  .breadcrumbs-text {
    text-align: inherit;
    padding: 0px;
    flex-direction: row;
    display: flex;
    align-items: center;
    font-size: 14px;
    .breadcrumb-element {
      flex-direction: inherit;
      display: inherit;
      .breadcrumb {
        display: inline-block;
        white-space: nowrap;
        color:var(--ion-toolbar-color, var(--color))
      }
      .breadcrumb-tag {
        flex-direction: inherit;
        display: inherit;
        color: #666;
      }
    } 
  }
  .close-toolbar-icon {
    width: 22px;
    height: 22px;
  }
  .h1-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: var(--color-blue);
    padding-top: 6px;
  }
  
  .h1-title-notifications {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    color: var(--color-blue);
    padding-top: 6px;
    margin-left: 15px;
  }
}

ion-header ion-toolbar:first-of-type {
  &.size-ios {
    padding: 40px 10px 20px 10px;
    height: 98px;
  }
  &.size-default {
    padding: 20px 10px 20px 10px;
    height: 79px;
  }
}

ion-toolbar {
  &.size-ios {
    padding: 40px 10px 20px 10px;
    height: 98px;
  }
  &.size-default {
    padding: 20px 10px 20px 10px;
    height: 79px;
  }
  &.no-box-shadow {
    box-shadow: none;
  }
  &.box-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 35px 0 rgba(0, 0, 0, 0.2);
  }
  .notif-badge { 
    border-radius: 50% !important;
    width: 15px !important;
    height: 15px !important;
  }
}

ion-modal.alert-confirmation {
  .modal-wrapper {
    background: transparent;
    box-shadow: none;
    div.ion-page {
      left: 8%;
      right: 5%;
      top: 24%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        border-radius: 8px;
        height: 24em;

        >.header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }

        >.body-guru-pro {
          display: flex;
          flex-direction: row;
          padding: 0 18px;

          >div {
            &:nth-child(1) {
              height: 8em;
              width: 9em;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              background-size: auto 72%;
              background-position: center;
              background-repeat: no-repeat;
              height: 6em;
              width: 100%;
            }
          }
        }

        >.container-info-productModal {
          text-align: center;
          padding: 4px 12px 24px 12px;
          font-size: 13px;

          .container-title-modal {
            font-size: 14px;
            font-weight: 600;
            color: #666;
            text-transform: uppercase;
            margin-bottom: 33px;
          }
          .img-container {
            text-align: -webkit-center;
            margin-bottom: 20px;
            .img {
              height: 115px;
              width: 115px;
              background: no-repeat;
            }
          }

          .info-modal {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            text-align: center;
            margin: 11px;
            margin-bottom: -36px;
          }
        }

        >.body {
          padding: 0 18px;

          >.title {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            color: var(--text-title-gray-3);
          }

          >.info-terms {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            margin-top: 30px;
          }
        }

        >.footer {
          margin-top: 40px;
          padding: 0 18px;
          width: 100%;
          display: flex;
          justify-content: center;

          >.read-more {
            width: 73%;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: var(--text-title-gray-6);
            text-transform: uppercase;
          }

          >.to-accept {
            width: 25%;
            display: inline-block;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: var(--primary-color-celeste);
            text-transform: uppercase;
          }

          >.confirm {
            font-size: 14px;
            font-weight: 700;
            color: var(--primary-color-celeste);
            text-transform: uppercase;
            float: right;
          }
        }
      }
    }
  }
}

.container-search{
  display: flex;
  ion-input{
    --placeholder-color: #999;
    font-size: 16pt; 
    font-style: italic;
  }
  ion-button{
    text-transform: uppercase;
    align-self: center;
    --background-focused:none;
    --background-activated:none;
    --border-radius: none;
    font-size: 14px;
    font-weight: 700;
    --color: #00B0FF ;
    --background:none;
    --box-shadow: none;
    --border-color: none;
  } 
}