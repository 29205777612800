.container-guru-white-screen {
  height: 100%;
  display: flex;
  align-items: center;
  .guru-white-screen {
    width: 100%;
    .container-img-guru-white-screen {
      text-align: center;
      img {
        width: 185px;
        height: 180px;
      }
    }
    .container-text-guru-white-screen {
      margin-left: 20%;
      position: relative;
      background: #407bff;
      color: white;
      border-radius: 15px;
      padding: 8px 12px 8px 14px;
      margin-top: 15px;
      width: 60%;
      min-height: 54px;
      min-width: 191px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
    .container-text-guru-white-screen:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 4px;
      top: 90%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: #407bff;
      border-bottom: 0;
      border-left: 0;
      margin-left: 52%;
      transform: rotate(82deg);
    }
  }
}
