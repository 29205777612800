.suggested-product-card {
  width: 100%;
  height: 19rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(30, 55, 153, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & > .image-promo-cast {
    position: absolute;
    top: 0.9rem;
    left: 9.5rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  & > .image-icon-ff {
    position: absolute;
    top: 0.4rem;
    left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  & > .image-desc {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;

    &--with-offer-price {
      top: 3rem;
      left: 9.5rem;
    }

    &--without-offer-price {
      top: 0.9rem;
      left: 9.5rem;
    }
  }

  & > .content-image {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
    cursor: pointer;

    & > img {
      height: 7rem;

      @media only screen and (max-width: 992px) {
        max-height: 5rem;
      }
    }
  }

  & > .content-info {
    padding: 0.5rem 0.7rem 0rem 0.7rem;
    text-align: start;

    & > h6 {
      text-decoration: line-through;
    }

    & > .content-info-title {
      color: var(--color-blue);
      font-size: 1.2rem;
      font-weight: 600;

      &--oferta {
        color: #f7432b;
      }

      &--agotado {
        color: #999999;
      }
    }

    & > .span-info {
      display: block;
      font-size: 0.75rem;
      color: #333333;

      &--margin {
        margin-top: -0.5rem;
      }

      &--agotado {
        color: #999999;
      }
    }
  }

  & > .buttons {
    position: relative;
    height: fit-content;
    width: fit-content !important;
    margin: 0 auto;
    padding-bottom: 1rem;

    ion-col {
      text-align: center;
    }

    .button {
      margin: 0px;
      padding: 0px;
      width: fit-content;
      border: 2px solid red;
      height: fit-content;
      border-color: #d9d8db;
      background-color: var(--color-blue);
      font-size: 12px;
      opacity: 1;

      &__active {
        background-color: var(--color-blue);
      }

      &__disabled {
        background-color: #999;
      }

      .icon {
        margin: 0;
        width: 25px;
        height: 25px;
        color: #ffff;
      }
    }

    .btn-left {
      border-radius: 100%;
    }

    .btn-right {
      border-radius: 100%;
    }

    .value-cell {
      color: var(--color-blue);
      display: inline-block;
      width: 1em;
      vertical-align: middle;
      font-size: 16px;
      min-width: 3em;
      font-weight: bold;
      text-align: center;
      --padding-top: 3px;
      --padding-end: 0;
      --padding-bottom: 0px;
      --padding-start: 0;
    }
    .inputs-background {
      position: absolute;
      width: 100%;
      height: 60%;
      margin: 0px auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 15px;
      background-color: var(--white);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }

  & > .title-agotado {
    font-weight: 700;
    color: #999999;
    font-size: 1.1rem;
    text-align: center;
    padding-bottom: 0.6rem;
  }
}
