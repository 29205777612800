@use "sass:math";
.container-photo-user{
  .container-image-photo-user{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .img-camera, .fade-icon{
      width: 100%;
      height: 100%;
      position: relative;
    }
    .img-camera{
      z-index: 1;
      margin-bottom: 0px;
    }
    .fade-icon{
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      color: white;
      background-color: rgba($color: #000000, $alpha: 0.7);
      display: none;
      &.editable{
        display: block;
      }
      ion-icon{
        $size-icon: 30px;
        $margin-icon: math.div($size-icon, 2) * -1;
        top:50%;
        left: 50%;
        margin-top: $margin-icon;
        margin-left: $margin-icon;
        position: absolute;
        font-size: $size-icon;
      }
    }
    
    .image{
      height: 120px;
      margin-right: 11px;
      margin-bottom: 19px;
      position: relative;
      display: inline-block;
      text-align: center;
      .icon-view{
        position: absolute;
        top: 50px;
        text-align: center;
        width: 100%;
        z-index: 100;
      }
    }
  }
  .not-image{
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
  }

}

.text-photosRutAhead{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
}

ion-modal.modal-lightbox{
  .modal-wrapper  {
    background: transparent;
    div.ion-page {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
    .container-lightbox{
      background: #666666;
      position: relative;
      min-height: 100%;
      .conianter-img-lightbox{
        margin-top: 28%;
        height: 62%;
        img{
          max-height: 357px;
          width: 100%;
        }
      }
      .container-btn{
        display: flex;
        justify-content: space-around;
        color:#FFFFFF;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
      .icon-footer{
        .container-icon{
          text-align: center;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {  
 ion-modal.modal-lightbox{
  .modal-wrapper  {
    .container-lightbox{
      .conianter-img-lightbox{
        margin-top: 10%;
        height: 62%;
        text-align: center;
        img{
          max-height: 266px;
          width: 100%;
          max-width: 266px;
          text-align: center;
        }
      }
    }
  }
 }
}