ion-modal#delete-modal {
  --width: 25%;
  --min-width: 320px;
  --height: 25%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#delete-modal h2 {
  color: var(--color-blue);
  font-weight: 600;
  font-size: 1.4rem;
}

ion-modal#delete-modal p {
  font-size: 1.1rem;
}

ion-modal#delete-modal .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;
  height: 100%;

  & > div {
    display: flex;
    gap: 1rem;

    & > button {
      width: 7rem;
      color: #fff;
      padding: 8px 10px;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 600;

      &:nth-child(1) {
        background: var(--primary-color-celeste);
      }

      &:nth-child(2) {
        background: var(--emphasis-background-color);
      }
    }
  }
}
