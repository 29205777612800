
.help-modal-container {
  .help-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(31, 60, 124, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; 
  }

  .help-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 11;
    
    .help-modal {
      pointer-events: all !important;
      height: fit-content;
      min-width: 300px;
      max-width: 85%;
      padding: 25px;
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
      background-color: #FFF;
      border-radius: 10px;
  
      .help-modal-title {
        margin: 0px;  
      }
      .help-option-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        cursor: pointer;
      }
      .help-option-title {
        color: var(--color-blue);
        padding-right: 20px;
      }
      .help-arrow-wrapper {
        width: 40px;
        display: flex;
        justify-content: right;
        align-items: center;
        padding-right: 6px;
      }
    }
  }
}

.opened-modal {
  visibility: visible;
}
.closed-modal {
  visibility: hidden;
}