.coupons-count-card {
  width: 90%;
  max-width: 500px;
  margin: 10px auto;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FA983A !important;
  border-radius: 5px;

  .coupons-count-text {
    margin: 0px;
    color: var(--white)  !important;
  }
  .coupons-link-text {
    margin: 0px;
    color:  var(--white) !important;
    text-decoration: underline;
  }
}