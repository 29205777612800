@import '../../assets/scss/styles.scss';
.container-input{
  margin: 10px 0px 0px 0px;
  position: relative;
  width: 100%;
  max-width: 300px;
  .error-message-container{
    position: absolute;
    top: 66px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3px 5px 3px 3px;
    z-index: 2;
    width: 90%;
    align-items: center;
    min-height: 25px;
    background: #FFEBE8;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    .icon-error{
      font-size: 16px;
      margin: 0 8px;
    }
    p{
      margin: 0;
    }
  }
  .container-label-icon{
    display: flex;
    margin-left: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    .icon-label{
      margin-right: 10px;
    }
    .default{
      color: var(--form-gray);
    }
    .invalid{
      color: var(--secondary-color);
    }
    .valid{
      color: var(--color-blue);
    }
  }
  .container-input-text{
    position: relative;
    .container-alert-icon{
      position: absolute;
      top: 17px;
      left: 86%;
      font-size: 20px;
    }
  }
  .informative-text-container {
    min-height: 25px;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3px 5px 3px 3px;
    z-index: 1;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 100px;
    font-size: 12px;
    font-weight: lighter;
    color: var(--color-blue);
    line-height: 16px;

    p {
      margin: 0;
    }
  }
  .eye-icon {
    color: var(--color-blue);
    width: 24px;
    height: 20.5px;
  }
}  
