@import '../../assets/scss/styles';

.orders-page-cupons {
  ion-avatar{
    border-radius: 0;
    --border-radius: 0;
  }
  .isCheck{
    color:var(--primary-background-color);
  }
  .title-coupon{
    margin: 0;
    display: inline-block;
    width: 88%;
    padding: 20px 0 20px 20px;
  }
  ion-content.body-content-coupons {
    --background:  var(--background-color-blue);
    ion-segment{
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      ion-segment-button{
        font-size: 10px;
        font-weight: 600;
        color: #666;
        --color-checked:var(--primary-background-color);
      }
    }
    .wrapper-orders {
      animation: fadeInUp; 
      animation-duration: 1.6s;
      .list-orders {
        .title {
          background: #f1f1f1;
          color: #4a4a4a;
          padding: 0.25rem 1rem;
          font-weight: bold;
        }
        .list {
          margin: 0.5rem;
          .provider-delivery {
            text-align: center;
            font-size: 0.8em;
            color: var(--secondary-color);
            padding: 0.5em;
          }
          .order {
            margin: 0.5rem 1rem;
            background: white;
            min-height: 3rem;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            display: flex;
            &.Rechazado {
              background: #efefef;
            }
            .provider {
              display: inline-block;
              position: relative;
              line-height: 2rem;
              flex: 1;
              .date {
                color: gray;
                font-size: 0.75rem;
                line-height: 0.75rem;
              }
              .state {
                font-size: 0.75rem;
                color: var(--primary-color);
                &.Rechazado {
                  color: var(--emphasis-background-color);
                }
              }
            }
          }
          .container-coupons{
            margin: 0.5rem 0.8rem;
            background: white;
            min-height: 3rem;
            vertical-align: middle;
            background: #FFFFFF;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);;
            border-radius: 10px;
            .coupon-vendors{
              background-color: var(--primary-color-celeste);
              border-radius: 0px 0px 10px 10px;
              text-align: center;
              color: #fff;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              text-decoration-line: underline;
              padding: 0.3rem;
            }
            .coupon {
              padding: 0.5rem 1rem;
              display: flex;
           
              .img_coupon {
                height: 3.5rem;
              }
              .provider {
                display: inline-block;
                position: relative;
                line-height: 2rem;
                flex: 1;
                .minimum_amount {
                  padding-left: 1rem;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  color:var(--normal-text)
                }
                .code {
                  padding-left: 1rem;
                  padding-top: 0.7rem;
                  padding-bottom: 0.3rem;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  color:var(--normal-text)
                }
                .expire {
                  padding-left: 1rem;
                  padding-bottom: 0.3rem;
                  color: gray;
                  font-size: 0.65rem;
                  line-height: 0.65rem;
                }
                .amount {
                  padding-left: 1rem;
                  padding-bottom: 0.3rem;
                  color: var(--color-blue);
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 16px;
                }
                .state {
                  font-size: 0.75rem;
                  color: var(--primary-color);
                  &.Rechazado {
                    color: var(--emphasis-background-color);
                  }
                }
              }
              .button-wrapper {
                vertical-align: middle;
                position: relative;
                top: 0.5rem;
                margin-top: 13px;
                button {
                  color: #4a90e2;
                  background: white;
                  border: 1px solid #4a90e2;
                  border-radius: 1rem;
                  padding: 0.5rem 1rem;
                  font-size: 0.75rem;
                  &:active {
                    color: white;
                    background: #4a90e2;
                  }
                }
              }
              .button-garbage{
                vertical-align: middle;
                position: relative;
                top: 1.3rem;
              }
            }
          }
        }
      }
    }
    .without-products {
      text-align: center;
      ion-img {
        max-width: 70%;
        margin: 1rem auto;
      }
      .message {
        font-weight: bold;
        color: #142242;
        margin: 1rem 0;
        font-size: 1.25rem;
      }
      ion-button {
        width: 70%;
      }
    }
    .message-top-creator {
      font-weight: bold;
      color: #142242;
      margin-top: 1em;
      font-size: 1.15rem;
      margin-left: 24px;
    }
    .container-new-coupon{
      margin : 0px 20px 20px 20px
    }
    .new-coupons{
      margin-top: 4px;
      .cotainer-coupons-referal{
        min-height: 3rem;
        padding: 0.5rem;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        vertical-align: middle;
        height: 80px;
        padding: 14px;
        .title-new-coupons{
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--color-blue);
          margin-bottom: -14px
        }
        div{
          width: 100%;
          height: 0px;
          .container-input{
            width: 67%;
            display: inline-block;
            margin-right: 15px;
            ion-input{
              margin-top:6px;
              border-bottom: 1px solid #999;
             
            }
            .sc-ion-input-ios-h, .native-input.sc-ion-input-md  {
              --padding-top: 0px !important;
              --padding-end: 0px !important;
              --padding-bottom: 0px !important;
              --padding-start: 0 !important;
             }
          }
          .container-btn-validate{
            width: 20%;
            display: inline-block;
            text-align: right;
              ion-button{
                margin-top:-24px;
                --background-focused:none;
                --background-activated:none;
                --border-radius: none;
                font-size: 100%;
                font-weight: 700;
                --color: #00B0FF ;
                --background:none;
                --box-shadow: none;
                --border-color: none;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }
          }
        }
      }
    }
  }
}

ion-alert .alert-wrapper .alert-title.sc-ion-alert-md {
  text-transform: none; 
  text-align: left;
}

.container-bottom-sheet-coupons{
  .bottom-sheet__container {
    overflow: auto !important;
    height: 83vh;
    background:  var(--background-color-blue);
    bottom: 3px !important;
  }
  .info-bottom-sheet{
    padding: 15px;
    width: 100%;
    .btn-close{
      height: 10px;
      background: rgba(31, 60, 124, 0.3);
      border-radius: 100px;
      width: 25%;
      height: 10px;
      margin: 0 auto;
    }
    .title-text{
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 17px;
      color: var(--color-blue);
      margin-top: 25px;
    }
    .sheet-text{
      margin-top: 20px;
      text-align: center;
      margin-bottom: 20px;
      .underlined{
        text-decoration: underline
      }
    }
  }
}
