.container-reder-products {

  .product-slider {
    .swiper-wrapper {
      flex: 1;
      min-height: 0;
    
      /* Styles for screens smaller than 800px */
      @media screen and (max-width: 799px) {
        height: 18em !important;
      }
    }
  }
}


.slides-brands-home-provider {
  margin-top: 1rem;

  .swiper-slide {
    width: fit-content !important;
  }
}

.container-reder-products {
  max-width: 1000px;
  margin: auto;
  background: var(--background-color-blue);

  .minimum-for-delivery {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-title-gray-3);
    padding: 10px 10px 10px 19px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .delivery-days{
      float: right;
    }
  }

  .banners-grid-container ion-col, 
  .banners-grid-container ion-row, 
  .banners-grid-container ion-grid {
    padding: 0px;
  }

  .promotions-section {
    // width: 100vw !important;
  }

  .slides-brands-home-provider.swiper {
     
      .swiper-wrapper {
        height: 4rem !important;
      }
    
  }
  

  ion-grid >ion-row {
    .swiper.slides-brands-home-provider {
      width: 100%;
      height: 4rem;
      margin-bottom: 8px;
      margin-left: 12px;
      overflow: visible;
      .swiper-slide-brand {
        // width: 22% !important;
        margin: 10px 0px;
        .brand-picture {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3em;
          width: 3em;
          border-radius: 50%;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          color: #b2b2b2;
          background: white;
          overflow: hidden;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}

ion-content {
  --background: var(--background-color-blue);
  visibility: visible !important;

  ion-segment {
    --background: var(--background-color-blue);
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    visibility: visible !important;

    ion-segment-button {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: none;
      visibility: visible !important;
      color: var(--text-title-gray-3);

      ion-label {
        visibility: visible !important;
      }
    }
    
  }
}

.hydrated {
  visibility: visible !important;
}

.is-checked {
  color: #1E3799;
  text {
    color: black;
  }
}

.loading-offers,
.loading-products {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--text-label-color);
  position: relative;
  width: 100%;

  .spinner-mid {
    margin: 0px auto 15px auto;
    --color: var(--text-label-color);

    .logo-providers {
      background-size: auto 70%;
      background-repeat: no-repeat;
      height: 9em;
      width: 33%;
      display: inline-block;
      background-color: white;
      border-radius: 72px;
      width: 27%;
      margin-top: 29px;
    }

    .more-information {
      display: inline-block;
      width: 67%;
      position: relative;
      top: -87px;
      left: 9px;
      color: var(--ion-color-primary, #3880ff);
      text-decoration: underline;
    }
  }

  .container-select {
    ion-item {
      border: none;
      --border-width: 0 0 0px 0;
      margin-top: 16px;
      margin-bottom: 6px;
    }
  }

  .container-select-no-offers {
    ion-item {
      border: none;
      --border-width: 0 0 0px 0;
      margin-top: 16px;
      margin-bottom: 6px;
    }

    .loading {
      margin-top: 8px;
      font-size: 14px;
      color: var(--text-label-color);
    }
  }
}

.information-brand {
  max-width: 1000px;
  margin: 0 auto;

  .tittle-brand {
    margin: 16px 23px 10px 23px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-blue);
  }

  .container-logo {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 321px;

    .logo-modal-brands {
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      height: 6em;
      width: 33%;
      display: inline-block;
      background-color: white;
    }
  }
  
  .separator {
    border-bottom: 1px solid #BBBBBB;
  }

  .title-aditional-info{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-blue);
    margin: 16px 23px 0px 23px;
  }

  .aditional-info-vendors{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-title-gray-3);
    margin: 16px 23px 0px 23px;
  }

  .aditionalInfo{
    margin-bottom: 20px;
  }

  .aditional-info-text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-title-gray-3);
    margin: 16px 23px 14px 23px;
    white-space: pre-line;
    pre {
      font-size: inherit;
      color: inherit;
      border: initial;
      padding: initial;
      font-family: inherit;
    }
  }
  .picture {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 70px;
    width: 70px;
    background-color: white;
    border-radius: 80%;
    margin: 8px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }
}

.provider-rating{    
  margin: -7px 0px 9px 56px;
  z-index: 100;
}

@media screen and (min-width: 375px) {
  .container-reder-products {
    ion-grid >ion-row {
      .swiper.slides-brands-home-provider {
        // .swiper-slide-brand {
        //   width: 18% !important;
        // }
      }
    }
  }
}

@media screen and (min-width: 450px) {
  .container-reder-products {
    ion-grid >ion-row {
      .swiper.slides-brands-home-provider {
        .swiper-slide-brand {
          width: 15% !important;
        }
      }
    }
  }
}

@media screen and (min-width: 550px) {
  .container-reder-products {
    ion-grid >ion-row {
      .swiper.slides-brands-home-provider {
        .swiper-slide-brand {
          width: 4em !important;
        }
      }
    }
  }
}
