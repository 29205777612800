.info-fulfillment {
  max-width: 90%;
  padding: 10px 37px;
  margin: 0 auto;
  background: #eff5f9;
  box-shadow: 0px 3px 10px rgba(30, 55, 153, 0.1);
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    text-align: center;
  }

  & > div:nth-child(1) {
    & > span:nth-child(1) {
      color: var(--text-title-gray-6);
      font-size: 14px;
      font-weight: 400;
    }

    & > span:nth-child(2) {
      color: #00ab0b;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  & > div:nth-child(2) {
    & > span:nth-child(1) {
      color: var(--text-title-gray-6);
      font-size: 14px;
      font-weight: 400;
    }

    & > span:nth-child(2) {
      color: #666;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
