.home-page-profile {
  background: var(--background-app) !important;
  .loading-profile {
    --color: var(--text-label-color);
    text-align: center;
    padding-top: 13em;
    padding-bottom: 19em;
    position: absolute;
    width: 100%;

    .loading-promotions {
      font-size: 14px;
      color: var(--text-label-color);
    }

    .spinner-mid {
      padding-left: 22em;
    }

    .loading {
      font-size: 14px;
      color: var(--text-label-color);
    }
  }

  ion-segment {
    --background: var(--background-app);

    .guru-pro {
      --color-checked: var(--emphasis-background-color)
    }

    .profile {
      --color-checked: var(--primary-background-color)
    }
  }

  .container-guru-pro-profile {
    width: 100%;
    margin: 15px;
    display: flex;

    .info-gruru-pro {
      margin-left: 13px;
      width: 100%;

      .title-guru {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--secondary-color);
      }

      .guru-info {
        font-weight: 300;
        font-size: 12px;
        color: var(--text-title-gray-3);
        text-decoration-line: underline;
        margin-bottom: 9px;
      }

      .progress-guru {
        display: flex;

        ion-progress-bar {
          --progress-background: var(--background-green);
          width: 70%;
          --background: #d9d9d999
        }

        .percentage-user {
          margin-left: 15px;
          margin-top: -7px;
          margin-top: -17px;
        }

        .native-input[disabled].sc-ion-input-md {
          opacity: 1;
        }
      }
    }
  }

  .profile-content-wrapper {
    --background: var(--background-color-blue);
    background-color: var(--background-color-blue);
    padding: 20px !important;
    overflow-y: scroll;
    height: 100%;
    .profile-info-text {
      margin-top: 5px;
    }

    .separator {
      border-bottom: 1px solid #ccc;
    }

    .container-delete-user {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: var(--text-title-gray-9);
      text-transform: uppercase;
    }
    .separatorName{
      margin-top: 20px;
    }
    .container-info-user {
      position: relative;
      height: auto;
      .name {
        margin: 15px;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-title-gray-9);
        text-transform: uppercase;
        margin-top: 35px;
        margin-bottom: -12px;
      }

      .user-name-profile {
        margin: 15px 0px 0px 0px;
      }

      .profile-subtitle {
        margin: 20px 0px 0px 0px;
      }

      .rut-user {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-title-gray-3);
        margin: 15px;
      }

      .direction {
        margin: 15px;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-title-gray-9);
        text-transform: uppercase;
        margin-bottom: -13px;
      }

      .direction-user {
        font-size: 15px;
        font-weight: 600;
        color: var(--text-title-gray-3);
        margin: 15px;
      }
    }

    .container-password {
      .password-to-update {
        margin-bottom: -12px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .password-title {
          margin: 8px 0px;
          display: inline-block;
          width: 70%;
        }

        .to-update-tittle {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary-color-celeste);
          margin: 14px 13px 14px 0px;
          cursor: pointer;
        }
      }

      .inputs-password-container {
        .alert-password {
          text-align: center;
          font-size: 12px;
          color: #EB2F06;
          padding: 4px;
        }
      }

      .button-password {
        display: flex;
        justify-content: flex-end;
        margin: 0px -23px 0px -23px;

        ion-button {
          height: 32px;
          --border-radius: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary-color-celeste);
          --background: none;
          --border: none;
          box-sizing: none;
          --box-shadow: none;
          text-transform: capitalize;

          ion-spinner {
            margin-left: 10px;
          }
        }
      }
    }

    .container-btn-profile {
      padding: 10px 0 0 0;
      max-width: 800px;
      margin: 13px auto 0px auto;
      text-align: center;
      .btn-profile {
        text-align: center;
        display: inline-block;
        vertical-align: top;
        width: 25%;
        margin-bottom: 10px;
      }

      .wrapp-icon {
        display: inline-block;
        background-color: white;
        padding: 10px 14px;
        border-radius: 100%;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 10px;
      }

      .img-menu-profile {
        font-size: 25px;
        height: 25px;
        width: 25px;
        display: inline-block;
      }

      .text-btn {
        font-size: 10px;
        font-weight: 600;
        color: var(--text-title-gray-6);
        text-transform: uppercase;
        text-align: center;
      }

      .button-password {
        display: flex;
        justify-content: flex-end;
        margin: 0px -23px 0px -23px;

        ion-button {
          height: 32px;
          --border-radius: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary-color-celeste);
          --background: none;
          --border: none;
          box-sizing: none;
          --box-shadow: none;
          text-transform: capitalize;

          ion-spinner {
            margin-left: 10px;
          }
        }
      }
    }

    .info-left-btn-container {
      width: fit-content;
    }

    .info-update-btn-container {
      width: 100%;
      margin: 20px auto 0px auto;
    }

    .w-100-btn {
      width: 100% !important;
    }

    .margin-separation {
      margin-top: 25px !important;
    }
  }
  .local-data-display {
    width: 100%;
    background-color: #333;
    color: white;
    border: 2px solid wheat;
    border-radius: 5px;
  }
  .container-email-phone {
    .header-email-phone {
      margin: 15px;
      display: flex;
      justify-content: space-between;
      .title-email-phone {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-title-gray-9);
        text-transform: uppercase;
        width: 80%;
      }
      .icon-email-phone {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-color-celeste);
        margin: 0px 3px 0px 3px;
        cursor: pointer;
      }
      .icon-save {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-color-celeste);
        margin-left: -12px;
        float: right;
      }
    }
    .edit-email-phone {
      width: 100%;
      ion-select {
        width: 100%;
        max-width: 500px;
        padding-left: 0;
      }
      .user-field {
        margin-top: 15px !important;
        .without-value {
          font-size: 14px;
          font-weight: 600;
          color: var(--text-disabled-color);
          text-transform: uppercase;
        }
        .field {
          margin-top: 10px;
          ion-icon {
            font-size: 32px;
            font-weight: 400;
            color: #00B0FF;
            --background: none;
            --box-shadow: none;
            float: right;
            margin-bottom: 4px;
            margin-top: -8px;
          }
        }
        .value {
          font-size: 16px;
          font-weight: 600;
          color: var(--text-title-gray-3);
          margin-top: 5px;
          .phone-box {
            background: white;
            align-items: center;
            vertical-align: middle;
            margin-top: 10px;
            .phone-prefix {
              background-color: #ccc;
              font-size: 14px;
              padding: 0 8px;
            }
            ion-input {
              background: white;
              border-radius: 0.25rem;
              border: 1px solid rgba(0, 0, 0, 0.25);
              input {
                padding: 0 1rem;
              }
            }
          }
        }
      }
      .dynamic-input-container {
        display: flex;
        justify-content: left;
        align-items: flex-end;
      }
      .dynamic-input-wrapper {
        width: 85%;
      }
      .dynamic-input-icon-wrapper {
        width: 15%;
        text-align: center;
        cursor: pointer;
      }
      .dynamic-input-icon {
        width: 22px;
        height: 22px;
        margin-bottom: 13px;
        text-align: center;
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      justify-content: flex-end;
      margin: 2px -11px 2px -11px;
      ion-button {
        height: 32px;
        --border-radius: none;
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-color-celeste);
        --background: none;
        --border: none;
        box-sizing: none;
        --box-shadow: none;
        text-transform: capitalize;
      }
    }
    .add-email-text {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: var(--primary-color-celeste);
      margin: 0px;
      cursor: pointer;
    }
    .input-additional-email-wrapper{
      display: flex !important;
      justify-content: space-between;
    }
    .input-additional-emails {
      margin-bottom: 10px;
      display: inline-block !important;
      width: 90%;
    }
    .invalid-email {
      border: 1px solid rgb(236, 71, 51) !important;
    }
    .delete-icon-container {
      position: absolute;
      text-align: center;
      top: 0;
      right: 10px;
    }
    .email-delete-icon{
      width: 25px;
      height: 19px;
    }
  }
  .container-logout {
    text-align: center;
    margin: 30px;
    display: flex;
    justify-content: center;
    div {
      margin-top: 1px;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 600;
      color: var(--text-title-gray-6);
    }
  }
  .profile-info-card {
    width: 100%;
    height: fit-content;
    padding: 10px 15px;
    background-color: #FFF;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: 10px;
    .pending-office-text-container {
      h6 {
        display: inline;
      }
      span {
        color: #666666;
        font-size: 12px;
        font-weight: 400;
      }

    }
    .icon-arrow{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--blue {
      h6 {
        color: #FFF;
      }
      background-color: #557FEA;
    }
  }
  .container-branch-offices-tittle{
    margin-top:31px
  }
  .location-container{
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    padding: 12px;
  }
  .delete-account-wrapper {
    margin-top: 10px;
  }
  .delete-account-wrapper button {
    padding-left: 10px;
    text-align: left;
  }
}

.footer-menu-home {
  display: flex;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  button {
    background: transparent;
    color: #999;
    flex: 1;
    height: 3rem;
    font-size: 13px;
    &.active {
      color: var(--button-footer);
      font-size: 13px;
      height: 3rem;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
}
.provider-header {
  margin: 10px;
  .name-provider {
    display: inline-block;
  }
  .provider-products {
    float: right
  }
}
.guru-pro,
.profile {
  background-color: var(--background-app);
}
.container-branch-office{
  --padding-top: 15px;
  --padding-start: 30px;
  --padding-end: 30px;
  --padding-bottom: 30px;
  .container-header{
    display: flex;
      .back-small-arrow{
        ion-button {
          --background: none;
          --border: none;
          box-sizing: none;
          --box-shadow: none;
          text-transform: capitalize;
          margin-left: -14px;
          --background-focused:none;
          --background-activated:none;
          --background-activated:none ;
          --background-hover: none !important;
        }
      }
      .container-title{
        .title{
          margin-top: 11.3px;
          margin-left: 2px;
          padding-top: 0px;
        }
      }
  }
}
@media only screen and (min-width: 768px) {
  .container-office-user {
    .container-office {
      .container-header{
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}