.plp-page {
  div{
    .container-card-search{
      .product-card{
        width: 45%;
        float: left;
        margin-left: 3%;
        margin-bottom: 4%;
        background: white;
      }
    }
  }

  .without-products {
    text-align: center;
    ion-img {
      max-width: 70%;
      margin: 1rem auto;
    }
    .message {
      font-weight: bold;
      color: #142242;
      margin: 1rem 0;
      font-size: 1.25rem;
    }
    ion-button {
      width: 70%;
    }
  }

  .product-wrapper {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f1f1f1;
    > .header {
      padding: 6px 12px;
      > span {
        font-weight: bold;
        color: var(--text-primary-color);
      }
    }
  }

  div.product-list {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    > .product-card {
      background: white;
      display: inline;
      margin-left: 3%;
      max-width: 30%;
      margin-bottom: 11px;
    }
  }


}
