.product-slider {
  border-bottom: 1px solid #f1f1f1;
  max-width: 1000px;
  margin: 0 auto;

  > .header {
    padding: 12px 24px 0 24px;
    > span {
      font-weight: bold;
      color: var(--color-blue);
    } 
    .seeMore{
      margin-right: 12px;
      font-size: 14px;
      width: 14px;
      font-weight: bold;
      .button-more {
        .see-all-products {
          margin-right: 6px;
          color: var(--color-blue);
        }
        .container-icon-right {
          padding-top: 2px;
        }
      }
    }
    .show-in-blue{
      color: var(--color-blue);
    }
    .name-into-header{
      color: #fff;
    }
    .see-button{
      color: #fff;
    }
    .counter-products{
      color: var(--text-title-gray-6);
      font-size: 16px;
      font-weight: normal;
    }
    .container-title{
      margin-right: 10px;
    }
  }
  > .headerReturnBack {
    padding: 12px 24px;
    > span {
      font-weight: bold;
    } 
    .seeMore{
      display: inline-block;
      float: right;
      margin-right: -12px;
    }
  }
  > .body {
    margin-top: 8px;
    margin-bottom: 15px;
    .swiper {
      .swiper-slide {
        font-size: 1em;
        // width: 158px  !important;
        width: fit-content !important;
        min-width: 158px;
        max-width: 158px;
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-color);
    > div.button-more {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
  .offersHeader{
    color: #F7432B !important;
  }
  .topProductsHeader{
    background-color: #0C2461;
  }
}
