@import '../../assets/scss/mixins';

.cart-page-order {
  background: var(--background-color-blue);

  .container-header-without-toolbar {
    margin: 10px 0 -20px 0;
  }

  .container-anulate-order {
    --background: var(--background-color-blue);

    .card-delete {
      .text-delete {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-blue);
        padding: 0 20px 6px 20px;
      }

      .subtitle-delete {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-3);
        margin-bottom: 20px;
        padding: 0 20px 6px 20px;
      }

      .container-list-questions {
        padding: 0 10px 6px 20px;

        .container-questions-answers {
          color: var(--text-title-gray-3);
          margin-bottom: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;

          .container-question {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .container-checkbox {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            align-items: center;
            display: flex;

            .checkbox {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              border: 2px solid #1E3799;
              margin: 10px;
            }

            .checked {
              background: #1E3799;
            }
          }
        }
      }
    }
  }

  .container-btn-anulate-order {
    padding: 20px;
    margin: auto;

    button {
      width: 225px;
    }
  }

  .optionsQuestion {
    float: right;

    .optionQuestionYes {
      display: inline-block;
    }

    .optionQuestionNo {
      display: inline-block;
    }
  }

  ion-content.container-order-view {
    --background: var(--background-color-blue);

    .btn-repeat-order {
      text-transform: none;
      width: 100% !important;
      margin-top: 20px;
      margin-bottom: -14px;
    }

    .container-name-btn {
      display: flex;
      justify-content: space-between;
      padding: 24px 15px 5px 15px;
      border-bottom: 1px solid rgba(31, 60, 124, 0.3);
      align-items: center;

      .name-provider {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-blue);
        text-align: center;
      }
    }

    .container-state {
      padding: 0 19px 11px;

      .title-state {
        display: flex;
        justify-content: space-between;

        .title-state-order {
          padding-top: 10px;

          h5 {
            font-size: 16px;
            color: var(--color-blue);
            margin-bottom: 6px;
            line-height: 19px;
            font-style: normal;
            font-weight: bold;
          }
        }
      }
    }

    .container-order {
      padding: 0 19px 0;

      .title-order {
        h5 {
          font-size: 16px;
          color: var(--color-blue);
          margin-bottom: 6px;
          line-height: 19px;
          font-style: normal;
          font-weight: bold;
        }
      }

      .date-order {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-3);
      }
    }

    .product-list-order {
      margin-bottom: 33px;

      .container-btn-confirm {
        text-align: center;
        margin-top: 40px;
      }

      .provider-summary-order {
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 16px;
        background: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .provider-header-order {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.8em;

          .container-provider-header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .provider-title {
              display: inline-block;
              font-size: 16px;
              font-weight: 600;
              color: var(--color-blue);
            }

            .icon-provider {
              align-self: center;
              margin-right: 6px;
            }

            .icon-anulate-order {
              margin-right: 10px;
              margin-top: 5px;
            }
          }
        }

        .open-details-products {
          border-bottom: 1px solid rgba(31, 60, 124, 0.3);
        }
      }
    }

    .info {
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 16px;
      background: #fff;
      padding-bottom: 5px;
      border-radius: 10px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);

      .summary {
        padding: 12px 12px 4px 4%;
        font-size: 16px;
        color: var(--color-blue);
        border-bottom: 1px solid rgba(31, 60, 124, 0.3);
        font-weight: 700;
      }

      .info-aditional {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-title-gray-3);
        margin: 12px 0 10px 4%;
      }

      .info-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 0 4% 4px 4%;
      }

      .info-aditional-days {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-title-gray-3);
        margin: 0 0 10px 4%;
      }

      .info-aditional-delivery-date {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-title-gray-3);
        margin: 0 0 10px 4%;
        text-transform: capitalize;
      }

      .name-provider-info {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-title-gray-3);
        display: flex;
        justify-content: space-between;
        margin-left: 4%;
        margin-bottom: 4px;

        .total-amount {
          margin-right: 4%;
        }
      }

      .container-discount {
        .title-discount {
          padding: 4px 4%;
          margin-top: 21px;
          padding: 12px 12px 4px 4%;
          font-size: 16px;
          color: var(--color-blue);
          border-bottom: 1px solid rgba(31, 60, 124, 0.3);
          font-weight: 700;
        }

        .total-discount {
          font-size: 16px;
          display: flex;
          justify-content: flex-end;
          color: var(--text-title-gray-6);
          font-weight: 400;
          margin-top: 10px;
          padding: 10px;
        }

        .container-total-without-value {
          display: flex;
          justify-content: space-between;
          margin: 10px 10px 26px 10px;
          color: var(--text-title-gray-3);
        }
      }

      .container-total {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        color: var(--color-blue);
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .product {
      background: #f1f1f1;
      padding: 0.70rem 1rem;
      font-size: 14px;
      font-weight: 700;
      color: var(--text-title-gray-6);
      margin-top: 25px;
      border-bottom: 1px solid #CCC;
      border-top: 1px solid #CCC;

      .seeMore {
        display: inline-block;
        margin-top: 1px;
        margin-right: -6px;
        font-size: 17px;
      }
    }

    .information {
      background: #f1f1f1;
      padding: 0.70rem 1rem;
      font-size: 14px;
      font-weight: 700;
      color: var(--text-title-gray-6);
      border-bottom: 1px solid #CCC;
      border-top: 1px solid #CCC;

      .seeMore {
        display: inline-block;
        margin-top: 1px;
        margin-right: -6px;
        font-size: 17px;
      }
    }

    .delete {
      background: #f1f1f1;
      padding: 0.70rem 1rem;
      font-size: 14px;
      font-weight: 700;
      color: var(--text-title-gray-6);
      border-bottom: 1px solid #CCC;
      border-top: 1px solid #CCC;

      .seeMore {
        display: inline-block;
        margin-top: 1px;
        margin-right: -6px;
        font-size: 17px;
      }
    }

    .confirmed {
      background: #f1f1f1;
      padding: 0.70rem 1rem;
      font-size: 16px;
      font-weight: 600;
      text-align: initial;
      color: var(--primary-background-color);
    }

    .card-product {
      padding: 1.20rem 2.2rem 0.6em;
      border-bottom: 1px solid #CCC;
      display: flex;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .quantity {
        font-size: 22px;
        font-weight: 600;
        color: var(--text-title-gray-3);
        margin-top: 12px;
      }

      .card-img {
        width: 40%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 3.6em;
        margin-left: 4px;
      }

      .card-text {
        margin-left: 3px;
      }

      .card-name {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
      }

      .card-total {
        font-size: 16px;
        font-weight: 600;
        color: var(--color-blue);
      }
    }

    .card-user {
      display: flex;
      margin: 6px 12px;
      flex-direction: column;

      .card-info-user {
        margin: 6px 0;

        .title-field-user {
          font-size: 14px;
          color: var(--color-blue);
          font-weight: 600;
        }

        .value-field-user {
          font-size: 14px;
          color: var(--text-title-gray-3);
        }
      }
    }

    ion-list {
      ion-radio-group {
        .radio-1 {
          border-bottom: 1px solid #CCC;
          padding: 1.20rem 1rem;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-6);

          ion-radio {
            float: right;
            --color-checked: var(--primary-color-celeste);
          }
        }

        .radio-2 {
          border-bottom: 1px solid #CCC;
          padding: 1.20rem 1rem;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-6);

          ion-radio {
            float: right;
            --color-checked: var(--primary-color-celeste);
          }
        }
      }
    }
  }

  .container-btn-repeat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;

    .order-button-wrapper {
      margin-bottom: 10px;
    }

    .btn-standard-width {
      width: 200px;
    }
  }

  .container-btn {
    ion-button {
      --border-radius: 50px;
      margin: 40px 15px 43px 15px;
      --background: var(--primary-color-celeste);

      ion-spinner {
        position: absolute;
        left: 232px;
      }
    }
  }
}

.div-state {
  margin-top: 13px;

  .state-order {
    margin-bottom: 12px;

    .circle-state-order {
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      position: relative;
      margin-right: 18px;
      margin-top: 7px;
    }

    .line {
      border-left: 1.1px solid var(--text-title-gray-9);
      height: 38px;
      margin-left: 16px;
    }

    .detail {
      display: inline-block;
      color: var(--text-title-gray-6);
      width: 100%;

      .container-comment {
        margin-top: 10px;
      }

      .status-title {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-3);

        @media only screen and (min-width: 600px) {
          white-space: nowrap;
        }
      }

      .comment {
        font-size: 14px;
        margin-left: 27px;
      }
    }
  }
}

.without-products {
  text-align: center;

  ion-img {
    max-width: 70%;
    margin: 1rem auto;
  }

  .message-no-product {
    font-weight: bold;
    color: #142242;
    margin: 1rem 0;
    font-size: 1.25rem;
  }

  ion-button {
    width: 70%;
  }
}

ion-modal.anulated-confirm {
  .modal-wrapper {
    background: transparent;

    div.ion-page {
      left: 8%;
      right: 5%;
      top: 37%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 6px;
        height: 13em;

        .title {
          font-size: 20px;
          font-weight: 600;
          color: var(--text-title-gray-3);
          padding: 19px 19px 3px 19px;
          margin-top: 20px;
        }

        .content-info {
          font-size: 14px;
          font-weight: 400;
          color: var(--text-title-gray-6);
          padding: 19px;
        }

        .footer-order {
          display: flex;
          justify-content: space-between;
          margin: 24px 13px 0px 10px;

          .btn-order {
            @include title3;
            text-transform: uppercase;
          }

          .btn-order-confirm {
            @include btn3;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}