.vendor-offers-page {
  .container-slider {
    .guru-slider-container {
      height: 3.5rem;

      #slide-offers.selected {
        background-color: #F7432B;
      }

    }
  }
}


.spinner-mid {
  padding-left: 22em;
  top: -55px;
  margin-bottom: -58px;
  --color: var(--text-label-color);
}

.loading-products {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--text-label-color);
  position: relative;
  width: 100%;
}

.loading {
  font-size: 14px;
  color: var(--text-label-color);
}

.container-height{
  height: 300px;
}
.container-all-category{
  .header-category{
    padding: 10px 12px 10px;
    .container-title{
      margin-left: 5%;
      color: var(--color-blue);
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .container-product-card-offers,
  .container-product-card-favorites{
    display: flex;
    flex-wrap: wrap;
    margin-left: 4%;
  }
}

