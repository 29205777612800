.no-country-detect{
  text-align: center;
  padding-top: 1rem;
  .separator {
    border-bottom: 1px solid #ccc;
  }
  .container-title-not-country{
    font-size: 14px;
    font-weight: 400;
    color: var(--text-title-gray-6);
    margin-top: 42px;
  }
  .container-select{
    padding: 0.5rem 1rem 0.5rem 0;
    background: white;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 5%;
    margin-top: 11%;

    ion-select {
      text-align: left;
    }

    .item::part(native) {
      --inner-border-width: 0 0 0 0;
    }
  }


  .select-wrapper-inner {
    color: magenta;
  }

  .guru-initial-country-select {
    .select-wrapper-inner {
      color:cadetblue;
    }
  }

  .guru-initial-country-select::part(label) + div {
    width: 100%;
    justify-content: space-between;
    color: magenta;
  }
}