.confirm-code-sms {
  .content-confirm-code{
    --background: var(--background-color-blue);
  }
  .wrapper {
    position: absolute;
    .header {
      position: relative;
      ion-icon {
        color:var(--color-blue) ;
        top: 2.5rem;
        left: 1rem;
        position: absolute;
        }
    }
  }
  .welcome-title {
    text-align: center;
    padding: 24px 0px;
    margin-top: 7em;

    div {
      &:first-child {
        color: var(--primary-background-color);
        font-size: 1.2em;
        font-weight: bold;
      }
      &:last-child {
        color: var(--text-label-color);
        font-size: 0.85em;
        font-weight: lighter;
        padding-top: 4px;
      }
    }
  }
  .container-label-resend-code{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .label-resend-code{
      display: flex;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      color: #1E3799;
      p{
        margin: 0 0 0 8px;
        text-decoration: underline;
        font-weight: bold;
        color: #1E3799;
      }
    }
  }
  .container-btn-validate-code{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .container-input-number{
    display: flex;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: space-between;
    .code-valid {
      --ion-item-border-color: #f4511e !important;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 17px;
      color: #333333;
      .code-number-input{
        text-align: center;
        font-weight: 700;
        font-size: 1.5em;
        width: 53px;
        height: 54px;
        background: #FFFFFF;
        border: 0.5px solid rgba(31, 59, 122, 0.2);
        box-sizing: border-box;
        border-radius: 15px;
        &:focus-visible{
          outline: none;
        }
      }
      .valid{
        border: 0.5px solid #1E3799;
      }
    }
  }

  .form {
    .resend-sms {
      --color: var(--primary-color);
      text-align: center !important;
      width: 100%;
      display: inherit;
      padding-top: 10px;
      color: #00b0ff;
      background: white;
      ion-button {
        --background: var(--primary-color-celeste);
        margin-top: 0.5em !important;
        font-size: 14px;
        font-weight: 400;
        --background-activated: none;
        --background-focused: none;
        --background-hover: none;
      }
    }
    .validate-phone{
      margin-top: 23px;
      ion-label{
        margin-bottom: 10px;
        font-weight: 300 !important;
        color:var(--text-title-gray-6)!important; 
      }
      ion-input{
        background: white;
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 0, 0, 0.25);
        margin-top: 13px;
        padding: 0 1rem ;
        height:26px;
        transform: none !important;
        .phone-prefix {
          background-color: #ccc;
          font-size: 14px;
          padding: 0 8px ;
        }
       
      }
      .btn-save{
        display: flex;
        justify-content: flex-end;
        ion-button{
          height: 32px;
          --border-radius: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary-color-celeste);
          --background: none;
          --border: none;
          box-sizing: none;
          --box-shadow: none;
          text-transform: capitalize;
          width: 34%;
        }
      }
    }
    
    ion-button {
      --background: var(--primary-color);
      font-size: 1.1em;
      margin-top: 0.5em !important;
    }

    div.hint {
      padding: 12px 0px 0px 16px;
      font-size: 0.8em;
      color: var(--primary-color);
    }
  }

  .grid{
    font-weight: 700;
    margin-top: 1em;
    text-align: center;
    color: #444444;
    font-size: 1.5em;

    .numeric-keyboard {
      --background: white !important;
      color: var(--text-title-gray-3);
      font-size: 20px;
      font-weight: 400;
      margin-top: 0.5em !important;
      --box-shadow: none;
      border: none;
    }
    .numeric-keyboard-delete{
      --background: white !important;
      color: var(--text-title-gray-3);
      font-size: 16px;
      font-weight: 400;
      margin-top: 0.5em !important;
      --box-shadow: none;
      border: none;
    }
  }

  .sub-title{
    margin-top: 1em;
    text-align: center;
    color: #666666 !important;
  }

  .title{
    color: #444444 !important;
  }

  .title-2{
    font-weight: 300 !important;
    color: #666666 !important;
    font-family: Italic !important;
  }
}

.code-invalid{
  color: var(--emphasis-background-color);
  text-align: center;
}
