
.modal-confirm-order {
  --width: 300px;
  --height: 400px !important;
  z-index: 10 !important;
  background: none;
  .ion-page {
    justify-content: space-around !important;
  }
  .conf-modal-title{
    color: #333333;
  }
  div.modal-wrapper {
    border-radius: 10px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 0.8em;
    ion-icon {
      font-size: 20px;
      color: #999;
    }
  }
  .modal-content {
    text-align: -webkit-center;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 2em;
    .modal-inf-image {
      max-width: 150px;
      max-height: 150px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-evenly;
    padding: 0px 10px;
    margin-bottom: 1em;
    .modal-conf-btn-wrapper{
      width: 35%;
    }
    .conf-modal-btn {
      width: 100%;
    }
    button {
      border-width: 0px;
    }
  }
}

ion-backdrop { 
  --ion-backdrop-color: rgba(21, 67, 165, 0.3) !important;
  --backdrop-opacity: 1 !important;
}

.modal-no-confirm {
  div.modal-wrapper {
    border-radius: 5px;
  }
  .container-no-confirm{
    padding: 18px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color:  var(--text-title-gray-3);
      margin-bottom: 15px;
    }
    .text{
      font-size: 14px;
      font-weight: 400;
      color:var(--text-title-gray-6);
      line-height: 1.4;
    }
    .btn{
      float: right;
      margin-top: 17px;
      font-size: 15px;
      font-weight: 600;
      color:var(--primary-color-celeste);
    }
  }
}

