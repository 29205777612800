.cart-content {
  margin: 1rem auto 1.5rem auto;
  max-width: 60rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    & > p {
      color: var(--normal-text);
    }
  }

  &__separator {
    height: 0.04rem;
    background: var(--color-blue);
    margin-top: 0.1rem;
  }

  &__payment-methods-content {
    display: flex;
    flex-direction: column;
  }

  &__carro-vacio {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}
