.title-1{
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.title-2{
  font-size: 18px;
  font-weight: 600;
  color: #333;

}
.title-2-1{
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
.title-2-2{
  font-size: 16px;
  font-weight: 600;
  color: #1E3799;
  text-transform: uppercase;
}
.title-2-3{
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-3{
  font-size: 14px;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
}
.title-3-1{
  font-size: 14px;
  font-weight: 700;
  color: #1E3799;
  text-transform: uppercase;
}
.title-4{
  font-size: 14px;
  font-weight: 700;
  color: #666;
}
.title-4-1{
  font-size: 14px;
  font-weight: 700;
  color: #F6B93B;
}
.subtitle{
  font-size: 14px;
  font-weight: 300;
  color: #999;
  text-transform: uppercase;
}
.text-product-2{
  font-size: 10px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
}
.text-info-1{
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.text-info-2{
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.text-info-3{
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.extra-info{
  font-size: 10px;
  font-weight: 400;
  color: #666;
}
