ion-modal#suggested-modal {
  --min-width: 320px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--background-color-blue);
}

ion-modal#suggested-modal .wrapper {
  height: 100%;

  & > div {
    max-height: 30rem;
    overflow: auto;
    padding: 1rem;

    @media only screen and (max-width: 992px) {
      max-height: 45rem;
    }

    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > ion-icon {
        font-size: 1.8rem;
        color: #1e3799;
      }

      & > span {
        color: var(--color-blue);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    & > .guru-helper {
      margin-top: -1.7rem;
      margin-bottom: -0.1rem;
    }

    & > .providers-content {
      display: flex;
      gap: 0.8rem;
      flex-direction: column;
      overflow: auto;
    }
  }

  & > .button {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      width: 100%;
      max-width: 20rem;
      border-radius: 1.8rem;
      text-align: center;
      margin: 1rem auto;
      padding: 0.6rem 0.8rem;
      background: var(--color-blue);
      color: #fff;
    }
  }
}
