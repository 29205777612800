.cart-product-section {
  width: 100%;
  padding: 1.2rem 1.5rem;
  padding-bottom: 0.2rem;
  border-top: 1px solid var(--form-gray);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span {
    color: var(--normal-text);
    font-size: 1.7rem;
    font-weight: 700;
  }

  & > img {
    width: 4rem;
    height: 4rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3px;

    & > h5 {
      font-size: 0.7rem;
      color: #404852;
    }

    & > h3 {
      font-size: 1.2rem;
      color: var(--color-blue);
      font-weight: 700;
    }
  }

  &__buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &__button {
      border-radius: 60%;
      width: 1.7rem;
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -0.1rem;

      &--active {
        background-color: var(--color-blue);
      }

      &--disabled {
        background-color: #999;
      }

      & > ion-icon {
        color: #fff;
        font-size: 1rem !important;
        cursor: pointer;
      }
    }

    &__button--trash {
      border-radius: 60%;
      width: 2.2rem;
      height: 2.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -0.6rem;
      background: #fff;

      & > ion-icon {
        margin-left: -0.4rem;
        color: var(--color-blue);
        font-size: 1.5rem !important;
        cursor: pointer;
      }
    }
  }
}
