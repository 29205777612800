.card-provider-wrapper {
    margin-top: 16%;
    width: 100%;
    display: inline-block;
    animation: fadeInLeft; 
    animation-duration: 1.6s;
} 
.card-provider-home {
    position: relative;
    overflow: hidden !important;
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: white;

    .img-provider-home {
      border-radius: 20px;
      background-color: white;
    }

    .ribbon-text-container {
      pointer-events: none;
      display: flex;
      align-items: center;
      width: 100%;
      height: 24px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      text-align: center;
    }
    
    .ribbon-text {
      color: white;
      margin: 0px auto;
    }
}