ion-modal.alert-modal {
  --width: 300px;
  --height: 200px;
  --border-radius: 10px;
  --box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  background: rgba(31, 60, 124, 0.3);
  ion-backdrop{
    background: rgba(31, 60, 124, 0.3);
  }
  .content-alert{
    padding: 10px 20px;
    height: 100%;
    .content-alert-modal{
      display: flex;
      text-align: center;
      img{
        height: 24px;
        width: 24px;
        margin: 12px 6px 6px 0;
      }
      .container-title-modal{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-blue);
        margin-bottom: 8%;
      }
      .error-text{
        color: var(--secondary-color);
      }
    }
    .container-information-modal{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      p {
        margin: 0;
        line-height: 1.6;
        img{
          position: relative;
          height: 24px;
          top: 0;
          margin: 0 8px;
        }
      }
    }
    .container-btn-modal{
      display: flex;
      justify-content: flex-end;
      margin-right: -26px;
      padding-top: 20px
    }
    .container-btn-modal-two-buttons {
      padding: 20px 0px 0px 0px;
      display: flex;
      justify-content: right;
    }
    .container-btn-modal-two-buttons button {
      padding: 0px;
    }
  }
}

ion-modal.alert-modal-logo {
  --width: 300px;
  --height: 400px;
  --border-radius: 10px;
  --box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  background: rgba(31, 60, 124, 0.3);
  ion-backdrop{
    background: rgba(31, 60, 124, 0.3);
  }
  .content-alert-logo{
    padding: 10px 20px;
    height: 100%;
    .container-image-modal{
      display: flex;
      justify-content: center;
      margin: 54px 0 26px 0;
      img{
        width: 150px;
        height: 150px;
      }
    }
    .content-alert-modal > h5{
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-blue);
      margin-bottom: 8%;
    }
    .container-information-modal-logo{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0 10px;
    }
    .container-btn-modal{
      display: flex;
      justify-content: center;
      padding-top: 20px
    }
    .container-btn-modal-with-logo{
      display: flex;
      justify-content: space-evenly;
      padding-top: 20px
    }
    .container-btn-modal-with-logo .btn-primary{
      padding: 0 !important;
    }
  }
}
