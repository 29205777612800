.help-form{
  margin: 0 auto;  
  .container-help-form{
    --background: var(--background-color-blue);
  }
  .input-validate{
      margin: 0px 10%;
      margin-top: 24px;
      .subtitle-validate-user{
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6);
        margin-bottom: 6px;
      }
    }
  .container-help{
    text-align: center;
    padding: 26px;     
    .help {
      font-size: 12px;
      font-weight: 700;
      color:var(--normal-text);
      text-decoration:none;
      margin-top: 5px;
      text-align: center;
    }
  }
}