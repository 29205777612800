.container-product-modal {

    overflow-y: scroll;

    .container-product-image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    
        img {
            max-height: 200px;
        }
    }

    .header-title {
        display: flex;
        justify-content: space-between;
        margin: 35px 35px 18px 0;
    }
    
    .promotion-icon-container {
        position: absolute;
        width: 65%;
        z-index: 2;
        .promotion-icon-wrapper {
            position: absolute;
            right: 10px;
            right: 10px;
            top: -100px;
        }
    }
    
    .product-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .brand-product {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem !important;
    }
    
    .info-unid,
    .info-product {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        background-color: var(--background-color-blue) !important;
        font-size: 14px;
        font-weight: 400;
        padding: 15px 15px 2px;
    
        .info-unid-wrapper,
        .info-product-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
    
            .unid,
            .number-unid {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    
    
    .info-product-extra-info {
        .container-internal-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    
    
    .separator {
        border-bottom: 1px solid #1f3c7c4d;
    }

    .info-unid-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--background-color-blue);
        color: var(--text-title-gray-6);
        font-size: 14px;
        font-weight: 400;
        padding: 1rem 1rem 2rem;
    }

    .add-to-cart-btn-section {
        background-color: var(--background-color-blue);
        bottom: 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        left: 0;
        position: fixed;
        right: 0;
        width: 100%;
        z-index: 100;

        button {
            display: block;
            margin: 1.5rem auto !important;
        }
    }

    .product-slider-suggested {
        .product-suggested-container {
            display: flex;
            flex-wrap: wrap;
            background-color: var(--background-color-blue);
        }
    }
}


.container-product-modal {
    overflow: scroll;
    .header{
      display: flex;
      justify-content: space-between;
      margin: 35px 35px 18px 0px;
      ion-icon{
        font-size: 22px;
        color: #999;
      }
      .number-of-images{
        background-color: #eee;
        margin-left: 35px;
        border-radius: 5px;
        padding: 5px;
      }
      .number-images-text {
        color: #333 !important;
      }
      .tag-out-of-stock {
        width: 100px;
        height: 26px;
        background: #BBBBBB;
        border-radius: 5px;
        color: white;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .less-margin-right {
        margin-right: -56px;
      }
      .add-margin-right {
        margin-right: 16px;
      }
    }
    .body{
      .not-available{
        font-size: 14px;
        font-weight: 700;
        color: #999;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 25px;
        span{
          background-color: #f5f5f5;
          padding: 7px;
        }
      }
      .container-product-image{
        position: relative;
        display: flex;
        justify-content: center;
        .main-picture{
          height: 11em;
          position: relative;
          max-width: 80%;
        }
      }
      .product-title{
        text-align: center;
        padding: 6px 16px;
      }
      .brand-product{
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .separator {
      border-bottom: 1px solid rgba(31, 60, 124, 0.3);
      
    }
    .info-product{
      font-size: 14px;
      font-weight: 400;
      background-color: var(--background-color-blue) !important;
      padding: 15px;
      padding-bottom: 2px;
    }
    .product-description-text {
      margin: 6px 0px 15px 0px !important;
    }
    .info-unid{
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
      background-color: var(--background-color-blue);
      padding: 15px;
      height: fit-content !important;
    }
    .add-to-cart-btn-section {
      z-index: 100;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: fit-content;
      background-color: var(--background-color-blue);
      .add-to-cart-btn {
        margin: 30px auto 30px auto !important;
        display: block;
      }
    }
    .error-info{
      display: flex;
      justify-content: flex-end;
      width: 98%;
      font-size: 10px;
      color: red;
    }
    .info-unid-total{
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
      background-color: var(--background-color-blue);
      padding: 15px 15px 32px 15px;
      .promo-flex{
        display: flex;
        justify-content: flex-end;
        .old-prices {
          text-decoration: line-through;
          margin-right: 10px;
          opacity: 0.5;
        }
      }
    }
    .add-opacity-container {
      opacity: 0.3;
    }
    .info-ladder{
      font-size: 14px;
      font-weight: 400;
      color: var(--text-title-gray-6);
      background-color: var(--background-color-blue);
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .ladder-title {
        margin: 5px 0px 0px 0px;
      }
      .ladder{
        position: relative;
        .discount-ladder{
          .checkbox{
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 2px solid #1E3799;
            margin: 0px;
          }
          .checked {
            background: #1E3799;
          }
          .flex-container{
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            ion-radio{
              --color: var(--primary-color-celeste);
              --color-checked:var(--primary-color-celeste) !important;
              --ion-color-base:var(--primary-color-celeste) !important;
            }
          }
          .flex-container > div {
            margin: 5px;
          }
        }
      }
    }
    .container-internal-info{
      display: flex;
      justify-content: space-between;
      .description-text{
        margin-bottom: 10px;
      }
    }
    .btn-read-more{
      display: inline-flex;
      color: var(--primary-color-celeste);
      background: transparent;
    }
    .more-info-product{
      float: right;
    }
    .the-last-info{
      margin-bottom: 10px;
    }
    .see-more-info{
      font-weight: bold;
    }
    .title-product-info, .unid{
      display: inline-block;
    }
    .description-product-info, .number-unid{
      display: inline-block;
      float: right;
      .old-price {
        font-size: 0.7em;
        text-decoration: line-through;
        display: inline-grid;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-9);
        margin-right: 10px;
      }
      .gray-price {
        color: var(--text-title-gray-9) !important;
      }
    }
    .total{
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
    }
    .price-total{
      display: inline-block;
      float: right;
    }
    .product-slider-suggested {
      box-shadow: none !important;
      margin-bottom: 100px;
      > .header {
        padding: 12px 15px;
        background-color: var(--background-color-blue);
        margin: 0px;
        justify-content: flex-start;
        > span {
          font-weight: bold;
          color: var(--text-primary-color);
        } 
        .seeMore{
        display: inline-block;
        float: right;
        top: 10p;
        margin-top: 5px;
        margin-right: -12px;
        }
      }
      > .slider-body {
        margin: 0px;
        background-color: var(--background-color-blue);
         > div{
          display: flex;
          flex-wrap: wrap;
          .container-card{
            width:45%; 
            margin:2%
          }
        }
      }
    }
    .promotion-info-section {
      display: block;
      position: relative;
      align-items: center;
      height: fit-content;
    }
    .promotion-info-wrapper {
      display: table;
      text-align: center;
      background-color: #FA983A;
      width: 88%;
      height: fit-content !important;
      margin: 0px auto 0px auto !important;
      padding: 16px;
      border-radius: 10px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .promotion-info-text {
      color: white;
      margin: 0px;
    }
    .promotion-top-section {
      height: 25px;
    }
    .promotion-bottom-section {
      height: 35px;
      background-color: var(--background-color-blue);
    }
    .promotion-icon-wrapper {
      position: absolute;
      right: 10px;
    }
    .promotion-icon-container {
      z-index: 2;
      position: absolute;
      width: 65%;
    }
    .no-margin {
      margin: 0px;
    }
    .arrow-icon {
      position: relative;
      top: 0px;
      width: 16px;
    }
    .product-quantity-container {
      display: inline-block;
      float: right;
    }
    .suggested-empty-container {
      height: 160px;
      background-color: var(--background-color-blue) !important;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
  
  .addedToCart{
    text-align: center;
  }