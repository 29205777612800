@import '../../assets/scss/mixins';

ion-modal.container-payment-modal{
  --background:  var(--background-color-blue);
  ion-content{
    --background:  var(--background-color-blue);
    .separator{
      @include separator;
    }
    .title-save{
      margin: 4% 7% 1% 7%;
    }
    .container-other-payment-method{
      @include textInfo1;
      margin: 1% 7% 1% 7%;
      display: flex;
      justify-content: space-between;
      font-size: 1rem !important;
    }
  }
  .confirmation-payment{
    text-align: center;
    margin: 8%;
    .btn-primary {
      width: 60%;
    }
    .btn-primary.loading{
      width: 60%;
    }
  }
}

div{
  .container-info{
    .info-promotion{
      padding: 3%;
      display: flex;
      justify-content: space-between;
      border-top: 0.5px solid var(--color-border);
      .total, .save-money{
        color: var(--normal-text);
        margin:0px
      }
    }
    .info-extra{
      padding: 3%;
      color: #FFF;
      text-align: center;
      border-top: 0.5px solid var(--color-border);
      background-color: #FA983A;
      border-radius:0px 0px 10px 10px;
    }

  }
  .container-payment-cards{
    margin: 5% 6%;
    background: #FFFFFF;
    border-radius: 10px;
    ion-list{
      padding: 4%;
      border-radius: 10px;
      ion-radio-group{
        div{
          ion-radio{
            float: right;
            --border-radius: var(--color-blue );
            --color:var(--color-blue );
            --color-checked:var(--color-blue );
            --inner-border-radius:var(--color-blue );
            box-sizing: border-box;
            --border-radius: 5px;
          }
          .radio-checked{
            --border-width: 10px !important;
            --border-style: solid !important;
            box-sizing: border-box;
            --border-radius: 5px;
          }

          .payment-label {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: var(--color-blue);
          }

          ion-label {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color:  var(--color-blue );
          }
        }
      }
    }
    .card-payment{
      display:flex;
      align-items: center;
      .img-of-cards {
        height: 3.5rem;
      }
      .cards {
          position: relative;
          line-height: 20px;
          flex: 1;
          width: 100%;
          padding-right: 40px;
          .container-info-card {
            display: inline-block;
            width: 90%;
            .card-name-oatment{
              padding-left: 1rem;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              color: var(--color-blue)
            }
            .rut-account{
              padding-left: 2rem;
              padding-bottom: 0.6rem;
              padding-top: 10px;
              color:var(--text-title-gray-3);
              font-size: 16px;
              font-weight: 600;
              line-height: 0.85rem;
            }
            .id-cuenta{
              padding-left: 2rem;
              padding-top: 10px;
              color:var(--primary-color-celeste);
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
            }
    
            .name-associate-with-card-banco-estado{
              padding-left: 1rem;
              padding-bottom: 0.3rem;
              color:var(--normal-text);
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
            }
            .last-four-digits{
              padding-left: 2rem;
              padding-bottom: 0.5rem;
              padding-top: 0.3rem;
              color:var(--color-blue);
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
            }
            .name-associate-with-card{
              padding-left: 2rem;
              padding-bottom: 0.3rem;
              color:var(--text-title-gray-3);
              font-size: 16px;
              font-weight: 600;
              line-height: 0.85rem;
            }
          }
          .container-icon-trash{
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
          }
        }
    }
  }
}

