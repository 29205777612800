.provider-list{
  margin-top: 4%;
  margin-left: 4%;
  margin-right: 4%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 4%;

  
.invalid {
  border: 1px solid #F7432B;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

  .provider-summary {
    margin-top: 1em;
    background: #fff;
    border-radius: 2%;
    border-radius: 10px 10px 10px 10px;
    .provider-header-cart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8em;
      background:#FFFFFF;;
      border-radius: 10px 10px 0px 0px;
      border-bottom: 1px solid #ccc;
      .container-provider-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .h6-text{
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--color-blue)
        }
        .provider-title{
          display: inline-block;
          font-size: 16px;
          font-weight: 600;
          color:var(--color-blue);
        }
        .delivery-days{
          font-size: 14px;
          font-weight: 400;
          color: #999;
          text-transform: uppercase;
        }
        .payment-in-advance{
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          color: var(--color-blue);
          text-transform: uppercase;
          margin-left: 10px;
          padding-bottom: 0px;
        }
        .icon-provider{
          display: inline-block;
        }
      } 
    }
    .provider-products {
      .provider-product {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background: #FFFFFF;
        margin-top: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;
        h1{
          margin: 20px;
          margin-top: 16px;
        }
        .product-image {
          background-size: auto 30%;
          background-position: center;
          background-repeat: no-repeat;
          max-width: 4.5rem;
          max-height: 4.0rem;
          height: 100%;
        }
        .not-img{
          margin-left: 13px;
          margin-right: 13px;
        }
        .product-info {
          display: flex;
          // flex: 3;
          flex-direction: column;
          justify-content: space-evenly;
          padding-left: 0.5rem;
          color: #404852;
          .paragraph-3{
            margin: 0px !important;
          }
          .product-name{
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
          }
          .product-price {
            font-size: 16px;
            font-weight: 600;
            color: var(--text-title-gray-3);
          }
        }
        .product-operations {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          flex: 2;
          .product-operation {
            border-width: 0;
            padding: 0;
            margin-right: 1em;
            .icon {
              margin: 0;
            }
          }
        }
        .color-price{
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color:var(--color-blue)
        }
      }
    }
    div{
      .container-warning{
        .ion-color-warning{
        border: none ;
        color: #EB2F06 ;
        font-size: 12px;
        }
      }
    }
    .container-minimun{
      padding: 10px;
      width: 100%;
      border-bottom: 1px solid #ccc;
      .text-minimun{
        display: inline-block;
      }
      .provider-button{
        display: inline-block;
        float: right;
        .div-button{
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-align: right;
          color: #00B0FF;
        }
      }
    }
    .provider-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      .provider-total {
        background: #fff;
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        .provider-total-info {
          flex: 3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 1em;
          margin-right: 1em;
          .provider-total-title {
            font-size: 14px;
            font-weight: 700;
            color: var(--text-title-gray-3)
          }
          .provider-total-value {
            font-size: 20px;
            font-weight: 600;
            color: var(--text-title-gray-3)
          }
        }
      }
    }
    .containter-coupon-referal{
      width: 100%;
      border: 0.5px solid #CCC;
      padding: 10px;
      .container-referal-text{
        width: 50%;
        display: inline-block;
        .container-referal-title{
          font-size: 16px;
          font-weight: 700;
          color: #666;
          text-transform: uppercase;
          span{
            font-size: 16px;
            font-weight: 600;
            color: #333;
            margin-left: 10px;
          }
        }
        .container-referal-numCoupon{
          font-size: 10px;
          font-weight: 600;
          color: #666;
        }
      }
      .container-referal-btn{
        width: 50%;
        display: inline-block;
        ion-button{
          font-size: 11px;
          height: 28px;
          color: var(--secondary-color);
          --background: none;
          border-radius: 50px;
          border: 1px solid var(--secondary-color);
          --background-focused:none;
          --background-activated:none;
          --box-shadow: none;
          text-transform: uppercase;
          margin: -25px 0 0 80px;
          font-weight: 600;
        }
      }
    }
    .container-desactivate-referal {
      width: 100%;
      border: 0.5px solid #CCC;
      padding: 10px;
      background-color: var(--secondary-color);
      .container-referal-text{
        width: 50%;
        display: inline-block;
        .container-referal-title{
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          text-transform: uppercase;
          span{
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            margin-left: 10px;
          }
        }
        .container-referal-numCoupon{
          font-size: 10px;
          font-weight: 600;
          color: #fff;
        }
      }
      .container-referal-btn{
        width: 50%;
        display: inline-block;
        ion-button{
          font-size: 11px;
          height: 28px;
          color: var(--secondary-color);
          --background: #fff;
          border-radius: 50px;
          border: 1px solid var(--secondary-color);
          --background-focused:none;
          --background-activated:none;
          --box-shadow: none;
          text-transform: uppercase;
          margin: -25px 0 0 80px;
          font-weight: 600;
        }
      }
    }
    .way-to-pay-card{
      width: 100%;
      background:var(--new-primary-color-celeste);
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
     .change{
       position: relative;
       left: 20px;
       color: #fff;
       font-size: 14px;
       font-weight: 700;
     }
      ion-label{
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        padding: 10px 10px 10px 4px;
        width: 100%;
      }
      ion-select{
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
       --placeholder-opacity: 1;
        &::part(icon) {
         opacity: 1 !important;
         float: right !important;
        }
        &::part(text) {
          margin-left: -13px;
        }
      }
    }
  }
}

.coupon{
  ion-chip.ion-color.ion-color-success.ios.chip-outline.ion-activatable.hydrated{
    margin:5px;
    padding:0;
    border:none;
    border-radius:0;
  }
  ion-icon.ios.hydrated{
    display: none;
  }
  ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated{
    padding:10px 40px 10px 40px;
    font-size:12px;
    color: #1E3799;
  }
}

.container-coupons{
  .coupons-activated{
    display: flex;
    justify-content: space-between;
    background: #52D9CB;
    padding: 10px
  }
  .coupons-not-activated{
    background: #FA983A;
    display: flex;
    justify-content: space-between;
    padding: 10px
  }
  .text-coupon{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
  .see-more{
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    text-decoration-line: underline;
    color: #FFFFFF;
  }
}

.container-bottom-sheet{
  .bottom-sheet__container {
    height: 83vh;
    background:  var(--background-color-blue);
    bottom: 3px !important;
  }
  .info-bottom-sheet{
    padding: 15px;
    width: 100%;
    .btn-close{
      height: 10px;
      background: rgba(31, 60, 124, 0.3);
      border-radius: 100px;
      width: 25%;
      height: 10px;
      margin: 0 auto;
    }
    .title-text{
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 17px;
      color: var(--color-blue);
      margin-top: 25px;
    }
    .sheet-text{
      margin-top: 20px;
      text-align: center;
      margin-bottom: 20px;
      .underlined{
        text-decoration: underline
      }
    }
    .card-cupons{
      background: #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin: 15px 5px 5px 5px;
      display: flex;
      height: 80px;
      padding: 4%;

      &.disabled{
        opacity: 0.5;
      }
  
      .container-img-cupons{
        align-self: center;
        ion-img{
          height: 4.5rem
        }
      }
      .info{
        align-self: center;
        width: 100%;
        margin-left: 18px;
        .info-title{
          margin: 0px;
          margin-bottom: 3px      
        }
      }
      .container-toggle{
        align-self: center;
        text-align: center;
        ion-toggle{
          height: 15px;
          --background-checked: var(--new-primary-color-celeste);
          --handle-background-checked: var(--new-primary-color-celeste);
          margin-bottom: -11px;
        }
        ion-label{
          font-weight: bold;
          font-size: 10px;
          line-height: 17px;
          color:var(--normal-text)
        }
      }
    }
  }
}

.paymenth-options-cart-container {
  .action-sheet-group {
    --background: var(--background-color-blue);
    --button-color: var(--color-blue);
    --width: 100%;
    .action-sheet-button-inner.sc-ion-action-sheet-md {
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .action-sheet-cancel {
    display: none !important;
  }
}


@media only screen and (max-height: 700px) {
  .container-bottom-sheet{
    .bottom-sheet__container {
      height: 79vh;
    }
  }
}