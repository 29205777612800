.container-product-card{
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(30, 55, 153, 0.1);
  margin: 4px;

  .product-card {
    min-width: 158px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background: white;

    .body {
      height: 5em;
    }




    > .container-btn-ladder{
      margin-top: 0.8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn-ladder{
        border: 1px solid var(--color-blue);
        border-radius: 5px;
        width: 88%;
        padding: 4px;
        height: 40px;
        text-align: center;
        .quantity{
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color:var(--text-title-gray-3);
        }
        .total{
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 17px;
          color:var(--color-blue);
          margin: -1px 0 0 7px;
        }
      }
    }
    .container-btn-ladder-active{
      @extend .container-btn-ladder;
      .btn-ladder{
        background:var(--color-blue);
        .quantity{
         color:white;
        }
        .total{
          color:white;
        }
      }
    }
    > .body {
      margin-top: 8px;
      .container-img-ladder {
        display: flex;
        justify-content: flex-end;
        margin-right: 5%;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      
      .picture {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: -11px;
        margin-top: 2px;
        display: flex;
        justify-content: center;
        height: 5rem;
        .img-product {
          max-height: 5em;;
        }
      }
    
    }
    > .footer {
      position: relative;
      display: flex;
      align-items: flex-end !important;
      color: var(--color-blue);
      font-weight: 700;
      font-size: 20px;
      padding-left: 5px;
      // margin: 0px;
      height: 45px;
      ion-text {
        margin-left: 5px;
        margin-top: -2px;
      }
    }
    .container-text{
      height: 45px;
      max-height: 45px;
      > .tp {
        color: var(--text-title-gray-3);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        overflow: hidden;
        margin: 0px 5px;
        text-align: start;
      }
    }
    .out-of-stock {
      color: var(--text-title-gray-9) !important;
    }
    > .footer.offer {
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-top: 1rem;

      .tp-old {
        text-align: left;
        width: 100%;
        font-weight: 400;
        color: var(--text-title-gray-3);
        font-size: 14px;
        text-decoration: line-through;
      }
      .tp-offer {
        font-size: 20px;
        text-align: left;
        line-height: 20px;
        width: 100%;
        color: #EB2F06;
        padding-bottom: 3px;
      }
      .out-of-stock {
        color: var(--text-title-gray-9) !important;
        position: relative;
      }
    }
    .quantity-picker-container {
      width: fit-content;
      height: 100%;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      margin: 0px auto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-title-gray-9);
      display: flex;
      align-items: flex-end;
    }
    .container-add-btn{
      display: flex;
      justify-content: center;
      padding-bottom: 2px;
      .icon-add-product{
        height: 25px;
        width: 25px;
      }
    }
  }
  .has-responsive{
    width: 100%
  }
  .has-offer-price{
    height: 250px;
  }

  > .footer.offer {
    flex-direction: column;
    place-items: flex-start;
      .tp-old {
        font-weight: 400;
        color: var(--text-title-gray-9);
        font-size: 14px;
        text-decoration: line-through;
      }
      .tp-offer {
        font-size: 20px;
        color: #EB2F06;
        line-height: 20px;
        padding-bottom: 3px;
      }
  }
}

.has-responsive-father{
  width: 165px;
}

.has-margin{
  margin: 6px;
}

.container-product-card {
  .product-card.suggested-product {
    .footer {

    }
  }
    
}

.product-slider-suggested {
  box-shadow: none !important;
  margin-bottom: 100px;

  .header {
    background-color: var(--background-color-blue);
    justify-content: flex-start;
    margin: 0;
    padding: 12px 15px;
  }

  .slider-body {
    display: flex;
    flex-wrap: wrap;

    .container-card {
      margin: 2%;
      width: 45%;
    }
  }
}
  
