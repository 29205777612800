.is-not-loaded{
  z-index: 1000;
  position:absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parent-survey-container{
  --background: var(--background-color-blue);
}
.container-surveys{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto ;
  h3{
    font-size: 16px;
    font-weight: 600;
    color: var(--color-blue);
    padding: 0 20px 0 20px;
  }
  h4{
    margin: 0 0 4px 0;
    padding: 0 30px 0 30px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-title-gray-6);
  }
}
.divider-section{
  border-bottom: 0.5px solid var(--inactive-color);
}
.empty-container{
  margin-top: 20px;
}
.buy-experience{
  background: var(--background-color-blue);
  flex-direction: row;
  width: 90%;
  height: auto;
  ion-item{
    --background: var(--background-color-blue);
  }
  ion-icon{
    margin: 30px 12px 12px 12px;
  }
}
.container-range{
  background-color:var(--background-color-blue);
}

@mixin textarea-focus($isFocus: false) {
  @if $isFocus {
    border: 1px solid var(--color-blue);
  } @else {
    border: 1px solid var(--text-gray-color);
  }
}

.container-textarea {
  margin: 0px;
  width: 86%;
  .message{
    display: flex;
    font-size: 12px;
    color: var(--text-gray-color);
    margin-left: 20px;
    margin-bottom: 10px;
  }
  ion-textarea {
    background: white;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-title-gray-3);
    @include textarea-focus();
    width: 100%;
    height: 6em;
    border-radius: 20px;
    padding: 10px;
  }
}
.focused{
  ion-textarea{
    @include textarea-focus(true);
  }
  .message{
    color: var(--color-blue);
  }
}
.footer-btn-survey{
  background: var(--background-color-blue);
  ion-button{
    width: 176px;
    height: 42px;
    --background: var(--color-blue);
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
    border-radius: 100px;
    text-transform: uppercase;
    margin: 20px auto;
    max-width: 1000px;
    --box-shadow: none;
    --background-focused:none;
    --background-activated:none;
  }
}
