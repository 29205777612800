.container-btn-select{
  display: flex;
  justify-content: center;
  .btn-select{
    color: var(--color-blue) !important;
    img{
      margin-left: 10px;
      width: 16px;
    }
  }
}