@import '../../assets/scss/styles.scss';

.login-page {
  margin: 0 auto;  

  ion-row.separator {
    height: 1em;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
  ion-content{
    --background: var(--background-color-blue)
  }
  .container-login-register{
    display: flex;
    flex-direction: column;
    align-items: center;
    .container_title_peru{
      text-align: center;
      border-top: 0.5px solid #CCC;
      border-bottom: 0.5px solid var(--color-blue);
      font-size: 12px;
      font-weight: 700;
      color: var(--color-blue);
      text-transform: uppercase;
      padding: 15px;
      margin-top: 20px;
    }
  }

  ion-segment{
    text-align: center;
    max-width: 537px;
    margin: 0 auto;
    width: 100%;
    z-index: 100;
    box-shadow: none;
    --ripple-color: transparent;
    background-color: #ffffff;
    ion-segment-button{
      font-size: 14px;
      font-weight: 700;
      color: var(--color-blue);
      text-transform: uppercase;
    }
    .loginSegment{
      border-right: 1px solid #ccc;
      padding: 0px 3%;
      --color-checked: var(--color-blue)
    }
    .registerSegment{
      --color-checked: var(--color-blue)
    }
  }
  
  .formtype-header-container {
    text-align: center;
    width: 600px;
    max-width: 600px;
    background-color: white;
    padding: 14px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: var(--color-blue);
    .formtype-text {
      font-weight: bold;
      color: var(--color-blue);
    }
  }

  .welcome-title-container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    padding: 28px 55px 14px 55px;
    .welcome-title {
      font-size: 2em;
      font-weight: bold;
      color: var(--color-blue);
      margin-top: 0;
    }
    .welcome-text {
      font-size: 1em;
      color: #5e5e5e;
    }
  }

  .btn-showtype-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;
    .btn-showtype {
      margin-bottom: 14px;
      width: 100%;
      max-width: 600px;
    }
    .showtype-invited-btn{
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 16px;
      color: var(--primary-color-celeste);
    }
    .welcome-divider {
      height: 1px;
      border-top-style: solid;
      border-width: 1px;
      border-color: #c5c5c5;
      width: 100%;
      margin-top: 28px;
      margin-bottom: 28px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        content: 'O';
        position: absolute;
        background-color: #eff5f9;
        width: 50px;
        text-align: center;
        color: #949494;
      }
    }
  }


  .form {
    margin: 20px auto !important;
    margin-top: 24px;
    width: 300px;
    max-width: 800px;
    ion-item {
      ion-input {
        --color: var(--text-primary-color);
        input {
          margin-right: 5px;
        }
      }

      ion-label {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-title-gray-6)
      }

      ion-icon {
        display: none;
        color: var(--primary-color);
        position: absolute;
        bottom: 8px;
        right: 0px;
      }

      &.item-has-focus {
        --ion-item-border-color: var(--primary-color);
        .item-invalid-input {
          color: var(--ion-color-danger, #eb445a);
        }
      }
      
      .item-invalid-input {
        color: var(--ion-color-danger, #eb445a);
      }

      &.item-valid {
        --ion-item-border-color: var(--primary-color);

        ion-icon {
          display: inline;
        }
      }

      &.item-invalid {
        --ion-item-border-color: var(--ion-color-danger, #eb445a);
        ion-icon {
          display: inline;
        }
      }
    }
    
    .forgotPass{
      color:var(--normal-text);
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: -6px;
    }

    .img-avatar {
      width: 70%;
      height: 70%;
      margin-left: 60%;
    }

    .avatar {
      margin-left: -2em;
    }
 
    .recovery-password {
      color:var(--new-primary-color-celeste);
      text-decoration:none;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }

    .register {
      font-size: 12px;
      font-weight: 400;
      color:var(--secondary-color);
      text-decoration: none;
    }

    .help {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color:var(--normal-text);
      text-decoration:none;
      margin-top: 5px;
      a{
        color:var(--normal-text);
      }
    }

    ion-button {
      --background: var(--primary-color-celeste);
      font-size: 14px;
      font-weight: 700;
      color: #FFF;
      border-radius: 50%;
    }
  
    .captcha-container {
      text-align: -webkit-center;
      margin-bottom: 10px;
    }    
    
    .login-btn {
      display: flex;
      justify-content: center;
    }
    
    .forgot-password{
      margin-top: 18px;
      margin-bottom: 28px;
    }
  }
    
    ion-anchor {
      --color: var(--primary-color);
      text-align: center;
      width: 100%;
      display: inherit;
      padding-top: 10px;
    }

    a {
      --color: var(--primary-color);
      text-align: center;
      width: 100%;
      display: inherit;
      padding-top: 10px;
      cursor: pointer;
    }

    div.hint {
      padding: 12px 0px 0px 16px;
      font-size: 0.8em;
      color: var(--primary-color);
    }
}
.alert-login {
  width: 100%;
  height: 100%;
  .modal-wrapper {
    background: transparent;
    box-shadow: none;
    div.ion-page {
      background: white;
      left: 8%;
      right: 5%;
      top: 32%;
      bottom: 32%;
      overflow: visible;
      width: 83%;
      border-radius: 10px;
      .content-alert-login{
        width: 100%;

      }
      .container-information-modal{
        color: black;
      }
    }
  }
}
.app-version{
  text-align: center;
  color:var(--text-title-gray-6) ;
  font-size: 10px;
  padding: 10px 0;
  background: var(--background-color-blue);
  width: 100%;
  .capitalize{
    text-transform: capitalize;
  }
  &.ios{
    padding-bottom: 30px;
  }
}

.container-img-user{
  position: relative;
  padding-top: 3rem;
  background-color: rgb(41, 172, 255);
  // .img-user {
  //   height: 200px;
  // }
  .container-text{
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    .text{
      position: relative;
      .arrow-back {
        padding: 28px 14px;
        position: absolute;
        font-size: 30px;
        color: white;
        z-index: 1000;
        top: 0;
        left: 20px;
      }
    }
  }
  .logoImage{
    width: 150px;
    position: absolute;
    left: 50%;
    margin-left: -75px;
    bottom: -75px;
    animation: fadeIn; 
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
}

@media (min-width: 1020px) { 
  .login-page {
    .form {
      width: 100%;
      margin: 20px auto !important;
    }
  }
  .img-user{
    max-width: 799px;
    margin: 0 auto;
  }
  .container-img-user{
    .container-text{
      .text{
        padding-left: 100px !important;
        margin: auto;
        max-width: 820px;
        padding-top: 160px;
      }  
    }
  } 
} 


.captcha-container {
  width: 100%;
  margin: 10px 0;
  min-height: 78px; /* Altura mínima para el reCAPTCHA */
}

.g-recaptcha iframe {
  width: 100% !important;
  height: 78px !important;
}

.g-recaptcha {
  transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform: scale(0.77);
  -webkit-transform-origin: 0 0;
}

.captcha-container, .g-recaptcha {
  overflow: visible !important;
}