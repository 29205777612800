@import '../../assets/scss/base';

.home-page-app {
  --background: var(--background-color-blue);
  ion-content {
    --background: var(--background-color-blue);

    .container-guru-despacho {
      padding: 0px;
      width: 90%;
      max-width: 992px;
      margin: 20px auto 0px auto;

      & > h3 {
        font-size: 1.5rem;
      }

      & > div {
        border-radius: 1rem;
        text-align: center;
        max-width: 20rem;
        cursor: pointer;

        @media only screen and (max-width: 992px) {
          max-width: 100%;
        }

        & > a {
          font-size: 1.3rem;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
  ion-row.separator {
    height: 1em;
    border-bottom: 1px solid #ccc;
  }
  .banners-grid-container ion-col,
  .banners-grid-container ion-row,
  .banners-grid-container ion-grid {
    padding: 0px;
  }
  .guru-despacho-container {
    margin: 16px auto 0 auto;
    width: 90%;
    height: 80px;
    cursor: pointer;
    .guru-button{
      background-color: #1E3799;
      height: 80px;
      border-radius: 8px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .icon-fulfillment {
        width: 32%;
        height: 90%;
        @media screen and (min-width: 900px) {
          display: none;
        }
      }
      .icon-fulfillment-xl {
        height: 80%;
        width: 30%;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      .text-container {
        font-family: 'Montserrat', sans-serif;
        display: block;
        @media screen and (min-width: 900px) {
          display: flex;
        }
        .yellow {
          color: #F9E606;
        }
        .first-text {
          color: #ffffff;
          font-size: 26px;
          font-family: 'Montserrat', sans-serif;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 26px;
          @media screen and (min-width: 425px) {
            font-size: 32px;
            line-height: 32px;
          }
          @media screen and (min-width: 600px) {
            font-size: 40px;
            line-height: 40px;
          }
          @media screen and (min-width: 900px) {
            font-size: 32px;
            line-height: 32px;
          }
          @media screen and (min-width: 1400px) {
            font-size: 40px;
            line-height: 40px;
          }
        }
        .second-text {
          color: #ffffff;
          font-size: 17px;
          line-height: 18px;
          font-weight: bold;
          font-family: 'Montserrat', sans-serif;
          width: 100%;
          text-align: center;
          letter-spacing: 1px;
          @media screen and (min-width: 425px) {
            font-size: 22px;
          }
          @media screen and (min-width: 600px) {
            font-size: 28px;
            line-height: 28px;
          }
          @media screen and (min-width: 900px) {
            margin-left: 8px;
          }
          @media screen and (min-width: 1400px) {
            font-size: 40px;
            line-height: 40px;
          }
          .yellow {
            font-family: 'Montserrat', sans-serif;
            color: #F9E606;
          }
        }
      }
      .click-container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-family: 'Montserrat', sans-serif;
        .exclamation-mark {
          height: 100%;
          width: 8px;
        }
        .second-text-container {
          color: #ffffff;
          font-size: 14px;
          line-height: 14px;
          font-family: 'Montserrat', sans-serif;
          font-weight: bold;
          text-align: center;
          margin: 0px 4px;
          @media screen and (min-width: 600px) {
            font-size: 24px;
            line-height: 24px;
          }
          @media screen and (min-width: 900px) {
            display: flex;
          }
          @media screen and (min-width: 1400px) {
            font-size: 40px;
            line-height: 40px;
          }
          .click-text{
            font-family: 'Montserrat', sans-serif;
          }
          .yellow {
            font-family: 'Montserrat', sans-serif;
            color: #F9E606;
            @media screen and (min-width: 900px) {
              margin-left: 8px;
            }
          }
        }
      }

    }
  }
  .promotions-section {
    padding-top: 14px 0px 14px 0px;
    background-color: var(--background-color-blue);
    width: 100vw !important;

    .promotions-home-wrapper {
      width: 90%;
      margin: 0px auto;
      overflow: visible;
    }

    ion-row.one-promo {
      border-radius: 5px;
    }
    ion-col,
    ion-row,
    ion-grid {
      padding: 0px;
      --ion-grid-column-padding: 0;
    }
    ion-row.two-promo {
      display: flex;
      justify-content: center;

      > ion-col {
        overflow: hidden;
        max-width: 50%;
        min-height: 1em;
        max-height: 9em;
        box-shadow: -5px 5px 8px -5px #a2a2a2;
        border-radius: 9px;

        &:nth-child(1) {
          margin-right: 1.5%;
        }

        &:nth-child(2) {
          margin-left: 1.5%;
        }
      }
    }
  }
  .home-search-wrapper {
    margin-top: 10px;
  }
}

.container-distributo {
  background-color: var(--background-color-blue);
  padding: 0px;
  width: 90%;
  max-width: 992px;
  margin: 20px auto 0px auto;

  .providers-section-title {
    margin: 0px;
  }
}

.loading-section {
  --color: var(--text-label-color);
  width: 100%;
  text-align: center;
  padding-top: 2em;

  .loading-promotions {
    font-size: 14px;
    color: var(--text-label-color);
  }
}

.loading-section-providers {
  --color: var(--text-label-color);
  width: 100%;
  text-align: center;
  padding-top: 5.5em;

  .spinner-mid {
    padding-left: 22em;
  }

  .loading-providers {
    font-size: 14px;
    color: var(--text-label-color);
  }
}

.container-provider-home {
  margin: 0 auto;

  .banner-descanso-wrapper {
    margin-top: 8px;
  }
}

ion-modal.guru-pro-modal {
  .modal-wrapper {
    background: transparent;

    div.ion-page {
      left: 8%;
      right: 5%;
      top: 24%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
        height: 21em;

        > .header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }

        > .body-guru-pro {
          display: flex;
          flex-direction: row;
          padding: 0 18px;

          > div {
            &:nth-child(1) {
              height: 8em;
              width: 9em;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              background-size: auto 72%;
              background-position: center;
              background-repeat: no-repeat;
              height: 6em;
              width: 100%;
            }
          }
        }

        > .container-info-productModal {
          text-align: center;
          padding: 4px 12px 24px 12px;
          font-size: 13px;

          .container-title-modal {
            font-size: 16px;
            font-weight: 700;
            color: var(--emphasis-background-color);
            text-transform: uppercase;
            margin-bottom: 33px;
          }

          .info-modal {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            text-align: left;
            margin: 11px;
            margin-bottom: -36px;
          }
        }

        > .body {
          padding: 0 18px;

          > .title {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            color: var(--text-title-gray-3);
          }

          > .info-terms {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            margin-top: 30px;
          }
        }

        > .footer {
          margin-top: 40px;
          padding: 0 18px;
          width: 100%;

          > .read-more {
            width: 73%;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: var(--text-title-gray-6);
            text-transform: uppercase;
          }

          > .to-accept {
            width: 25%;
            display: inline-block;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: var(--primary-color-celeste);
            text-transform: uppercase;
          }

          > .to-perfil {
            font-size: 14px;
            font-weight: 700;
            color: var(--primary-color-celeste);
            text-transform: uppercase;
            float: right;
          }
        }
      }
    }
  }
}

ion-modal.not-office-modal {
  .modal-wrapper {
    background: transparent;
    div.ion-page {
      left: 8%;
      right: 5%;
      top: 24%;
      overflow: visible;
      width: 83%;

      .content {
        background: #fff;
        overflow: scroll;
        border-radius: 8px;
        height: 21em;

        > .header {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8em;
          color: #142242;
          opacity: 0.5;
          margin: 13px;
          margin-bottom: -4px;
        }
        > .container-info-productModal {
          padding: 4px 12px 24px 12px;
          font-size: 13px;
          margin-top: 25px;
          .container-title-modal {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin: 11px;
            margin-bottom: 27px;
          }

          .info-modal {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            text-align: left;
            margin: 11px;
            margin-bottom: -28px;
          }
          .text-modal {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-title-gray-6);
            text-align: left;
            margin: 11px;
            margin-bottom: -36px;
            margin-top: 52px;
          }
        }
        > .footer {
          margin-top: 65px;
          padding: 0 18px;
          width: 100%;

          > .read-more {
            width: 73%;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: var(--text-title-gray-6);
            text-transform: uppercase;
          }
          > .to-perfil {
            font-size: 14px;
            font-weight: 700;
            color: var(--primary-color-celeste);
            text-transform: uppercase;
            float: right;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .home-page {
    .promotions-section {
      ion-row.two-promo {
        > ion-col {
          &:nth-child(1) {
            margin-right: 1.5%;
            max-height: 16em;
          }

          &:nth-child(2) {
            margin-left: 1.5%;
            max-height: 16em;
          }
        }
      }
    }
  }
}
