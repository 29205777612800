ion-modal.modal-no-product {
	.modal-wrapper {
		background-color: rgba(0, 0, 0, 0.5);
		div.ion-page {
			left: 8%;
			right: 5%;
			top: 5%;
			overflow: visible;
			width: 83%;
			.content {
				background: #fff;
				border-radius: 8px;
				height: 39.8em;
				overflow-x: hidden;
				> .header {
					display: flex;
					justify-content: flex-end;
					font-size: 1.8em;
					color: #142242;
					opacity: 0.5;
					margin: 13px;
					margin-bottom: -4px;
				}

				> .body {
					display: flex;
					flex-direction: row;
					padding: 0 18px;
					margin-top: -26px;
					> div {
						&:nth-child(1) {
							height: 8em;
							width: 9em;
							display: flex;
							align-items: flex-start;
							justify-content: center;
							background-size: auto 72%;
							background-position: center;
							background-repeat: no-repeat;
					    height: 7em;
    					width: 99%;
						}
					}
				} >.container-info-productModal{
					text-align: center;
					padding: 10px;
					font-size: 13px;
					margin-top: -37px;
					.container-title-modal{
						font-size: 14px;
						font-weight: 600;
						color: var(--text-title-gray-3);
          }
				}
				> .container-input-modals{
					ion-item{
						margin: 16px;
						margin-bottom: -16px;
					>	ion-icon {
							display: none;
							color: var(--primary-color);
							position: absolute;
							bottom: 8px;
							right: 0px;
						}
						
						&.item-has-focus {
							--highlight-color-focused: var(--primary-background-color);
						}
						&.item-valid {
							ion-icon {
								display: inline;
							}
						}
						ion-avatar{
							width: 29px;
							margin: 7px 7px 7px -4px;
							.img-avatar{
								height: 32px;
							}
						}
						ion-label{
							color: var(--text-title-gray-6);
							font-size: 14px;
							font-weight: 400;	
						}
						ion-input{
							ion-icon{
								margin-right: 16px;
							}
							--placeholder-color: var(--text-title-gray-6);
							--placeholder-opacity: 1;
							 --placeholder-font-weight: 400;
							 font-size: 14px;
						}
						
					}
					.container-textarea{
						margin: 16px;
						margin-top: 31px;
						.message{
							color: var(--text-title-gray-6);
							font-size: 14px;
							font-weight: 400;	
							margin-bottom: 10px;
						}
						ion-textarea{
							font-size: 14px;
							font-weight: 400;
							width: 100%;
							height: 6em;
							border: 1px solid #e3e3e3;
							padding: 10px;
						}
					}
					.container-product-info{
						.tittle{
							font-size: 14px;
							font-weight: 400;
							color: var(--text-title-gray-6);
							padding: 0px 19px 0px 19px;
							margin-bottom: 20px;
						}
						.picture-info{
							margin-top: -22px;
							.picture{
								background-size: auto 70%;
								background-position: center;
								background-repeat: no-repeat;
								height: 8em;
								width: 45%;
								display: inline-block;
							}
							.brand{
								display: inline-block;
								width: 50%;
								position: relative;
								top: -51px;
								display: inline-block;
								font-size: 14px;
								font-weight: 400;
								color: var(--text-title-gray-6);
							}
						}
					}
				}
				> .footer{
					ion-button{
						--border-radius: 50px;
						margin: 0px 15px 0px 15px;
						font-size: 14px;
						font-weight: 700;
						--background:var(--primary-color-celeste);
						height: 33px;
						ion-spinner{
							position: absolute;
							left: 172px;
						}
					}
				}
      }
    }
	}
}

