.user-invited-page{
  margin: 0 auto;  
  .container-validate-comune{
    --background: var(--background-color-blue);
    .container-guru-helper{
      margin-top: 25%;
    }
    .container-search-comune-text{
      margin: 10px 20px;
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 20px;
      .btn-confirmation-comune{
        display: flex;
        justify-content: center;
        padding-top: 70px;
      }
      .input-comuna-container {
        padding: -20px;
        :first-child {
          min-width: 300px;
          :first-child {
            font-size: 0.9rem;
            margin-left: 0.3rem;
          }
          :nth-child(3) {
            border-radius: 1.5rem;
          }
        }
      }
    }
    .container-input-invited-user{
      .input-container-invited{
        display: flex;
        justify-content: center;
      }
      .container-btn-user-invited{
        display: flex;
        justify-content: center;
        margin-top: 46px;
      }
    }
  }
}
