.loading-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-gif{
  display: block;
}

