.suggested-providers-card {
  & > h3 {
    color: var(--color-blue);
    font-weight: 600;
    font-size: 1.3rem;
  }

  & > .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(2rem, auto);
    gap: 1rem;
  }
}
