.provider-summary-order{
  padding-top: 0px;
  .provider-header-order{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8em 0.8em 0 0.8em;
    .container-provider-header{
      width: 100%;
      display: flex;
      justify-content: center;
      .provider-title {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-blue);
        margin-right: 8px;
      }
      .icon-provider{
        align-self: center;
        margin-right: 0px;
        width: 14px;
        padding-bottom: 4px;
      }
      .icon-anulate{
        transform: rotate(90deg) !important;
        margin-right: 10px;
      }
  }
}
.open-details-products{
  border-bottom: 1px solid rgba(31, 60, 124, 0.3);
}
}