ion-modal#checkout-modal {
  --min-width: 320px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--background-color-blue);
}

ion-modal#checkout-modal .wrapper {
  height: 100%;

  & > div {
    max-height: 31rem;
    overflow: auto;
    padding: 1rem;

    @media only screen and (max-width: 992px) {
      max-height: 45rem;
    }

    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > ion-icon {
        font-size: 1.8rem;
        color: #1e3799;
      }

      & > span {
        color: var(--color-blue);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    & > .checkout-content {
      display: flex;
      gap: 0.8rem;
      flex-direction: column;
      overflow: auto;
      margin-top: 1rem;

      & > .recibe-en {
        background-color: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 0.8rem;
        padding-bottom: 0.3rem;

        & > span {
          color: var(--color-blue);
          font-weight: 600;
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }

        & > p {
          color: #333333;
        }
      }

      & > .datos-usuario {
        background-color: #fff;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 1.2rem;
        padding-bottom: 0.3rem;

        & > div {
          display: flex;
          flex-direction: column;

          & > label {
            display: flex;
            align-items: center;
            color: var(--color-blue);
            font-size: 1rem;
            font-weight: 600;
            gap: 0.5rem;
            margin-left: 1rem;
          }

          & > input {
            max-width: 50%;
            height: 40px;
            background-color: #fff;
            border: 1px solid var(--color-blue);
            border-radius: 100px;
            padding: 12px 20px;
            padding-right: 42px;
            margin: 10px 0;

            @media only screen and (max-width: 992px) {
              max-width: 85%;
            }
          }
        }
      }

      & > .comentario {
        padding: 0.4rem;
        padding-bottom: 0.3rem;

        & > p {
          font-size: 1rem;
          color: var(--color-blue);
          font-weight: 600;
          margin-left: 1rem;
        }

        & > textarea {
          background-color: #fff;
          border-radius: 20px;
          border: 1px solid var(--color-blue);
          width: 100%;
          padding: 0.8rem;
        }
      }

      & > .resumen {
        background-color: #fff;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        & > .padding {
          padding: 0.7rem;
          padding-bottom: 0.1rem;

          & > h3 {
            color: var(--color-blue);
            font-size: 1.1rem;
            font-weight: 600;
          }

          & > .tipo-pago {
            font-size: 0.9rem;
            color: #333333;
            margin-bottom: 0.7rem;
          }

          & > .precios {
            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                color: #333333;
              }
            }

            & > p {
              margin-top: -0.5rem;
              color: #333333;
            }
          }

          & > .total-price {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > p {
              color: var(--color-blue);
              font-weight: 600;
              font-size: 1.1rem;
            }
          }
        }

        & > .hr {
          height: 0.01rem;
          background-color: var(--color-blue);
        }
      }

      & > .metodo-pago {
        padding: 0.4rem;
        padding-bottom: 0.3rem;

        & > h3 {
          font-size: 1.1rem;
          font-weight: 600;
          color: var(--color-blue);
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          padding: 0.7rem;

          & > span {
            font-size: 1rem;
            color: var(--color-blue);
            font-weight: 600;
          }

          & > ion-icon {
            font-size: 1.3rem;
            color: var(--color-blue);
          }
        }
      }
    }
  }

  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      width: 100%;
      max-width: 20rem;
      border-radius: 1.8rem;
      text-align: center;
      margin: 1rem auto;
      padding: 0.6rem 0.8rem;
      background: var(--color-blue);
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
