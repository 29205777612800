.providers-cards-container {
  margin: 0 auto;
  width: 90%;
  max-width: 992px;

  display: grid; 
  grid-template-columns: repeat(auto-fill, 30%); 
  justify-content: space-between; 
}

@media (min-width: 650px) {
  .providers-cards-container {
    grid-template-columns: repeat(auto-fill, 18%); 
  }
}